import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import view from "../../assets/images/view.png";
import edit from "../../assets/images/edit.png";
import trash from "../../assets/images/trash.png";
import { baseUrl } from "../config/baseUrl";
import axios from "axios";

const TestCategoryList = () => {
  // const [testData, setTestData] = useState([]);

  useEffect(() => {
    // fetchTestData();/
  }, []);

//   const fetchTestData = async () => {
//     try {
//       const response = await axios.post(
//         "https://qualityeducation.in/app/webapi/Dashboard/getCategoryUpdated",
//         {
//           student_device_model: "samsung SM-A556E",
//           user_id: "22541",
//           student_device_id: "6d4f7ee63e142091",
//           student_fcm_token:
//             "d3rKoQKqQOOYTtPAOQ1BB4:APA91bELFFBTtTTAPga2Ec6SUGeaOlEPKnRXEn8melMh5LV0PbIqXFn5LKhpZWX4pij0WzzHYKO_hdkmpCoL6vdG5EYSVVEbQjcaOkpi83VnC-PIlOhjpISn4efGDGdH-1afcJNKusbe",
//         }
//       );
//       console.log("response", response);
//     } catch (error) {
//       console.error("Error fetching test data:", error); // Log any errors that occur
//     }
//   };
  
const testData =
  {
    "status": true,
    "message": "Category detail fetch successfully",
    "params": {
      "bannerList": [
        {
          "slider_id": 47,
          "slider_title": "offer2",
          "slider_des": "",
          "slider_image": "https://qualityeducation.in/app/uploads/slider_image/164836265125-03-2022-banner.jpg",
          "slider_status": true,
          "slider_order": 0,
          "slider_type": 0,
          "slider_button_name": "",
          "slider_button_url": "",
          "slider_redirect_to": 0
        },
        {
          "slider_id": 46,
          "slider_title": "offer1",
          "slider_des": "",
          "slider_image": "https://qualityeducation.in/app/uploads/slider_image/1631257259app-banner-2021.jpg",
          "slider_status": true,
          "slider_order": 0,
          "slider_type": 0,
          "slider_button_name": "",
          "slider_button_url": "",
          "slider_redirect_to": 0
        },
        {
          "slider_id": 37,
          "slider_title": "RAS Pre",
          "slider_des": "",
          "slider_image": "https://qualityeducation.in/app/uploads/slider_image/1648362688new-banner-for-bann.jpg",
          "slider_status": true,
          "slider_order": 0,
          "slider_type": 0,
          "slider_button_name": "",
          "slider_button_url": "",
          "slider_redirect_to": 0
        }
      ],
      "category": [
        {
          "exam_cat_id": 293,
          "exam_cat_name": "Patwar 2025",
          "exam_cat_parent_id": 0,
          "exam_cat_image": "https://qualityeducation.in/app/uploads/exam_type_images/20240806135658.jpg",
          "exam_cat_show_on": 1,
          "exam_cat_meta_title": "",
          "exam_cat_meta_keywords": "",
          "exam_cat_meta_description": "",
          "exam_cat_status": true,
          "exam_cat_identifier": "patwar-2024",
          "exam_cat_order": 0,
          "exam_cat_type": 0,
          "exam_cat_Total_makrs": "",
          "exam_type_scheme": "",
          "exam_cat_desc": "",
          "exam_cat_banner": "",
          "exam_cat_popular": 0
        },
        {
          "exam_cat_id": 391,
          "exam_cat_name": "Budget & Eco. Survey",
          "exam_cat_parent_id": 0,
          "exam_cat_image": "https://qualityeducation.in/app/uploads/exam_type_images/172277099720230512153100.png",
          "exam_cat_show_on": 0,
          "exam_cat_meta_title": "",
          "exam_cat_meta_keywords": "",
          "exam_cat_meta_description": "",
          "exam_cat_status": true,
          "exam_cat_identifier": "budget-eco.-survey",
          "exam_cat_order": 0,
          "exam_cat_type": 0,
          "exam_cat_Total_makrs": "",
          "exam_type_scheme": "",
          "exam_cat_desc": "",
          "exam_cat_banner": "",
          "exam_cat_popular": 0
        },
        {
          "exam_cat_id": 414,
          "exam_cat_name": "RAS Pre. 2024-25",
          "exam_cat_parent_id": 0,
          "exam_cat_image": "https://qualityeducation.in/app/uploads/exam_type_images/17226798781715237746ras-pre.png",
          "exam_cat_show_on": 0,
          "exam_cat_meta_title": "",
          "exam_cat_meta_keywords": "",
          "exam_cat_meta_description": "",
          "exam_cat_status": true,
          "exam_cat_identifier": "ras-pre.-2024-25",
          "exam_cat_order": 0,
          "exam_cat_type": 0,
          "exam_cat_Total_makrs": "",
          "exam_type_scheme": "",
          "exam_cat_desc": "RAS Pre. 2024-25",
          "exam_cat_banner": "",
          "exam_cat_popular": 0
        },
        {
          "exam_cat_id": 297,
          "exam_cat_name": "Daily Test",
          "exam_cat_parent_id": 0,
          "exam_cat_image": "https://qualityeducation.in/app/uploads/exam_type_images/17152009331613385940Daily-free-testnew.png",
          "exam_cat_show_on": 0,
          "exam_cat_meta_title": "",
          "exam_cat_meta_keywords": "",
          "exam_cat_meta_description": "",
          "exam_cat_status": true,
          "exam_cat_identifier": "daily-test",
          "exam_cat_order": 1,
          "exam_cat_type": 0,
          "exam_cat_Total_makrs": "",
          "exam_type_scheme": "",
          "exam_cat_desc": "Patwar, Raj SI, RAS Pre, High Court LDC etc.",
          "exam_cat_banner": "",
          "exam_cat_popular": 0
        },
        {
          "exam_cat_id": 371,
          "exam_cat_name": "CET 2024",
          "exam_cat_parent_id": 0,
          "exam_cat_image": "https://qualityeducation.in/app/uploads/exam_type_images/17152013141663758255CET-Graduation-Testnew.png",
          "exam_cat_show_on": 0,
          "exam_cat_meta_title": "",
          "exam_cat_meta_keywords": "",
          "exam_cat_meta_description": "",
          "exam_cat_status": true,
          "exam_cat_identifier": "cet-2024",
          "exam_cat_order": 1,
          "exam_cat_type": 0,
          "exam_cat_Total_makrs": "",
          "exam_type_scheme": "",
          "exam_cat_desc": "",
          "exam_cat_banner": "",
          "exam_cat_popular": 0
        },
        {
          "exam_cat_id": 382,
          "exam_cat_name": "CET (10+2)",
          "exam_cat_parent_id": 0,
          "exam_cat_image": "https://qualityeducation.in/app/uploads/exam_type_images/1669453566CET10+2.jpg",
          "exam_cat_show_on": 0,
          "exam_cat_meta_title": "",
          "exam_cat_meta_keywords": "",
          "exam_cat_meta_description": "",
          "exam_cat_status": true,
          "exam_cat_identifier": "cet-(10+2)",
          "exam_cat_order": 1,
          "exam_cat_type": 0,
          "exam_cat_Total_makrs": "",
          "exam_type_scheme": "",
          "exam_cat_desc": "join now",
          "exam_cat_banner": "",
          "exam_cat_popular": 0
        },
        {
          "exam_cat_id": 407,
          "exam_cat_name": "Raj SI 2024",
          "exam_cat_parent_id": 0,
          "exam_cat_image": "https://qualityeducation.in/app/uploads/exam_type_images/1715202175SI-New-2.jpg",
          "exam_cat_show_on": 0,
          "exam_cat_meta_title": "",
          "exam_cat_meta_keywords": "",
          "exam_cat_meta_description": "",
          "exam_cat_status": true,
          "exam_cat_identifier": "raj-si-2024",
          "exam_cat_order": 1,
          "exam_cat_type": 0,
          "exam_cat_Total_makrs": "",
          "exam_type_scheme": "",
          "exam_cat_desc": "",
          "exam_cat_banner": "",
          "exam_cat_popular": 0
        },
        {
          "exam_cat_id": 409,
          "exam_cat_name": "कनिष्ठ सहायक (LDC 2024)",
          "exam_cat_parent_id": 0,
          "exam_cat_image": "https://qualityeducation.in/app/uploads/exam_type_images/17152030391707988172GEOEconew.jpg",
          "exam_cat_show_on": 0,
          "exam_cat_meta_title": "",
          "exam_cat_meta_keywords": "",
          "exam_cat_meta_description": "",
          "exam_cat_status": true,
          "exam_cat_identifier": "??????-?????-(ldc-2024)",
          "exam_cat_order": 1,
          "exam_cat_type": 0,
          "exam_cat_Total_makrs": "",
          "exam_type_scheme": "",
          "exam_cat_desc": "?????? ????? (LDC 2024)",
          "exam_cat_banner": "",
          "exam_cat_popular": 0
        },
        {
          "exam_cat_id": 410,
          "exam_cat_name": "छात्रावास अधीक्षक (10+2 CET)",
          "exam_cat_parent_id": 0,
          "exam_cat_image": "https://qualityeducation.in/app/uploads/exam_type_images/1715202745GEOEconew.png",
          "exam_cat_show_on": 0,
          "exam_cat_meta_title": "",
          "exam_cat_meta_keywords": "",
          "exam_cat_meta_description": "",
          "exam_cat_status": true,
          "exam_cat_identifier": "?????????-???????-(10+2-cet)",
          "exam_cat_order": 1,
          "exam_cat_type": 0,
          "exam_cat_Total_makrs": "",
          "exam_type_scheme": "",
          "exam_cat_desc": "????????? ??????? (12th CET)",
          "exam_cat_banner": "",
          "exam_cat_popular": 0
        },
        {
          "exam_cat_id": 411,
          "exam_cat_name": "Stenographer",
          "exam_cat_parent_id": 0,
          "exam_cat_image": "https://qualityeducation.in/app/uploads/exam_type_images/1715202586Stenographernew.png",
          "exam_cat_show_on": 0,
          "exam_cat_meta_title": "",
          "exam_cat_meta_keywords": "",
          "exam_cat_meta_description": "",
          "exam_cat_status": true,
          "exam_cat_identifier": "stenographer",
          "exam_cat_order": 1,
          "exam_cat_type": 0,
          "exam_cat_Total_makrs": "",
          "exam_type_scheme": "",
          "exam_cat_desc": "stenographer",
          "exam_cat_banner": "",
          "exam_cat_popular": 0
        },
        {
          "exam_cat_id": 348,
          "exam_cat_name": "RPSC 2nd Grade Teacher",
          "exam_cat_parent_id": 0,
          "exam_cat_image": "https://qualityeducation.in/app/uploads/exam_type_images/1650939501RPSC-2nd-Grade-Teacher.png",
          "exam_cat_show_on": 1,
          "exam_cat_meta_title": "",
          "exam_cat_meta_keywords": "",
          "exam_cat_meta_description": "",
          "exam_cat_status": true,
          "exam_cat_identifier": "rpsc-2nd-grade-teacher",
          "exam_cat_order": 2,
          "exam_cat_type": 0,
          "exam_cat_Total_makrs": "",
          "exam_type_scheme": "",
          "exam_cat_desc": "",
          "exam_cat_banner": "",
          "exam_cat_popular": 0
        },
        {
          "exam_cat_id": 406,
          "exam_cat_name": "Animal Attendant Test Series ",
          "exam_cat_parent_id": 0,
          "exam_cat_image": "https://qualityeducation.in/app/uploads/exam_type_images/1715235280pashuparicharnew.jpg",
          "exam_cat_show_on": 0,
          "exam_cat_meta_title": "",
          "exam_cat_meta_keywords": "",
          "exam_cat_meta_description": "",
          "exam_cat_status": true,
          "exam_cat_identifier": "animal-attendant-test-series-",
          "exam_cat_order": 3,
          "exam_cat_type": 0,
          "exam_cat_Total_makrs": "",
          "exam_type_scheme": "",
          "exam_cat_desc": "Animal Attendant Test Series ",
          "exam_cat_banner": "",
          "exam_cat_popular": 0
        },
        {
          "exam_cat_id": 318,
          "exam_cat_name": "Subject Wise Test Series",
          "exam_cat_parent_id": 0,
          "exam_cat_image": "https://qualityeducation.in/app/uploads/exam_type_images/1715235901sub-wise-all-competion---Copy.png",
          "exam_cat_show_on": 0,
          "exam_cat_meta_title": "",
          "exam_cat_meta_keywords": "",
          "exam_cat_meta_description": "",
          "exam_cat_status": true,
          "exam_cat_identifier": "subject-wise-test-series",
          "exam_cat_order": 5,
          "exam_cat_type": 0,
          "exam_cat_Total_makrs": "",
          "exam_type_scheme": "",
          "exam_cat_desc": "",
          "exam_cat_banner": "",
          "exam_cat_popular": 0
        },
        {
          "exam_cat_id": 404,
          "exam_cat_name": "राजस्थान भूगोल एवं अर्थव्यवस्था ",
          "exam_cat_parent_id": 0,
          "exam_cat_image": "https://qualityeducation.in/app/uploads/exam_type_images/1715236628GEOEconew.jpg",
          "exam_cat_show_on": 0,
          "exam_cat_meta_title": "",
          "exam_cat_meta_keywords": "",
          "exam_cat_meta_description": "",
          "exam_cat_status": true,
          "exam_cat_identifier": "????????-?????-???-????????????-",
          "exam_cat_order": 5,
          "exam_cat_type": 0,
          "exam_cat_Total_makrs": "",
          "exam_type_scheme": "",
          "exam_cat_desc": "Rajasthan Geo.&Eco. | Vijendra Sir",
          "exam_cat_banner": "",
          "exam_cat_popular": 0
        },
        {
          "exam_cat_id": 300,
          "exam_cat_name": "Constable",
          "exam_cat_parent_id": 0,
          "exam_cat_image": "https://qualityeducation.in/app/uploads/exam_type_images/17152370911595230279Rajasthan-Police-Constable-.jpg",
          "exam_cat_show_on": 0,
          "exam_cat_meta_title": "",
          "exam_cat_meta_keywords": "",
          "exam_cat_meta_description": "",
          "exam_cat_status": true,
          "exam_cat_identifier": "constable",
          "exam_cat_order": 6,
          "exam_cat_type": 0,
          "exam_cat_Total_makrs": "",
          "exam_type_scheme": "",
          "exam_cat_desc": "Best way to do Best Study with Quality Education",
          "exam_cat_banner": "",
          "exam_cat_popular": 0
        },
        {
          "exam_cat_id": 400,
          "exam_cat_name": "Agriculture Supervisor",
          "exam_cat_parent_id": 0,
          "exam_cat_image": "https://qualityeducation.in/app/uploads/exam_type_images/1685281262AgricultureSupervisor.png",
          "exam_cat_show_on": 0,
          "exam_cat_meta_title": "",
          "exam_cat_meta_keywords": "",
          "exam_cat_meta_description": "",
          "exam_cat_status": true,
          "exam_cat_identifier": "agriculture-supervisor",
          "exam_cat_order": 6,
          "exam_cat_type": 0,
          "exam_cat_Total_makrs": "",
          "exam_type_scheme": "",
          "exam_cat_desc": "",
          "exam_cat_banner": "",
          "exam_cat_popular": 0
        },
        {
          "exam_cat_id": 389,
          "exam_cat_name": "Information Assistant Test Series ",
          "exam_cat_parent_id": 0,
          "exam_cat_image": "https://qualityeducation.in/app/uploads/exam_type_images/1675758951INFORMATION(4).png",
          "exam_cat_show_on": 0,
          "exam_cat_meta_title": "",
          "exam_cat_meta_keywords": "",
          "exam_cat_meta_description": "",
          "exam_cat_status": true,
          "exam_cat_identifier": "information-assistant-test-series-",
          "exam_cat_order": 7,
          "exam_cat_type": 0,
          "exam_cat_Total_makrs": "",
          "exam_type_scheme": "",
          "exam_cat_desc": "Information Assistant Test Series Package ",
          "exam_cat_banner": "",
          "exam_cat_popular": 0
        },
        {
          "exam_cat_id": 399,
          "exam_cat_name": "Junior Accountant ",
          "exam_cat_parent_id": 0,
          "exam_cat_image": "https://qualityeducation.in/app/uploads/exam_type_images/1715252037new.jpg",
          "exam_cat_show_on": 0,
          "exam_cat_meta_title": "",
          "exam_cat_meta_keywords": "",
          "exam_cat_meta_description": "",
          "exam_cat_status": true,
          "exam_cat_identifier": "junior-accountant-",
          "exam_cat_order": 7,
          "exam_cat_type": 0,
          "exam_cat_Total_makrs": "",
          "exam_type_scheme": "",
          "exam_cat_desc": "",
          "exam_cat_banner": "",
          "exam_cat_popular": 0
        },
        {
          "exam_cat_id": 309,
          "exam_cat_name": "REET Subject Wise Test",
          "exam_cat_parent_id": 0,
          "exam_cat_image": "https://qualityeducation.in/app/uploads/exam_type_images/1715245088reet-subject-wise-testPNG.png",
          "exam_cat_show_on": 0,
          "exam_cat_meta_title": "",
          "exam_cat_meta_keywords": "",
          "exam_cat_meta_description": "",
          "exam_cat_status": true,
          "exam_cat_identifier": "reet-subject-wise-test",
          "exam_cat_order": 8,
          "exam_cat_type": 0,
          "exam_cat_Total_makrs": "",
          "exam_type_scheme": "",
          "exam_cat_desc": "",
          "exam_cat_banner": "",
          "exam_cat_popular": 0
        },
        {
          "exam_cat_id": 397,
          "exam_cat_name": "Sangnak",
          "exam_cat_parent_id": 0,
          "exam_cat_image": "https://qualityeducation.in/app/uploads/exam_type_images/1715252744new.jpg",
          "exam_cat_show_on": 0,
          "exam_cat_meta_title": "",
          "exam_cat_meta_keywords": "",
          "exam_cat_meta_description": "",
          "exam_cat_status": true,
          "exam_cat_identifier": "",
          "exam_cat_order": 8,
          "exam_cat_type": 0,
          "exam_cat_Total_makrs": "",
          "exam_type_scheme": "",
          "exam_cat_desc": "",
          "exam_cat_banner": "",
          "exam_cat_popular": 0
        },
        {
          "exam_cat_id": 369,
          "exam_cat_name": "EO/RO Tests",
          "exam_cat_parent_id": 0,
          "exam_cat_image": "https://qualityeducation.in/app/uploads/exam_type_images/1715328614EO-RO-Category.png",
          "exam_cat_show_on": 0,
          "exam_cat_meta_title": "",
          "exam_cat_meta_keywords": "",
          "exam_cat_meta_description": "",
          "exam_cat_status": true,
          "exam_cat_identifier": "eo/ro-tests",
          "exam_cat_order": 14,
          "exam_cat_type": 0,
          "exam_cat_Total_makrs": "",
          "exam_type_scheme": "",
          "exam_cat_desc": "",
          "exam_cat_banner": "",
          "exam_cat_popular": 0
        },
        {
          "exam_cat_id": 363,
          "exam_cat_name": "Reet Mains",
          "exam_cat_parent_id": 0,
          "exam_cat_image": "https://qualityeducation.in/app/uploads/exam_type_images/1660798682Reet-Mains.png",
          "exam_cat_show_on": 0,
          "exam_cat_meta_title": "",
          "exam_cat_meta_keywords": "",
          "exam_cat_meta_description": "",
          "exam_cat_status": true,
          "exam_cat_identifier": "reet-mains",
          "exam_cat_order": 17,
          "exam_cat_type": 0,
          "exam_cat_Total_makrs": "",
          "exam_type_scheme": "",
          "exam_cat_desc": "",
          "exam_cat_banner": "",
          "exam_cat_popular": 0
        },
        {
          "exam_cat_id": 387,
          "exam_cat_name": "EO/RO Part-B",
          "exam_cat_parent_id": 0,
          "exam_cat_image": "https://qualityeducation.in/app/uploads/exam_type_images/1671546124EO-ROPART-BThumb.png",
          "exam_cat_show_on": 0,
          "exam_cat_meta_title": "",
          "exam_cat_meta_keywords": "",
          "exam_cat_meta_description": "",
          "exam_cat_status": true,
          "exam_cat_identifier": "eo/ro-part-b",
          "exam_cat_order": 21,
          "exam_cat_type": 0,
          "exam_cat_Total_makrs": "",
          "exam_type_scheme": "",
          "exam_cat_desc": "",
          "exam_cat_banner": "",
          "exam_cat_popular": 0
        },
        {
          "exam_cat_id": 301,
          "exam_cat_name": "Free Test",
          "exam_cat_parent_id": 0,
          "exam_cat_image": "https://qualityeducation.in/app/uploads/exam_type_images/1613192247Free-Demo-Test.png",
          "exam_cat_show_on": 0,
          "exam_cat_meta_title": "",
          "exam_cat_meta_keywords": "",
          "exam_cat_meta_description": "",
          "exam_cat_status": true,
          "exam_cat_identifier": "free-test",
          "exam_cat_order": 22,
          "exam_cat_type": 0,
          "exam_cat_Total_makrs": "",
          "exam_type_scheme": "",
          "exam_cat_desc": "",
          "exam_cat_banner": "",
          "exam_cat_popular": 0
        }
      ],
      "feedbacks": [
        {
          "id": 18135,
          "title": "Your Reviews",
          "description": "I have first time join quality education since 6 months ago I had purchased animal attendent part B course and currently join test series for CET outstanding content.the test series had so tough but I know it will show your quality in main exam lots of thanks from Narendra sir for back my confidence. we will meet you soon as a govt servant",
          "created": "2024-08-28T09:43:54+05:30",
          "ot_student": {
            "student_first_name": "Umashankar Sharma",
            "student_last_name": "",
            "student_phone": "7014369912",
            "student_image": ""
          },
          "formattedDate": "28 Aug,2024 09:43 AM"
        },
        {
          "id": 18130,
          "title": "Other",
          "description": "bhut achi team or bhut acha content Diya quality app ne me animal attend me agar mere quality notes se hi sare questions aaye to 40above que shi krungi \nthank you quality team",
          "created": "2024-08-26T11:10:11+05:30",
          "ot_student": {
            "student_first_name": "Kalp Bishnoi",
            "student_last_name": "",
            "student_phone": "7976446480",
            "student_image": ""
          },
          "formattedDate": "26 Aug,2024 11:10 AM"
        },
        {
          "id": 18072,
          "title": "Your Reviews",
          "description": "thanks you sir for ldc test series in exam maths and science question and Hindi English question as it is mile ",
          "created": "2024-08-12T11:41:31+05:30",
          "ot_student": {
            "student_first_name": "Dkdwivedi",
            "student_last_name": "",
            "student_phone": "9694165198",
            "student_image": "https://qualityeducation.in/app/uploads/user_image/1175147761717313091942.jpg"
          },
          "formattedDate": "12 Aug,2024 11:41 AM"
        },
        {
          "id": 17988,
          "title": "Your Reviews",
          "description": "thanks quality education.maine jra ki test series Li thi quality education se..bahut hi helpfull rhi .or Mera 2× list me selection ho gya h .I hope 1× me bhi hoga",
          "created": "2024-07-25T09:05:25+05:30",
          "ot_student": {
            "student_first_name": "aditya sharma",
            "student_last_name": "",
            "student_phone": "7357104373",
            "student_image": ""
          },
          "formattedDate": "25 Jul,2024 09:05 AM"
        },
        {
          "id": 17973,
          "title": "Your Reviews",
          "description": "i have selected for 2 times dv list for jra hope so final selection will be sure ...i prepared my exam 2 paper on this plateform thanks a lot quality education for gives us to a good content ",
          "created": "2024-07-20T10:46:22+05:30",
          "ot_student": {
            "student_first_name": "ashvani sharma",
            "student_last_name": "",
            "student_phone": "7976859344",
            "student_image": "https://qualityeducation.in/app/uploads/user_image/5837372791664785598196.jpg"
          },
          "formattedDate": "20 Jul,2024 10:46 AM"
        },
        {
          "id": 17959,
          "title": "Your Reviews",
          "description": "Myself prakash choudhary \nI took quality education course for jr. accountant I scored 652 marks\nthanks to quality education team and specially deepak sir",
          "created": "2024-07-16T18:40:29+05:30",
          "ot_student": {
            "student_first_name": "Prakash patel",
            "student_last_name": "",
            "student_phone": "9784530202",
            "student_image": ""
          },
          "formattedDate": "16 Jul,2024 06:40 PM"
        },
        {
          "id": 17952,
          "title": "Your Reviews",
          "description": "my name is sukhdeep singh. I have been selected in Jr accountant provisional. my Marks is 633. I got 2 times jobs with help quality education courses. Best coaching institutes.",
          "created": "2024-07-15T08:56:35+05:30",
          "ot_student": {
            "student_first_name": "Sukhdeep Singh",
            "student_last_name": "",
            "student_phone": "9983499498",
            "student_image": "https://qualityeducation.in/app/uploads/user_image/2227222101654887034811.jpg"
          },
          "formattedDate": "15 Jul,2024 08:56 AM"
        },
        {
          "id": 17944,
          "title": "Your Reviews",
          "description": "sir mene aapki test series aur course Kiya jra ka mera 2 Guna me ho gya . sir such me hi quality education me Rajasthan ki no 1 quality h thank you so much",
          "created": "2024-07-13T08:21:49+05:30",
          "ot_student": {
            "student_first_name": "HANSA NETAD",
            "student_last_name": "",
            "student_phone": "6376392688",
            "student_image": ""
          },
          "formattedDate": "13 Jul,2024 08:21 AM"
        },
        {
          "id": 17924,
          "title": "Your Reviews",
          "description": "Sir,I am very glad to inform you that I have selected in junior accountant 2 times dv list. I scored 603 marks and it could possible from your test series only.Thank you so much sir for your guidence and  literally I can say that the quality test series is number 1 in Rajasthan.my roll number is 749945",
          "created": "2024-07-09T17:03:16+05:30",
          "ot_student": {
            "student_first_name": "Satyendra Yadav",
            "student_last_name": "",
            "student_phone": "8432828332",
            "student_image": ""
          },
          "formattedDate": "9 Jul,2024 05:03 PM"
        },
        {
          "id": 17923,
          "title": "Other",
          "description": "namste sir.    mene aapka jr accountant ka course purchse kiya tha.  bhot behtreen trike se pdaya gyaa tha..or mera 2 guna m selection ho gya h...thku so much sir for good material.  ",
          "created": "2024-07-09T14:35:48+05:30",
          "ot_student": {
            "student_first_name": "pooja sharma",
            "student_last_name": "",
            "student_phone": "8386990686",
            "student_image": ""
          },
          "formattedDate": "9 Jul,2024 02:35 PM"
        },
        {
          "id": 17904,
          "title": "Your Reviews",
          "description": "sir mene quality education se jra ke second paper ki online tyari kre shandar faculty or behtreen content se mujhai bahut fayda hua mera 639 marks ke sath provisanal me selection ho gya h ummid h final bhi ho jayega \nthankyou quality education",
          "created": "2024-07-07T18:10:29+05:30",
          "ot_student": {
            "student_first_name": "Nikita Choudhary",
            "student_last_name": "",
            "student_phone": "9610714920",
            "student_image": ""
          },
          "formattedDate": "7 Jul,2024 06:10 PM"
        },
        {
          "id": 17892,
          "title": "Your Reviews",
          "description": "सर मैने junior accountant का कोर्स खरीदा था। उससे मैंने खासकर दूसरे पेपर की तैयारी की थी। मैं SCIENCE STUDENT हूं और मुझे कॉमर्स के एक भी सब्जेक्ट का ज्ञान नही था। दीपक सर,  BS राजावत सर जैसी फैकल्टी से पढ़कर मैंने  Commerce वालो को टक्कर दी है । दूसरे पेपर में 317 मार्क्स है। \nमै OBC से हु और अभी जनरल कैटेगरी से 2 गुणा में सिलेक्टेड हु। बाकी अब भगवान पर है। \nऐसी चुन्दीदा फैकल्टी के लिए आपका बहुत बहुत धन्यवाद।।\nROLL NO - 708747\nNAME - RAVI PRAKASH",
          "created": "2024-07-05T18:23:36+05:30",
          "ot_student": {
            "student_first_name": "RAVI PRAJAPAT",
            "student_last_name": "",
            "student_phone": "9828493083",
            "student_image": "https://qualityeducation.in/app/uploads/user_image/14676290641636682237592.jpg"
          },
          "formattedDate": "5 Jul,2024 06:23 PM"
        },
        {
          "id": 17887,
          "title": "Other",
          "description": "thanks quality education aapke course selection Mera Jr. accountant 2 times me selection hua h shayad final bhi ho jayega aapne Kam keemat me accha contant Diya tha aur content bhi pura tha bas self study aur revision ki jarurat thi",
          "created": "2024-07-05T08:33:41+05:30",
          "ot_student": {
            "student_first_name": "anita meena",
            "student_last_name": "",
            "student_phone": "7976416019",
            "student_image": ""
          },
          "formattedDate": "5 Jul,2024 08:33 AM"
        },
        {
          "id": 17880,
          "title": "Your Reviews",
          "description": "सर मैं क्वालिटी एजुकेशन के जूनियर अकाउंटेंट बैच का ऑनलाइन स्टूडेंट हूं,पेपर 2 की शानदार फैकल्टी और क्वालिटी एजुकेशन की टेस्ट सीरीज की बदौलत मेरा जूनियर अकाउंटेंट भर्ती में प्रोविजनल रूप से चयन हुआ है और उम्मीद है अंतिम रूप से भी हो जाएगा।\nनरेंद्र सर और समस्त टीम को बहुत बहुत धन्यवाद आपका प्यार और आशीर्वाद यूं ही बना रहे।",
          "created": "2024-07-03T15:10:12+05:30",
          "ot_student": {
            "student_first_name": "Pawan kumar",
            "student_last_name": "",
            "student_phone": "9772411476",
            "student_image": ""
          },
          "formattedDate": "3 Jul,2024 03:10 PM"
        },
        {
          "id": 17874,
          "title": "Your Reviews",
          "description": "नमस्ते सर, क्वालिटी एजुकेशन की टेस्ट सीरीज ने मेरी तैयारी के स्तर को हमेशा और मजबूत किया और जूनियर अकाउंटेंट मै 2x मै मेरा चयन 628 नंबर के साथ हो गया है।\nसंपूर्ण क्वालिटी एजुकेशन टीम का धन्यवाद ",
          "created": "2024-07-03T08:41:16+05:30",
          "ot_student": {
            "student_first_name": "munir",
            "student_last_name": "",
            "student_phone": "8949473724",
            "student_image": ""
          },
          "formattedDate": "3 Jul,2024 08:41 AM"
        },
        {
          "id": 17873,
          "title": "Your Reviews",
          "description": "sir ji pranam Narendra sir aapka bahut bahut dhanyawad aapki test series ramban sabit hai ye test series gagar me Sagar barne ka kam karti hai very nice test series",
          "created": "2024-07-03T06:03:10+05:30",
          "ot_student": {
            "student_first_name": "sanwar mal gurjar",
            "student_last_name": "",
            "student_phone": "9414439486",
            "student_image": ""
          },
          "formattedDate": "3 Jul,2024 06:03 AM"
        },
        {
          "id": 17869,
          "title": "Your Reviews",
          "description": "sir maine quality education se course liya tha 2nd paper k liye or Mera JRA  ×2 list mai name aa gya hai...thank you abhi teachers ko jinhone itni help ki ",
          "created": "2024-07-02T10:06:18+05:30",
          "ot_student": {
            "student_first_name": "deeksha sharma",
            "student_last_name": "",
            "student_phone": "9001110488",
            "student_image": ""
          },
          "formattedDate": "2 Jul,2024 10:06 AM"
        },
        {
          "id": 17867,
          "title": "Other",
          "description": "मैं Junior Accountant मे 2x में चुने जाने पर Quality Education Online platform को धन्यवाद करता हूँ । इस Exam के II पेपर के लिए Quality Educution का सहयोग रहा और मै इसके लिए दीपक सर और अर्जुन सर का विषेष आभारी हूँ । \nआगे मेरा निवेदन है कि आप हमारा मार्गदर्शन करें मेरे 619 score card के बाद बन रहे तो क्या chances है इस दुविधा का निराकरण करे ।\nएक बार फिर धन्यवाद Quality Education l",
          "created": "2024-07-02T08:11:39+05:30",
          "ot_student": {
            "student_first_name": "Prakash Singh Gehlot",
            "student_last_name": "",
            "student_phone": "7610859567",
            "student_image": ""
          },
          "formattedDate": "2 Jul,2024 08:11 AM"
        },
        {
          "id": 17860,
          "title": "Other",
          "description": "finally I got selected in jra in  2 x thank  quality education team your test series was so helpful for me.Once again thank you",
          "created": "2024-06-30T21:37:51+05:30",
          "ot_student": {
            "student_first_name": "KANHAIYA BILONIA",
            "student_last_name": "",
            "student_phone": "9024103479",
            "student_image": ""
          },
          "formattedDate": "30 Jun,2024 09:37 PM"
        },
        {
          "id": 17847,
          "title": "Your Reviews",
          "description": "नरेन्द्र सर नमस्कार।  आपका बहुत बहुत आभार व  धन्यवाद। मेरा जूनियर अकाउंटेंट दो गुणा में सिलेक्शन हो गया है। मैंने आपकी बेहतरीन टीम द्वारा रिकॉर्डेड कोर्स व शानदार डिजाइन की गई टेस्ट सीरीज खरीदी थी, जिसका आज मुझे बहुत ही अच्छा रिजल्ट मिला है। मै अब RAS mains की preparation कर रहा हूं जिसकी टेस्ट ऑफलाइन टेस्ट सीरीज भी मैंने आपसे ही purchase की है। आपके टेलीग्राम चैनल का कंटेंट और आपके द्वारा ली गई  पॉलिटी क्लास और SI विजेंदर सर के economic सर्वे से RAS pre में हुबहू प्रश्न मिले जिससे मेरा pre क्लियर हुआ।\nसर एक बार आप से मिलने की इच्छा है। पैर छूकर आपसे आशीर्वाद लेना है।\nविनोद कुमार सैनी ( वन विभाग)",
          "created": "2024-06-29T11:15:16+05:30",
          "ot_student": {
            "student_first_name": "VINOD kumar",
            "student_last_name": "",
            "student_phone": "7014190396",
            "student_image": ""
          },
          "formattedDate": "29 Jun,2024 11:15 AM"
        }
      ],
      "app_rating_status": "1"
    }
  }

  // console.log(testData.params.category)
  const newTestData = testData.params.category;
  //  console.log("newTestData",newTestData)
return (
    <>
      <section className="main-sec">
        <div className="row">
          <div className="col-lg-12">
            <div className="dashboard-title">
              <h4 className="dash-head">
                <i className="fas fa-book me-2" />
                Test Category List
              </h4>
              <Link to="#" className="blu-btn">
                <i className="far fa-plus me-2" />
                Add Test Category
              </Link>
            </div>
            <div className="custom-bredcump">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/testcategorylist">Test Category Management</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Test Category List
                  </li>
                </ol>
              </nav>
            </div>
          </div>
          <div className="col-lg-12">
            <div className="cards bus-list">
              <div className="bus-filter">
                <div className="row justify-content-end">
                  <div className="col-lg-7">
                    <h4 className="table-head">Test Category ({})</h4>
                  </div>
                  <div className="col-lg-5">
                    <form action="">
                      <div className="row justify-content-end gx-2 align-items-center">
                        <div className="col-lg-6">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Search Category..."
                            // onChange={(e)=>setSearchCategoryName(e.target.value)}
                          />
                        </div>
                        <div className="col-lg-2">
                          <button type="submit" className="blu-btn w-100">
                            Search
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div className="table table-responsive custom-table">
                <table className="table table-borderless">
                  <thead>
                    <tr>
                      <th>SR NO.</th>
                      <th>Image</th>
                      <th>Test Category NAME</th>
                      <th>SEQUENCE</th>
                      <th>STATUS</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {newTestData.map((arr, i) => (
                      <tr key={arr.exam_cat_id}>
                        <th>{i + 1}</th>
                        <th>
                          <img
                            src={arr?.exam_cat_image}
                            className="img-40"
                            alt=""
                            // onError={(e)=>(e.target.src=categoryImg)}
                          />
                        </th>
                        <td><Link to={`/testpackagelist/${arr?.exam_cat_id}`}>{arr?.exam_cat_name}</Link></td>
                        <td>
                          <div className="d-flex table-box">
                            <input
                              type="number"
                              className="form-control"
                              defaultValue={arr?.exam_cat_order} 
                              // onChange={(e) =>
                              //   setUpdateSequence(e.target.value)
                              // }
                              min={0}
                            />
                            <button
                              className="btn btn-success ms-2"
                              // onClick={() => handleUpdate(arr._id)}
                            > 
                              Update
                            </button>
                          </div>
                        </td>
                        <td>
                          <div className="form-check form-switch">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              defaultChecked={arr?.exam_cat_status}
                              // onClick={() => handleStatus(arr._id)}
                            />
                          </div>
                        </td>
                        
                        <td>
                          <Link
                          to="#"
                            // to={`/test_category_edit/${arr._id}`}
                            className="mx-2"
                          >
                            <img src={edit} alt="" />
                          </Link>
                          <Link
                            to="#"
                            // onClick={() => handleDelete(arr?._id)}
                            className="mx-2"
                          >
                            <img src={trash} alt="" />
                          </Link>
                        </td>
                      </tr>
                    ))} 
                  </tbody>
                </table>
                {/* { showPagination && <div className="custom-pagination">
                  <ul className="pagination">
                    <li className="page-item">
                      <button
                        className="page-link"
                        to=""
                        aria-label="Previous"
                        disabled={pageNumber === 1 && true}
                        onClick={() => setPageNumber((prev) => prev - 1)}
                      >
                        <span aria-hidden="true">«</span>
                      </button>
                    </li>
                    {pageNumber >= 2 && (
                      <li className="page-item">
                        <Link className="page-link" to="">
                          {pageNumber - 1}
                        </Link>
                      </li>
                    )}
                    <li className="page-item">
                      <Link className="page-link active" to="">
                        {pageNumber}
                      </Link>
                    </li>
                    <li className="page-item">
                      <Link
                        className="page-link"
                        to=""
                        onClick={() => setPageNumber((prev) => prev + 1)}
                      >
                        {pageNumber + 1}
                      </Link>
                    </li>
                    <li className="page-item">
                      <Link
                        className="page-link"
                        to=""
                        onClick={() => setPageNumber((prev) => prev + 2)}
                      >
                        {pageNumber + 2}
                      </Link>
                    </li>
                    <li className="page-item">
                      <Link
                        className="page-link"
                        to=""
                        aria-label="Next"
                        onClick={() => setPageNumber((prev) => prev + 1)}
                      >
                        <span aria-hidden="true">»</span>
                      </Link>
                    </li>
                  </ul>
                </div>} */}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default TestCategoryList;
