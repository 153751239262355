import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import edit from "../../assets/images/edit.png";
import trash from "../../assets/images/trash.png";
import axios from "axios";
const TestList = () => {
  const { packid } = useParams();
  console.log("packid", packid);
// const [testData,setTestData] = useState([]);
//   useEffect(() => {
//     fetchTestData();
//   }, []);

//   const fetchTestData = async () => {
//     const response = await axios.post(
//       "https://qualityeducation.in/app/webapi/dashboard/coursedetail",
//       { pack_id: "140", user_id: "22541" },
//       {
//         headers: {
//           Authorization: localStorage.getItem("token"),
//         },
//       }
//     );
//     console.log("response",response)
//   };

const testData  = {
    "status": true,
    "message": "course detail fetcha successfully",
    "params": {
      "message": "ok",
      "results": {
        "sub_pack_id": 140,
        "sub_pack_title": "Agriculture Supervisor Test Series",
        "sub_pack_price": 249,
        "sub_pack_no_test": "20",
        "sub_pack_validity": "365",
        "sub_pack_created_date": "2023-05-26T01:06:03+05:30",
        "sub_pack_image": "1685089704??????????????image.jpg",
        "sub_pack_status": true,
        "sub_pack_dept": 400,
        "sub_pack_et": 0,
        "sub_pack_series": "",
        "sub_pack_desc": "<h1 helvetica=\"\" neue\",=\"\" helvetica,=\"\" arial,=\"\" sans-serif;=\"\" line-height:=\"\" 1.53846;=\"\" color:=\"\" rgb(51,=\"\" 51,=\"\" 51);\"=\"\" style=\"font-family: Roboto, &quot;Helvetica Neue&quot;, Helvetica, Arial, sans-serif; line-height: 1.53846; color: rgb(51, 51, 51);\"><font color=\"#ff0000\"><b>For 20% Instant Discount Use Coupen Code - NS01</b></font></h1><h1 helvetica=\"\" neue\",=\"\" helvetica,=\"\" arial,=\"\" sans-serif;=\"\" line-height:=\"\" 1.53846;=\"\" color:=\"\" rgb(51,=\"\" 51,=\"\" 51);\"=\"\" style=\"font-family: Roboto, \"><u style=\"color: inherit; font-family: inherit; font-size: 17px; letter-spacing: -0.015em;\"><b><font color=\"#083139\">Agriculture Supervisor Online Test Series</font></b></u><br></h1><p><br></p><p><font color=\"#9c0000\">Schedule Wise &amp; Full Length Test Series</font></p><p><font color=\"#9c0000\"><br></font></p><p><font color=\"#9c0000\">Total Test - 20 </font></p><p><font color=\"#9c0000\"><br></font></p><p><font color=\"#9c0000\">[ Schedule Wise - 12, Full Length - 08 )</font><br></p><p><br></p><ul><li><font color=\"#0000ff\">All Questions with Explaination</font></li></ul><p><br></p><ul><li><font color=\"#295218\">Re-Attempt Option for Revision</font></li></ul><p><br></p><ul><li><font color=\"#ce0000\">All Rajasthan Rank</font></li></ul><p><br></p><p><font color=\"#846300\">Validity - 1year.</font></p>",
        "sub_actual_price": 249,
        "sub_total_minutes": 0,
        "sub_total_question": 100,
        "sub_pack_background": "",
        "sub_pack_no_video": 0,
        "sub_popular": 1,
        "no_of_pdf": "12",
        "package_detail": "",
        "sub_type": "Agriculture Supervisor",
        "sub_pack_type": 0,
        "sub_pack_bgimage": "1685089704??????????????image.jpg",
        "sub_pack_order": 15,
        "sub_pack_slug": "agriculture-supervisor-test-series",
        "sub_pack_refamount": 0,
        "sub_pack_refcashback": 0,
        "user_purchase_status": false
      },
      "results_freeexam": [],
      "results_mocexam": [
        {
          "test_id": 2512,
          "test_duration": "120",
          "test_ts_id": 0,
          "test_dpt_id": "400",
          "test_dept_names": "Agriculture Supervisor",
          "test_et_id": 0,
          "test_number": 0,
          "test_sub_id": "",
          "test_marks": 0,
          "test_negative_marks": 0,
          "test_sub_question": "",
          "test_datetime": "2023-05-26T01:25:10+05:30",
          "test_status": 1,
          "test_admin_id": 0,
          "test_series_id": 0,
          "test_paper_file": "",
          "test_solution_file": "",
          "test_schedule_file": "https://qualityeducation.in/app/uploads/paper_image/1685268775agriculturesuperviorexam2023Sch.pdf",
          "test_free_status": true,
          "product_in_stock": 0,
          "test_sectional_status": 0,
          "test_live_status": 0,
          "test_end_date": "2023-06-11T11:00:00+05:30",
          "test_announce_date": "2023-06-11T11:00:00+05:30",
          "test_start_date": null,
          "ot_test_solution_datetime": null,
          "test_subject_stope_time": "",
          "test_syllabus_info": "",
          "test_comming_date": "",
          "test_title": "Agriculture Supervisor Test - 1 ( Schedule Wise )",
          "test_total_marks": 300,
          "test_total_question": 100,
          "product_publish_date": null,
          "test_section_type": 1,
          "test_type": 0,
          "test_scheduled_details": null,
          "test_display_date": "yes",
          "user_section_test": null,
          "reward_point": [],
          "user_test_perform_status": "Live",
          "user_test_status": "Pending",
          "formattedDate": "11 Jun,2023 11:00 AM",
          "test_date_live": "2023-06-11 11:00:00",
          "test_date_local": "2024-08-30 12:52:50"
        },
        {
          "test_id": 2535,
          "test_duration": "120",
          "test_ts_id": 0,
          "test_dpt_id": "400",
          "test_dept_names": "Agriculture Supervisor",
          "test_et_id": 0,
          "test_number": 0,
          "test_sub_id": "",
          "test_marks": 0,
          "test_negative_marks": 0,
          "test_sub_question": "",
          "test_datetime": "2023-06-12T07:03:03+05:30",
          "test_status": 1,
          "test_admin_id": 0,
          "test_series_id": 0,
          "test_paper_file": "",
          "test_solution_file": "",
          "test_schedule_file": "https://qualityeducation.in/app/uploads/paper_image/1686832362Test-2.pdf",
          "test_free_status": true,
          "product_in_stock": 0,
          "test_sectional_status": 0,
          "test_live_status": 0,
          "test_end_date": "2023-06-18T05:00:00+05:30",
          "test_announce_date": "2023-06-18T05:00:00+05:30",
          "test_start_date": null,
          "ot_test_solution_datetime": null,
          "test_subject_stope_time": "",
          "test_syllabus_info": "",
          "test_comming_date": "",
          "test_title": "Agriculture Supervisor Test - 2 ( Schedule Wise )",
          "test_total_marks": 300,
          "test_total_question": 100,
          "product_publish_date": null,
          "test_section_type": 1,
          "test_type": 0,
          "test_scheduled_details": null,
          "test_display_date": "yes",
          "user_section_test": null,
          "reward_point": [],
          "user_test_perform_status": "Live",
          "user_test_status": "Pending",
          "formattedDate": "18 Jun,2023 05:00 AM",
          "test_date_live": "2023-06-18 05:00:00",
          "test_date_local": "2024-08-30 12:52:50"
        },
        {
          "test_id": 2536,
          "test_duration": "120",
          "test_ts_id": 0,
          "test_dpt_id": "400",
          "test_dept_names": "Agriculture Supervisor",
          "test_et_id": 0,
          "test_number": 0,
          "test_sub_id": "",
          "test_marks": 0,
          "test_negative_marks": 0,
          "test_sub_question": "",
          "test_datetime": "2023-06-12T07:03:53+05:30",
          "test_status": 1,
          "test_admin_id": 0,
          "test_series_id": 0,
          "test_paper_file": "",
          "test_solution_file": "",
          "test_schedule_file": "https://qualityeducation.in/app/uploads/paper_image/1687173177Schedule-3.pdf",
          "test_free_status": true,
          "product_in_stock": 0,
          "test_sectional_status": 0,
          "test_live_status": 0,
          "test_end_date": "2023-06-25T05:00:00+05:30",
          "test_announce_date": "2023-06-25T05:00:00+05:30",
          "test_start_date": null,
          "ot_test_solution_datetime": null,
          "test_subject_stope_time": "",
          "test_syllabus_info": "",
          "test_comming_date": "",
          "test_title": "Agriculture Supervisor Test - 3 ( Schedule Wise )",
          "test_total_marks": 300,
          "test_total_question": 100,
          "product_publish_date": null,
          "test_section_type": 1,
          "test_type": 0,
          "test_scheduled_details": null,
          "test_display_date": "yes",
          "user_section_test": null,
          "reward_point": [],
          "user_test_perform_status": "Live",
          "user_test_status": "Pending",
          "formattedDate": "25 Jun,2023 05:00 AM",
          "test_date_live": "2023-06-25 05:00:00",
          "test_date_local": "2024-08-30 12:52:50"
        },
        {
          "test_id": 2537,
          "test_duration": "120",
          "test_ts_id": 0,
          "test_dpt_id": "400",
          "test_dept_names": "Agriculture Supervisor",
          "test_et_id": 0,
          "test_number": 0,
          "test_sub_id": "",
          "test_marks": 0,
          "test_negative_marks": 0,
          "test_sub_question": "",
          "test_datetime": "2023-06-12T07:05:04+05:30",
          "test_status": 1,
          "test_admin_id": 0,
          "test_series_id": 0,
          "test_paper_file": "",
          "test_solution_file": "",
          "test_schedule_file": "https://qualityeducation.in/app/uploads/paper_image/168803168102-07-2023_Ag.Su.-4Schedule_FF(REV1-3)(1).pdf",
          "test_free_status": true,
          "product_in_stock": 0,
          "test_sectional_status": 0,
          "test_live_status": 0,
          "test_end_date": "2023-07-02T18:00:00+05:30",
          "test_announce_date": "2023-07-02T18:00:00+05:30",
          "test_start_date": null,
          "ot_test_solution_datetime": null,
          "test_subject_stope_time": "",
          "test_syllabus_info": "",
          "test_comming_date": "",
          "test_title": "Agriculture Supervisor Test - 4 ( Schedule Wise )",
          "test_total_marks": 300,
          "test_total_question": 100,
          "product_publish_date": null,
          "test_section_type": 1,
          "test_type": 0,
          "test_scheduled_details": null,
          "test_display_date": "yes",
          "user_section_test": null,
          "reward_point": [],
          "user_test_perform_status": "Live",
          "user_test_status": "Pending",
          "formattedDate": "2 Jul,2023 06:00 PM",
          "test_date_live": "2023-07-02 18:00:00",
          "test_date_local": "2024-08-30 12:52:50"
        },
        {
          "test_id": 2538,
          "test_duration": "120",
          "test_ts_id": 0,
          "test_dpt_id": "400",
          "test_dept_names": "Agriculture Supervisor",
          "test_et_id": 0,
          "test_number": 0,
          "test_sub_id": "",
          "test_marks": 0,
          "test_negative_marks": 0,
          "test_sub_question": "",
          "test_datetime": "2023-06-12T07:06:30+05:30",
          "test_status": 1,
          "test_admin_id": 0,
          "test_series_id": 0,
          "test_paper_file": "",
          "test_solution_file": "",
          "test_schedule_file": "https://qualityeducation.in/app/uploads/paper_image/168811714105krishipryveshakonlinetest5.pdf",
          "test_free_status": true,
          "product_in_stock": 0,
          "test_sectional_status": 0,
          "test_live_status": 0,
          "test_end_date": "2023-07-09T17:00:00+05:30",
          "test_announce_date": "2023-07-09T17:00:00+05:30",
          "test_start_date": null,
          "ot_test_solution_datetime": null,
          "test_subject_stope_time": "",
          "test_syllabus_info": "",
          "test_comming_date": "",
          "test_title": "Agriculture Supervisor Test - 5 ( Schedule Wise )",
          "test_total_marks": 300,
          "test_total_question": 100,
          "product_publish_date": null,
          "test_section_type": 1,
          "test_type": 0,
          "test_scheduled_details": null,
          "test_display_date": "yes",
          "user_section_test": null,
          "reward_point": [],
          "user_test_perform_status": "Live",
          "user_test_status": "Pending",
          "formattedDate": "9 Jul,2023 05:00 PM",
          "test_date_live": "2023-07-09 17:00:00",
          "test_date_local": "2024-08-30 12:52:50"
        },
        {
          "test_id": 2539,
          "test_duration": "120",
          "test_ts_id": 0,
          "test_dpt_id": "400",
          "test_dept_names": "Agriculture Supervisor",
          "test_et_id": 0,
          "test_number": 0,
          "test_sub_id": "",
          "test_marks": 0,
          "test_negative_marks": 0,
          "test_sub_question": "",
          "test_datetime": "2023-06-12T07:07:26+05:30",
          "test_status": 1,
          "test_admin_id": 0,
          "test_series_id": 0,
          "test_paper_file": "",
          "test_solution_file": "",
          "test_schedule_file": "https://qualityeducation.in/app/uploads/paper_image/168914466606krishipryveshakonlinetest6(1).pdf",
          "test_free_status": true,
          "product_in_stock": 0,
          "test_sectional_status": 0,
          "test_live_status": 0,
          "test_end_date": "2023-07-16T17:00:00+05:30",
          "test_announce_date": "2023-07-16T17:00:00+05:30",
          "test_start_date": null,
          "ot_test_solution_datetime": null,
          "test_subject_stope_time": "",
          "test_syllabus_info": "",
          "test_comming_date": "",
          "test_title": "Agriculture Supervisor Test - 6 ( Schedule Wise )",
          "test_total_marks": 300,
          "test_total_question": 100,
          "product_publish_date": null,
          "test_section_type": 1,
          "test_type": 0,
          "test_scheduled_details": null,
          "test_display_date": "yes",
          "user_section_test": null,
          "reward_point": [],
          "user_test_perform_status": "Live",
          "user_test_status": "Pending",
          "formattedDate": "16 Jul,2023 05:00 PM",
          "test_date_live": "2023-07-16 17:00:00",
          "test_date_local": "2024-08-30 12:52:50"
        },
        {
          "test_id": 2540,
          "test_duration": "120",
          "test_ts_id": 0,
          "test_dpt_id": "400",
          "test_dept_names": "Agriculture Supervisor",
          "test_et_id": 0,
          "test_number": 0,
          "test_sub_id": "",
          "test_marks": 0,
          "test_negative_marks": 0,
          "test_sub_question": "",
          "test_datetime": "2023-06-12T07:08:20+05:30",
          "test_status": 1,
          "test_admin_id": 0,
          "test_series_id": 0,
          "test_paper_file": "",
          "test_solution_file": "",
          "test_schedule_file": "https://qualityeducation.in/app/uploads/paper_image/16895883305_6163668624021129713.pdf",
          "test_free_status": true,
          "product_in_stock": 0,
          "test_sectional_status": 0,
          "test_live_status": 0,
          "test_end_date": "2023-07-23T17:00:00+05:30",
          "test_announce_date": "2023-07-23T17:00:00+05:30",
          "test_start_date": null,
          "ot_test_solution_datetime": null,
          "test_subject_stope_time": "",
          "test_syllabus_info": "",
          "test_comming_date": "",
          "test_title": "Agriculture Supervisor Test - 7 ( Schedule Wise )",
          "test_total_marks": 300,
          "test_total_question": 100,
          "product_publish_date": null,
          "test_section_type": 1,
          "test_type": 0,
          "test_scheduled_details": null,
          "test_display_date": "yes",
          "user_section_test": null,
          "reward_point": [],
          "user_test_perform_status": "Live",
          "user_test_status": "Pending",
          "formattedDate": "23 Jul,2023 05:00 PM",
          "test_date_live": "2023-07-23 17:00:00",
          "test_date_local": "2024-08-30 12:52:50"
        },
        {
          "test_id": 2541,
          "test_duration": "120",
          "test_ts_id": 0,
          "test_dpt_id": "400",
          "test_dept_names": "Agriculture Supervisor",
          "test_et_id": 0,
          "test_number": 0,
          "test_sub_id": "",
          "test_marks": 0,
          "test_negative_marks": 0,
          "test_sub_question": "",
          "test_datetime": "2023-06-12T07:09:21+05:30",
          "test_status": 1,
          "test_admin_id": 0,
          "test_series_id": 0,
          "test_paper_file": "",
          "test_solution_file": "",
          "test_schedule_file": "https://qualityeducation.in/app/uploads/paper_image/169030969508krishipryveshakonlinetest8revisedtest.pdf",
          "test_free_status": true,
          "product_in_stock": 0,
          "test_sectional_status": 0,
          "test_live_status": 0,
          "test_end_date": "2023-07-30T17:00:00+05:30",
          "test_announce_date": "2023-07-30T17:00:00+05:30",
          "test_start_date": null,
          "ot_test_solution_datetime": null,
          "test_subject_stope_time": "",
          "test_syllabus_info": "",
          "test_comming_date": "",
          "test_title": "Agriculture Supervisor Test - 8 ( Schedule Wise )",
          "test_total_marks": 300,
          "test_total_question": 100,
          "product_publish_date": null,
          "test_section_type": 1,
          "test_type": 0,
          "test_scheduled_details": null,
          "test_display_date": "yes",
          "user_section_test": null,
          "reward_point": [],
          "user_test_perform_status": "Live",
          "user_test_status": "Pending",
          "formattedDate": "30 Jul,2023 05:00 PM",
          "test_date_live": "2023-07-30 17:00:00",
          "test_date_local": "2024-08-30 12:52:50"
        },
        {
          "test_id": 2542,
          "test_duration": "120",
          "test_ts_id": 0,
          "test_dpt_id": "400",
          "test_dept_names": "Agriculture Supervisor",
          "test_et_id": 0,
          "test_number": 0,
          "test_sub_id": "",
          "test_marks": 0,
          "test_negative_marks": 0,
          "test_sub_question": "",
          "test_datetime": "2023-06-12T07:10:17+05:30",
          "test_status": 1,
          "test_admin_id": 0,
          "test_series_id": 0,
          "test_paper_file": "",
          "test_solution_file": "",
          "test_schedule_file": "https://qualityeducation.in/app/uploads/paper_image/169078505709AgricultureSupervisoronlinetest9.pdf",
          "test_free_status": true,
          "product_in_stock": 0,
          "test_sectional_status": 0,
          "test_live_status": 0,
          "test_end_date": "2023-08-06T17:00:00+05:30",
          "test_announce_date": "2023-08-06T17:00:00+05:30",
          "test_start_date": null,
          "ot_test_solution_datetime": null,
          "test_subject_stope_time": "",
          "test_syllabus_info": "",
          "test_comming_date": "",
          "test_title": "Agriculture Supervisor Test - 9 ( Schedule Wise )",
          "test_total_marks": 300,
          "test_total_question": 100,
          "product_publish_date": null,
          "test_section_type": 1,
          "test_type": 0,
          "test_scheduled_details": null,
          "test_display_date": "yes",
          "user_section_test": null,
          "reward_point": [],
          "user_test_perform_status": "Live",
          "user_test_status": "Pending",
          "formattedDate": "6 Aug,2023 05:00 PM",
          "test_date_live": "2023-08-06 17:00:00",
          "test_date_local": "2024-08-30 12:52:50"
        },
        {
          "test_id": 2543,
          "test_duration": "120",
          "test_ts_id": 0,
          "test_dpt_id": "400",
          "test_dept_names": "Agriculture Supervisor",
          "test_et_id": 0,
          "test_number": 0,
          "test_sub_id": "",
          "test_marks": 0,
          "test_negative_marks": 0,
          "test_sub_question": "",
          "test_datetime": "2023-06-12T07:15:10+05:30",
          "test_status": 1,
          "test_admin_id": 0,
          "test_series_id": 0,
          "test_paper_file": "",
          "test_solution_file": "",
          "test_schedule_file": "https://qualityeducation.in/app/uploads/paper_image/169078510210AgricultureSupervisoronlinetest10.pdf",
          "test_free_status": true,
          "product_in_stock": 0,
          "test_sectional_status": 0,
          "test_live_status": 0,
          "test_end_date": "2023-08-13T18:00:00+05:30",
          "test_announce_date": "2023-08-13T18:00:00+05:30",
          "test_start_date": null,
          "ot_test_solution_datetime": null,
          "test_subject_stope_time": "",
          "test_syllabus_info": "",
          "test_comming_date": "",
          "test_title": "Agriculture Supervisor Test - 10 ( Schedule Wise )",
          "test_total_marks": 300,
          "test_total_question": 100,
          "product_publish_date": null,
          "test_section_type": 1,
          "test_type": 0,
          "test_scheduled_details": null,
          "test_display_date": "yes",
          "user_section_test": null,
          "reward_point": [],
          "user_test_perform_status": "Live",
          "user_test_status": "Pending",
          "formattedDate": "13 Aug,2023 06:00 PM",
          "test_date_live": "2023-08-13 18:00:00",
          "test_date_local": "2024-08-30 12:52:50"
        },
        {
          "test_id": 2544,
          "test_duration": "120",
          "test_ts_id": 0,
          "test_dpt_id": "400",
          "test_dept_names": "Agriculture Supervisor",
          "test_et_id": 0,
          "test_number": 0,
          "test_sub_id": "",
          "test_marks": 0,
          "test_negative_marks": 0,
          "test_sub_question": "",
          "test_datetime": "2023-06-12T07:16:16+05:30",
          "test_status": 1,
          "test_admin_id": 0,
          "test_series_id": 0,
          "test_paper_file": "",
          "test_solution_file": "",
          "test_schedule_file": "https://qualityeducation.in/app/uploads/paper_image/169078512211AgricultureSupervisoronlinetest11.pdf",
          "test_free_status": true,
          "product_in_stock": 0,
          "test_sectional_status": 0,
          "test_live_status": 0,
          "test_end_date": "2023-08-20T18:00:00+05:30",
          "test_announce_date": "2023-08-20T18:00:00+05:30",
          "test_start_date": null,
          "ot_test_solution_datetime": null,
          "test_subject_stope_time": "",
          "test_syllabus_info": "",
          "test_comming_date": "",
          "test_title": "Agriculture Supervisor Test - 11 ( Schedule Wise )",
          "test_total_marks": 300,
          "test_total_question": 100,
          "product_publish_date": null,
          "test_section_type": 1,
          "test_type": 0,
          "test_scheduled_details": null,
          "test_display_date": "yes",
          "user_section_test": null,
          "reward_point": [],
          "user_test_perform_status": "Live",
          "user_test_status": "Pending",
          "formattedDate": "20 Aug,2023 06:00 PM",
          "test_date_live": "2023-08-20 18:00:00",
          "test_date_local": "2024-08-30 12:52:50"
        },
        {
          "test_id": 2545,
          "test_duration": "120",
          "test_ts_id": 0,
          "test_dpt_id": "400",
          "test_dept_names": "Agriculture Supervisor",
          "test_et_id": 0,
          "test_number": 0,
          "test_sub_id": "",
          "test_marks": 0,
          "test_negative_marks": 0,
          "test_sub_question": "",
          "test_datetime": "2023-06-12T07:17:27+05:30",
          "test_status": 1,
          "test_admin_id": 0,
          "test_series_id": 0,
          "test_paper_file": "",
          "test_solution_file": "",
          "test_schedule_file": "https://qualityeducation.in/app/uploads/paper_image/169207863112AgricultureSupervisoronlinetest12revised.pdf",
          "test_free_status": true,
          "product_in_stock": 0,
          "test_sectional_status": 0,
          "test_live_status": 0,
          "test_end_date": "2023-08-27T18:00:00+05:30",
          "test_announce_date": "2023-08-27T18:00:00+05:30",
          "test_start_date": null,
          "ot_test_solution_datetime": null,
          "test_subject_stope_time": "",
          "test_syllabus_info": "",
          "test_comming_date": "",
          "test_title": "Agriculture Supervisor Test - 12 ( Schedule Wise )",
          "test_total_marks": 300,
          "test_total_question": 100,
          "product_publish_date": null,
          "test_section_type": 1,
          "test_type": 0,
          "test_scheduled_details": null,
          "test_display_date": "yes",
          "user_section_test": null,
          "reward_point": [],
          "user_test_perform_status": "Live",
          "user_test_status": "Pending",
          "formattedDate": "27 Aug,2023 06:00 PM",
          "test_date_live": "2023-08-27 18:00:00",
          "test_date_local": "2024-08-30 12:52:50"
        },
        {
          "test_id": 2667,
          "test_duration": "120",
          "test_ts_id": 0,
          "test_dpt_id": "400",
          "test_dept_names": "Agriculture Supervisor",
          "test_et_id": 0,
          "test_number": 0,
          "test_sub_id": "",
          "test_marks": 0,
          "test_negative_marks": 0,
          "test_sub_question": "",
          "test_datetime": "2023-08-23T12:26:59+05:30",
          "test_status": 1,
          "test_admin_id": 0,
          "test_series_id": 0,
          "test_paper_file": "",
          "test_solution_file": "",
          "test_schedule_file": "",
          "test_free_status": true,
          "product_in_stock": 0,
          "test_sectional_status": 0,
          "test_live_status": 0,
          "test_end_date": "2023-09-03T18:00:00+05:30",
          "test_announce_date": "2023-09-03T18:00:00+05:30",
          "test_start_date": null,
          "ot_test_solution_datetime": null,
          "test_subject_stope_time": "",
          "test_syllabus_info": "",
          "test_comming_date": "",
          "test_title": "Agriculture Supervisor Test - 13 ( Schedule Wise )",
          "test_total_marks": 300,
          "test_total_question": 100,
          "product_publish_date": null,
          "test_section_type": 1,
          "test_type": 0,
          "test_scheduled_details": null,
          "test_display_date": "yes",
          "user_section_test": null,
          "reward_point": [],
          "user_test_perform_status": "Live",
          "user_test_status": "Pending",
          "formattedDate": "3 Sep,2023 06:00 PM",
          "test_date_live": "2023-09-03 18:00:00",
          "test_date_local": "2024-08-30 12:52:50"
        },
        {
          "test_id": 2668,
          "test_duration": "120",
          "test_ts_id": 0,
          "test_dpt_id": "400",
          "test_dept_names": "Agriculture Supervisor",
          "test_et_id": 0,
          "test_number": 0,
          "test_sub_id": "",
          "test_marks": 0,
          "test_negative_marks": 0,
          "test_sub_question": "",
          "test_datetime": "2023-08-23T12:29:26+05:30",
          "test_status": 1,
          "test_admin_id": 0,
          "test_series_id": 0,
          "test_paper_file": "",
          "test_solution_file": "",
          "test_schedule_file": "",
          "test_free_status": true,
          "product_in_stock": 0,
          "test_sectional_status": 0,
          "test_live_status": 0,
          "test_end_date": "2023-09-17T18:00:00+05:30",
          "test_announce_date": "2023-09-17T18:00:00+05:30",
          "test_start_date": null,
          "ot_test_solution_datetime": null,
          "test_subject_stope_time": "",
          "test_syllabus_info": "",
          "test_comming_date": "",
          "test_title": "Agriculture Supervisor Test - 14 ( Full Schedule  )",
          "test_total_marks": 300,
          "test_total_question": 100,
          "product_publish_date": null,
          "test_section_type": 1,
          "test_type": 0,
          "test_scheduled_details": null,
          "test_display_date": "yes",
          "user_section_test": null,
          "reward_point": [],
          "user_test_perform_status": "Live",
          "user_test_status": "Pending",
          "formattedDate": "17 Sep,2023 06:00 PM",
          "test_date_live": "2023-09-17 18:00:00",
          "test_date_local": "2024-08-30 12:52:50"
        },
        {
          "test_id": 2669,
          "test_duration": "120",
          "test_ts_id": 0,
          "test_dpt_id": "400",
          "test_dept_names": "Agriculture Supervisor",
          "test_et_id": 0,
          "test_number": 0,
          "test_sub_id": "",
          "test_marks": 0,
          "test_negative_marks": 0,
          "test_sub_question": "",
          "test_datetime": "2023-08-23T12:33:15+05:30",
          "test_status": 1,
          "test_admin_id": 0,
          "test_series_id": 0,
          "test_paper_file": "",
          "test_solution_file": "",
          "test_schedule_file": "",
          "test_free_status": true,
          "product_in_stock": 0,
          "test_sectional_status": 0,
          "test_live_status": 0,
          "test_end_date": "2023-10-01T18:00:00+05:30",
          "test_announce_date": "2023-10-01T18:00:00+05:30",
          "test_start_date": null,
          "ot_test_solution_datetime": null,
          "test_subject_stope_time": "",
          "test_syllabus_info": "",
          "test_comming_date": "",
          "test_title": "Agriculture Supervisor Test - 15 ( Full Schedule )",
          "test_total_marks": 300,
          "test_total_question": 100,
          "product_publish_date": null,
          "test_section_type": 1,
          "test_type": 0,
          "test_scheduled_details": null,
          "test_display_date": "yes",
          "user_section_test": null,
          "reward_point": [],
          "user_test_perform_status": "Live",
          "user_test_status": "Pending",
          "formattedDate": "1 Oct,2023 06:00 PM",
          "test_date_live": "2023-10-01 18:00:00",
          "test_date_local": "2024-08-30 12:52:50"
        },
        {
          "test_id": 2670,
          "test_duration": "120",
          "test_ts_id": 0,
          "test_dpt_id": "400",
          "test_dept_names": "Agriculture Supervisor",
          "test_et_id": 0,
          "test_number": 0,
          "test_sub_id": "",
          "test_marks": 0,
          "test_negative_marks": 0,
          "test_sub_question": "",
          "test_datetime": "2023-08-23T12:35:27+05:30",
          "test_status": 1,
          "test_admin_id": 0,
          "test_series_id": 0,
          "test_paper_file": "",
          "test_solution_file": "",
          "test_schedule_file": "",
          "test_free_status": true,
          "product_in_stock": 0,
          "test_sectional_status": 0,
          "test_live_status": 0,
          "test_end_date": "2023-10-15T18:00:00+05:30",
          "test_announce_date": "2023-10-15T18:00:00+05:30",
          "test_start_date": null,
          "ot_test_solution_datetime": null,
          "test_subject_stope_time": "",
          "test_syllabus_info": "",
          "test_comming_date": "",
          "test_title": "Agriculture Supervisor Test - 16 ( Full Schedule  )",
          "test_total_marks": 300,
          "test_total_question": 100,
          "product_publish_date": null,
          "test_section_type": 1,
          "test_type": 0,
          "test_scheduled_details": null,
          "test_display_date": "yes",
          "user_section_test": null,
          "reward_point": [],
          "user_test_perform_status": "Live",
          "user_test_status": "Pending",
          "formattedDate": "15 Oct,2023 06:00 PM",
          "test_date_live": "2023-10-15 18:00:00",
          "test_date_local": "2024-08-30 12:52:50"
        },
        {
          "test_id": 2671,
          "test_duration": "120",
          "test_ts_id": 0,
          "test_dpt_id": "400",
          "test_dept_names": "Agriculture Supervisor",
          "test_et_id": 0,
          "test_number": 0,
          "test_sub_id": "",
          "test_marks": 0,
          "test_negative_marks": 0,
          "test_sub_question": "",
          "test_datetime": "2023-08-23T12:40:48+05:30",
          "test_status": 1,
          "test_admin_id": 0,
          "test_series_id": 0,
          "test_paper_file": "",
          "test_solution_file": "",
          "test_schedule_file": "",
          "test_free_status": true,
          "product_in_stock": 0,
          "test_sectional_status": 0,
          "test_live_status": 0,
          "test_end_date": "2023-10-29T18:00:00+05:30",
          "test_announce_date": "2023-10-29T18:00:00+05:30",
          "test_start_date": null,
          "ot_test_solution_datetime": null,
          "test_subject_stope_time": "",
          "test_syllabus_info": "",
          "test_comming_date": "",
          "test_title": "Agriculture Supervisor Test - 17 ( Full Schedule  )",
          "test_total_marks": 300,
          "test_total_question": 100,
          "product_publish_date": null,
          "test_section_type": 1,
          "test_type": 0,
          "test_scheduled_details": null,
          "test_display_date": "yes",
          "user_section_test": null,
          "reward_point": [],
          "user_test_perform_status": "Live",
          "user_test_status": "Pending",
          "formattedDate": "29 Oct,2023 06:00 PM",
          "test_date_live": "2023-10-29 18:00:00",
          "test_date_local": "2024-08-30 12:52:50"
        },
        {
          "test_id": 2672,
          "test_duration": "120",
          "test_ts_id": 0,
          "test_dpt_id": "400",
          "test_dept_names": "Agriculture Supervisor",
          "test_et_id": 0,
          "test_number": 0,
          "test_sub_id": "",
          "test_marks": 0,
          "test_negative_marks": 0,
          "test_sub_question": "",
          "test_datetime": "2023-08-23T12:44:07+05:30",
          "test_status": 1,
          "test_admin_id": 0,
          "test_series_id": 0,
          "test_paper_file": "",
          "test_solution_file": "",
          "test_schedule_file": "",
          "test_free_status": true,
          "product_in_stock": 0,
          "test_sectional_status": 0,
          "test_live_status": 0,
          "test_end_date": "2023-11-12T18:00:00+05:30",
          "test_announce_date": "2023-11-12T18:00:00+05:30",
          "test_start_date": null,
          "ot_test_solution_datetime": null,
          "test_subject_stope_time": "",
          "test_syllabus_info": "",
          "test_comming_date": "",
          "test_title": "Agriculture Supervisor Test - 18 ( Full Schedule  )",
          "test_total_marks": 300,
          "test_total_question": 100,
          "product_publish_date": null,
          "test_section_type": 1,
          "test_type": 0,
          "test_scheduled_details": null,
          "test_display_date": "yes",
          "user_section_test": null,
          "reward_point": [],
          "user_test_perform_status": "Live",
          "user_test_status": "Pending",
          "formattedDate": "12 Nov,2023 06:00 PM",
          "test_date_live": "2023-11-12 18:00:00",
          "test_date_local": "2024-08-30 12:52:50"
        },
        {
          "test_id": 2673,
          "test_duration": "120",
          "test_ts_id": 0,
          "test_dpt_id": "400",
          "test_dept_names": "Agriculture Supervisor",
          "test_et_id": 0,
          "test_number": 0,
          "test_sub_id": "",
          "test_marks": 0,
          "test_negative_marks": 0,
          "test_sub_question": "",
          "test_datetime": "2023-08-23T12:58:35+05:30",
          "test_status": 1,
          "test_admin_id": 0,
          "test_series_id": 0,
          "test_paper_file": "",
          "test_solution_file": "",
          "test_schedule_file": "",
          "test_free_status": true,
          "product_in_stock": 0,
          "test_sectional_status": 0,
          "test_live_status": 0,
          "test_end_date": "2023-11-26T18:00:00+05:30",
          "test_announce_date": "2023-11-26T18:00:00+05:30",
          "test_start_date": null,
          "ot_test_solution_datetime": null,
          "test_subject_stope_time": "",
          "test_syllabus_info": "",
          "test_comming_date": "",
          "test_title": "Agriculture Supervisor Test - 19 ( Full Schedule )",
          "test_total_marks": 300,
          "test_total_question": 100,
          "product_publish_date": null,
          "test_section_type": 1,
          "test_type": 0,
          "test_scheduled_details": null,
          "test_display_date": "yes",
          "user_section_test": null,
          "reward_point": [],
          "user_test_perform_status": "Live",
          "user_test_status": "Pending",
          "formattedDate": "26 Nov,2023 06:00 PM",
          "test_date_live": "2023-11-26 18:00:00",
          "test_date_local": "2024-08-30 12:52:50"
        },
        {
          "test_id": 2674,
          "test_duration": "120",
          "test_ts_id": 0,
          "test_dpt_id": "400",
          "test_dept_names": "Agriculture Supervisor",
          "test_et_id": 0,
          "test_number": 0,
          "test_sub_id": "",
          "test_marks": 0,
          "test_negative_marks": 0,
          "test_sub_question": "",
          "test_datetime": "2023-08-23T01:01:19+05:30",
          "test_status": 1,
          "test_admin_id": 0,
          "test_series_id": 0,
          "test_paper_file": "",
          "test_solution_file": "",
          "test_schedule_file": "",
          "test_free_status": true,
          "product_in_stock": 0,
          "test_sectional_status": 0,
          "test_live_status": 0,
          "test_end_date": "2023-12-10T18:00:00+05:30",
          "test_announce_date": "2023-12-10T18:00:00+05:30",
          "test_start_date": null,
          "ot_test_solution_datetime": null,
          "test_subject_stope_time": "",
          "test_syllabus_info": "",
          "test_comming_date": "",
          "test_title": "Agriculture Supervisor Test - 20 ( Full Schedule )",
          "test_total_marks": 300,
          "test_total_question": 100,
          "product_publish_date": null,
          "test_section_type": 1,
          "test_type": 0,
          "test_scheduled_details": null,
          "test_display_date": "yes",
          "user_section_test": null,
          "reward_point": [],
          "user_test_perform_status": "Live",
          "user_test_status": "Pending",
          "formattedDate": "10 Dec,2023 06:00 PM",
          "test_date_live": "2023-12-10 18:00:00",
          "test_date_local": "2024-08-30 12:52:50"
        },
        {
          "test_id": 2899,
          "test_duration": "120",
          "test_ts_id": 0,
          "test_dpt_id": "400",
          "test_dept_names": "Agriculture Supervisor",
          "test_et_id": 0,
          "test_number": 0,
          "test_sub_id": "",
          "test_marks": 0,
          "test_negative_marks": 0,
          "test_sub_question": "",
          "test_datetime": "2023-12-21T11:33:01+05:30",
          "test_status": 1,
          "test_admin_id": 0,
          "test_series_id": 0,
          "test_paper_file": "",
          "test_solution_file": "",
          "test_schedule_file": "",
          "test_free_status": true,
          "product_in_stock": 0,
          "test_sectional_status": 0,
          "test_live_status": 0,
          "test_end_date": "2023-12-24T18:00:00+05:30",
          "test_announce_date": "2023-12-24T18:00:00+05:30",
          "test_start_date": null,
          "ot_test_solution_datetime": null,
          "test_subject_stope_time": "",
          "test_syllabus_info": "",
          "test_comming_date": "",
          "test_title": "Agriculture Supervisor Test - 21 (Full Schedule)",
          "test_total_marks": 300,
          "test_total_question": 100,
          "product_publish_date": null,
          "test_section_type": 1,
          "test_type": 0,
          "test_scheduled_details": null,
          "test_display_date": "yes",
          "user_section_test": null,
          "reward_point": [],
          "user_test_perform_status": "Live",
          "user_test_status": "Pending",
          "formattedDate": "24 Dec,2023 06:00 PM",
          "test_date_live": "2023-12-24 18:00:00",
          "test_date_local": "2024-08-30 12:52:50"
        },
        {
          "test_id": 2900,
          "test_duration": "120",
          "test_ts_id": 0,
          "test_dpt_id": "400",
          "test_dept_names": "Agriculture Supervisor",
          "test_et_id": 0,
          "test_number": 0,
          "test_sub_id": "",
          "test_marks": 0,
          "test_negative_marks": 0,
          "test_sub_question": "",
          "test_datetime": "2023-12-21T01:10:12+05:30",
          "test_status": 1,
          "test_admin_id": 0,
          "test_series_id": 0,
          "test_paper_file": "",
          "test_solution_file": "",
          "test_schedule_file": "",
          "test_free_status": true,
          "product_in_stock": 0,
          "test_sectional_status": 0,
          "test_live_status": 0,
          "test_end_date": "2024-01-07T18:00:00+05:30",
          "test_announce_date": "2023-12-31T18:00:00+05:30",
          "test_start_date": null,
          "ot_test_solution_datetime": null,
          "test_subject_stope_time": "",
          "test_syllabus_info": "",
          "test_comming_date": "",
          "test_title": "Agriculture Supervisor Test - 22 (Full Schedule)",
          "test_total_marks": 300,
          "test_total_question": 100,
          "product_publish_date": null,
          "test_section_type": 1,
          "test_type": 0,
          "test_scheduled_details": null,
          "test_display_date": "yes",
          "user_section_test": null,
          "reward_point": [],
          "user_test_perform_status": "Live",
          "user_test_status": "Pending",
          "formattedDate": "7 Jan,2024 06:00 PM",
          "test_date_live": "2024-01-07 18:00:00",
          "test_date_local": "2024-08-30 12:52:50"
        },
        {
          "test_id": 2901,
          "test_duration": "120",
          "test_ts_id": 0,
          "test_dpt_id": "400",
          "test_dept_names": "Agriculture Supervisor",
          "test_et_id": 0,
          "test_number": 0,
          "test_sub_id": "",
          "test_marks": 0,
          "test_negative_marks": 0,
          "test_sub_question": "",
          "test_datetime": "2023-12-21T01:12:12+05:30",
          "test_status": 1,
          "test_admin_id": 0,
          "test_series_id": 0,
          "test_paper_file": "",
          "test_solution_file": "",
          "test_schedule_file": "",
          "test_free_status": true,
          "product_in_stock": 0,
          "test_sectional_status": 0,
          "test_live_status": 0,
          "test_end_date": "2024-01-15T18:00:00+05:30",
          "test_announce_date": "2024-01-15T18:00:00+05:30",
          "test_start_date": null,
          "ot_test_solution_datetime": null,
          "test_subject_stope_time": "",
          "test_syllabus_info": "",
          "test_comming_date": "",
          "test_title": "Agriculture Supervisor Test - 23 (Full Schedule)",
          "test_total_marks": 300,
          "test_total_question": 100,
          "product_publish_date": null,
          "test_section_type": 1,
          "test_type": 0,
          "test_scheduled_details": null,
          "test_display_date": "yes",
          "user_section_test": null,
          "reward_point": [],
          "user_test_perform_status": "Live",
          "user_test_status": "Pending",
          "formattedDate": "15 Jan,2024 06:00 PM",
          "test_date_live": "2024-01-15 18:00:00",
          "test_date_local": "2024-08-30 12:52:50"
        },
        {
          "test_id": 2902,
          "test_duration": "120",
          "test_ts_id": 0,
          "test_dpt_id": "400",
          "test_dept_names": "Agriculture Supervisor",
          "test_et_id": 0,
          "test_number": 0,
          "test_sub_id": "",
          "test_marks": 0,
          "test_negative_marks": 0,
          "test_sub_question": "",
          "test_datetime": "2023-12-21T01:13:53+05:30",
          "test_status": 1,
          "test_admin_id": 0,
          "test_series_id": 0,
          "test_paper_file": "",
          "test_solution_file": "",
          "test_schedule_file": "",
          "test_free_status": true,
          "product_in_stock": 0,
          "test_sectional_status": 0,
          "test_live_status": 0,
          "test_end_date": "2024-01-21T18:00:00+05:30",
          "test_announce_date": "2024-01-21T18:00:00+05:30",
          "test_start_date": null,
          "ot_test_solution_datetime": null,
          "test_subject_stope_time": "",
          "test_syllabus_info": "",
          "test_comming_date": "",
          "test_title": "Agriculture Supervisor Test - 24 (Full Schedule)",
          "test_total_marks": 300,
          "test_total_question": 100,
          "product_publish_date": null,
          "test_section_type": 1,
          "test_type": 0,
          "test_scheduled_details": null,
          "test_display_date": "yes",
          "user_section_test": null,
          "reward_point": [],
          "user_test_perform_status": "Live",
          "user_test_status": "Pending",
          "formattedDate": "21 Jan,2024 06:00 PM",
          "test_date_live": "2024-01-21 18:00:00",
          "test_date_local": "2024-08-30 12:52:50"
        },
        {
          "test_id": 2903,
          "test_duration": "120",
          "test_ts_id": 0,
          "test_dpt_id": "400",
          "test_dept_names": "Agriculture Supervisor",
          "test_et_id": 0,
          "test_number": 0,
          "test_sub_id": "",
          "test_marks": 0,
          "test_negative_marks": 0,
          "test_sub_question": "",
          "test_datetime": "2023-12-21T01:15:38+05:30",
          "test_status": 1,
          "test_admin_id": 0,
          "test_series_id": 0,
          "test_paper_file": "",
          "test_solution_file": "",
          "test_schedule_file": "",
          "test_free_status": true,
          "product_in_stock": 0,
          "test_sectional_status": 0,
          "test_live_status": 0,
          "test_end_date": "2024-01-28T18:00:00+05:30",
          "test_announce_date": "2024-01-28T18:00:00+05:30",
          "test_start_date": null,
          "ot_test_solution_datetime": null,
          "test_subject_stope_time": "",
          "test_syllabus_info": "",
          "test_comming_date": "",
          "test_title": "Agriculture Supervisor Test - 25 (Full Schedule)",
          "test_total_marks": 300,
          "test_total_question": 100,
          "product_publish_date": null,
          "test_section_type": 1,
          "test_type": 0,
          "test_scheduled_details": null,
          "test_display_date": "yes",
          "user_section_test": null,
          "reward_point": [],
          "user_test_perform_status": "Live",
          "user_test_status": "Pending",
          "formattedDate": "28 Jan,2024 06:00 PM",
          "test_date_live": "2024-01-28 18:00:00",
          "test_date_local": "2024-08-30 12:52:50"
        }
      ],
      "results_faq": [
        {
          "faq_id": 15,
          "faq_question": "How i can purchase the Book",
          "faq_answer": "Go to the book Section and select book then click on purchase button.",
          "faq_type": 0,
          "faq_publish_Date": "2020-07-18T15:53:19+05:30"
        }
      ]
    }
  }

//   console.log("results_mocexam" , testData.params.results_mocexam)
  const newTestData = testData.params.results_mocexam;


  return (
  <>
   <section className="main-sec">
        <div className="row">
          <div className="col-lg-12">
            <div className="dashboard-title">
              <h4 className="dash-head">
                <i className="fas fa-book me-2" />
                Test List
              </h4>
              <Link to="#" className="blu-btn">
                <i className="far fa-plus me-2" />
                Add Test
              </Link>
            </div>
            <div className="custom-bredcump">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/testcategorylist">Test Category Management</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Test List
                  </li>
                </ol>
              </nav>
            </div>
          </div>
          <div className="col-lg-12">
            <div className="cards bus-list">
              <div className="bus-filter">
                <div className="row justify-content-end">
                  <div className="col-lg-7">
                    <h4 className="table-head">Test List ({})</h4>
                  </div>
                  <div className="col-lg-5">
                    <form action="">
                      <div className="row justify-content-end gx-2 align-items-center">
                        <div className="col-lg-6">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Search Category..."
                            // onChange={(e)=>setSearchCategoryName(e.target.value)}
                          />
                        </div>
                        <div className="col-lg-2">
                          <button type="submit" className="blu-btn w-100">
                            Search
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div className="table table-responsive custom-table">
                <table className="table table-borderless">
                  <thead>
                    <tr>
                      <th>SR NO.</th>
                      <th>Image</th>
                      <th>TEST NAME</th>
                      {/* <th>SEQUENCE</th> */}
                      <th>STATUS</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {newTestData.map((arr, i) => (
                      <tr key={arr.test_id}>
                        <th>{i + 1}</th>
                        <th>
                          <img
                            src={arr?.test_schedule_file}
                            className="img-40"
                            alt=""
                            // onError={(e)=>(e.target.src=categoryImg)}
                          />
                        </th>
                        <td>
                          <Link to={`/testlist/${arr?.test_id}`}>
                            {arr?.test_title}
                          </Link>
                        </td>
                        {/* <td>
                          <div className="d-flex table-box">
                            <input
                              type="number"
                              className="form-control"
                              defaultValue={arr?.exam_cat_order}
                              onChange={(e) =>
                                setUpdateSequence(e.target.value)
                              }
                              min={0}
                            />
                            <button
                              className="btn btn-success ms-2"
                            //   onClick={() => handleUpdate(arr._id)}
                            >
                              Update
                            </button>
                          </div>
                        </td>  */}
                        <td>
                          <div className="form-check form-switch">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              defaultChecked={arr?.test_status}
                              // onClick={() => handleStatus(arr._id)}
                            />
                          </div>
                        </td>

                        <td>
                          <Link
                            to="#"
                            // to={`/test_category_edit/${arr._id}`}
                            className="mx-2"
                          >
                            <img src={edit} alt="" />
                          </Link>
                          <Link
                            to="#"
                            // onClick={() => handleDelete(arr?._id)}
                            className="mx-2"
                          >
                            <img src={trash} alt="" />
                          </Link>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                {/* { showPagination && <div className="custom-pagination">
                  <ul className="pagination">
                    <li className="page-item">
                      <button
                        className="page-link"
                        to=""
                        aria-label="Previous"
                        disabled={pageNumber === 1 && true}
                        onClick={() => setPageNumber((prev) => prev - 1)}
                      >
                        <span aria-hidden="true">«</span>
                      </button>
                    </li>
                    {pageNumber >= 2 && (
                      <li className="page-item">
                        <Link className="page-link" to="">
                          {pageNumber - 1}
                        </Link>
                      </li>
                    )}
                    <li className="page-item">
                      <Link className="page-link active" to="">
                        {pageNumber}
                      </Link>
                    </li>
                    <li className="page-item">
                      <Link
                        className="page-link"
                        to=""
                        onClick={() => setPageNumber((prev) => prev + 1)}
                      >
                        {pageNumber + 1}
                      </Link>
                    </li>
                    <li className="page-item">
                      <Link
                        className="page-link"
                        to=""
                        onClick={() => setPageNumber((prev) => prev + 2)}
                      >
                        {pageNumber + 2}
                      </Link>
                    </li>
                    <li className="page-item">
                      <Link
                        className="page-link"
                        to=""
                        aria-label="Next"
                        onClick={() => setPageNumber((prev) => prev + 1)}
                      >
                        <span aria-hidden="true">»</span>
                      </Link>
                    </li>
                  </ul>
                </div>} */}
              </div>
            </div>
          </div>
        </div>
      </section>
  </>
  );
};

export default TestList;
