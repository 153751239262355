import React, { useState } from "react";

import RolePermissionContext from "./rolePermissionContext";
 
function RolePermissionProvider({ children }) {
 
  const  [userPermission,setUserPermission]=useState([])
  return (
    <RolePermissionContext.Provider value={{ userPermission,setUserPermission }}>
      {children}
    </RolePermissionContext.Provider>
  );
}
export default RolePermissionProvider;
