import React, { useEffect, useState } from "react";
// import Header from "../Layout/Header";
// import Sidebar from "../Layout/Sidebar";
import { Link } from "react-router-dom";
import axios from "axios";
import BASE_URL from "../config/baseUrl";

export default function Permission_list() {
  const [data, setData] = useState([]);
  useEffect(() => {
    const apiCall = async () => {
      let response = await axios(`${BASE_URL}/api/permissioncategory/alldata`, {
        method: "GET",
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
// console.log(response?.data?.data);

      setData(response?.data?.data);
    };
    apiCall();
  // useEffect(() => {
  //   const apiCall = async () => {
  //     let response = await axios(`${BASE_URL}/api/permission/alldata`, {
  //       method: "GET",
  //       headers: {
  //         Authorization: localStorage.getItem("token"),
  //       },
  //     });

  //     setData(response?.data?.data);
  //   };
  //   apiCall();
  }, []);
  return (
    <>
      <section className="main-sec">
        <div className="row">
          <div className="col-lg-12">
            <div className="dashboard-title">
              <h4 className="dash-head">
                <i className="fas fa-video me-2" />
                Permission List
              </h4>
            </div>
            <div className="custom-bredcump">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Permission List
                  </li>
                </ol>
              </nav>
            </div>
          </div>
          <div className="col-lg-12">
            <div className="cards bus-list">
              <div className="bus-filter">
                <div className="row">
                  <div className="col-lg-12">
                    <h4 className="table-head">Permission List</h4>
                  </div>
                </div>
              </div>
              <div className="table table-responsive custom-table">
                <table className="table table-borderless">
                  <thead>
                    <tr>
                      <th>SR NO.</th>
                      <th>Category </th>
                      {/* <th>Permission Name</th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {data?.map((arr, i) => {
                      return (
                        <tr key={arr._id}>
                          <td>{++i}</td>
                          <td>{arr?.category }</td>
                          {/* <td>{arr?.permission}</td> */}
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
                {/* <div className="custom-pagination">
                  <ul className="pagination">
                    <li className="page-item">
                      <a className="page-link" href="#" aria-label="Previous">
                        <span aria-hidden="true">«</span>
                      </a>
                    </li>
                    <li className="page-item">
                      <a className="page-link active" href="#">
                        1
                      </a>
                    </li>
                    <li className="page-item">
                      <a className="page-link" href="#">
                        2
                      </a>
                    </li>
                    <li className="page-item">
                      <a className="page-link" href="#">
                        3
                      </a>
                    </li>
                    <li className="page-item">
                      <a className="page-link" href="#" aria-label="Next">
                        <span aria-hidden="true">»</span>
                      </a>
                    </li>
                  </ul>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
