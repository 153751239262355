import axios from "axios";
import React, { useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import BASE_URL from "../config/baseUrl";

const CommentAdd = () => {
  // const {tid} = useParams();
  //  console.log("tid",tid)
  const [values, setValues] = useState({
    comment: "",
    subject_id: "",
    sender_id: "",
    offline_live_course_id: "",
    topic_id: "",
    parent_id: "",
  });
  const navigate = useNavigate();
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        `${BASE_URL}/api/videoComment/savecomment`,
        values
      );
      console.log("response", response);
      // navigate(`/commentlist/${tid}`);
    } catch (error) {
      console.log("comment submitting error", error);
    }
  };

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
  };

  return (
    <>
      <section className="main-sec">
        <div className="row">
          <div className="col-lg-12">
            <div className="dashboard-title">
              <h4 className="dash-head">
                <i className="fas fa-video me-2" />
                Add Comment
              </h4>
            </div>
            <div className="custom-bredcump">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/livevideocourselist">
                      live Video Course Management
                    </Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Add Comment
                  </li>
                </ol>
              </nav>
            </div>
          </div>
          <div className="col-lg-12">
            <div className="cards cstm-form">
              <form action="" onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-lg-4 col-md-6 mb-4">
                    <label htmlFor="" className="form-label">
                      Commet Name
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="comment"
                      onChange={handleOnChange}
                      value={values.comment}
                    />
                  </div>
                  <div className="col-lg-4 col-md-6 mb-4">
                    <label htmlFor="" className="form-label">
                      subject Id
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      name="subject_id"
                      onChange={handleOnChange}
                      value={values.subject_id}
                    />
                  </div>
                  <div className="col-lg-4 col-md-6 mb-4">
                    <label htmlFor="" className="form-label">
                      Sender Id
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="sender_id"
                      onChange={handleOnChange}
                      value={values.sender_id}
                    />
                  </div>
                  <div className="col-lg-4 col-md-6 mb-4">
                    <label htmlFor="" className="form-label">
                      Offline Live Course Id
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      name="offline_live_course_id"
                      onChange={handleOnChange}
                      value={values.offline_live_course_id}
                    />
                  </div>
                  <div className="col-lg-4 col-md-6 mb-4">
                    <label htmlFor="" className="form-label">
                      Topic Id
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      name="topic_id"
                      onChange={handleOnChange}
                      value={values.topic_id}
                    />
                  </div>
                  <div className="col-lg-4 col-md-6 mb-4">
                    <label htmlFor="" className="form-label">
                      Parent Id
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      name="parent_id"
                      onChange={handleOnChange}
                      value={values.parent_id}
                    />
                  </div>
                  <div className="col-lg-12 mb-4 text-center">
                    <button className="thm-btn" type="submit">
                      Submit
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default CommentAdd;
