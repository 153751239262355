import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import BASE_URL from "../config/baseUrl";

const SubPermissionAdd = () => {
  let navigate = useNavigate();
  let [formval, setFormval] = useState({
    name: "",
    route:"",
  });
const [catData,setCatData] = useState([]);
  useEffect(()=>{
   fetchCatData();
  },[])
// fetch cat api
  const fetchCatData = async() => {
      try {
        const response = await axios.get(`${BASE_URL}/api/permissioncategory/permission-category-get-all`,{
          headers:{
            Authorization:localStorage.getItem("token")
          }
        })
        console.log("response add",response)
        setCatData(response?.data?.data);
      } catch (error) {
        console.log("select category error",error)
      }
  }
// handle post api
  const handlepostPermission = async (e) => {
    e.preventDefault();
    const response = await axios.post(
      `${BASE_URL}/api/PermissionSubCategory/permission-sub-category-create`,formval,
      {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }
    );
    console.log("123",response?.data)
    // if (!response?.data?.status) {
    //   alert("some error accoured");
    //   return;
    // }
    navigate("/subpermissioncatlist");
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormval({ ...formval, [name]: value });
  };
  return (
    <>
      <section className="main-sec">
        <div className="row">
          <div className="col-lg-12">
            <div className="dashboard-title">
              <h4 className="dash-head">
                <i className="fa-solid fa-book  me-2" />
                Add Permission Category
              </h4>
            </div>
            <div className="custom-bredcump">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/permissioncatlist">Test</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Add Permission Category
                  </li>
                </ol>
              </nav>
            </div>
          </div>
          <div className="col-lg-12">
            <div className="cards cstm-form">
            <form onSubmit={handlepostPermission}>
                <div className="row">
                <div className="col-lg-4 col-md-6 mb-4">
                    <label htmlFor="" className="form-label">
                      Category
                    </label>
                    <select
                      name="cat_id"
                      onChange={handleChange}
                      className="form-select"
                    >
                      <option defaultValue >Select Category</option>
                      {catData?.map((arr) => (
                        <option key={arr?._id} value={arr._id}>
                          {arr?.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="col-lg-4 col-md-6 mb-4">
                    <label htmlFor="" className="form-label">
                      Name
                    </label>
                    <input
                      type="text"
                      name="name"
                      className="form-control"
                      placeholder="Enter Permission Name"
                      value={formval.name}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col-lg-4 col-md-6 mb-4">
                    <label htmlFor="" className="form-label">
                      Route
                    </label>
                    <input
                      type="text"
                      name="route"
                      className="form-control"
                      placeholder="Enter Route Name"
                      value={formval.route}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="col-lg-12 mb-4 text-center">
                  <button className="thm-btn" type="submit">
                    Submit Permission
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default SubPermissionAdd;
