import React, { useState, useEffect } from "react";
// import Header from "../Layout/Header";
// import Sidebar from "../Layout/Sidebar";
import { Link, useNavigate,useParams } from "react-router-dom";
import BASE_URL from "../config/baseUrl";
import axios from "axios";
import MyCkeditor from "../ckeditor/MyCKeditor";
import Tinymce from "../ckeditor/Tinymce";
export default function Ras_Package_Edit() {
  let navigate = useNavigate();
  let { id } = useParams();
  let [formval, setFormval] = useState({
    title: "",
    actual_price: "",
    discount_price: "",
    description: "",
    sequence: "",
    no_of_test: "",
    sub_pack_status:"",
    sub_pack_order: "",
    total_pdf: "",
    photo: "",
    validity:""
  });

  useEffect(() => {
    let apicall = async () => {
      let response = await axios(`${BASE_URL}/api/raspackage/details/${id}`, {
        method: "GET",
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      console.log(response.data) 
      setFormval(response?.data?.data);
    };
    apicall();


  }, [])


  let [img, setImg] = useState("");
  const handelOnChange = (e) => {
   if(e.target.name === 'photo'){
      const reader = new FileReader();
      reader.onload = () => {
        setImg(reader.result);
      };
      reader.readAsDataURL(e?.target?.files[0]);
      setFormval((currVal) => ({ ...currVal, photo: e.target.files[0] }));
    }else {
      setFormval((currVal) => ({
        ...currVal,
        [e.target.name]: e.target.value,
      }));
    }
  };

  const handelOnSubmit = (e) => {
    e.preventDefault();
    
    const postData = new FormData();
    
    postData.append("photo", formval.photo);
    postData.append("title", formval.title);
    postData.append("actual_price", formval.actual_price);
    postData.append("discount_price", formval.discount_price);
    postData.append("no_of_test", formval.no_of_test);
    postData.append("sub_pack_order", formval.sub_pack_order);
    postData.append("sub_pack_status", formval.sub_pack_status);
    postData.append("total_pdf", formval.total_pdf);
    postData.append("validity", formval.validity);
    postData.append("description", formval.description);

    const apiCall = async () => {
      let response = await axios(`${BASE_URL}/api/raspackage/edit/${id}`, {
        method: "PUT",
        headers: {
          Authorization: localStorage.getItem("token"),
        },
        data: postData,
      });
      console.log(response?.data)
      if (!response?.data?.status) {
        alert("some error accoured");
        return;
      }
      navigate("/ras_package_list");
    };
    apiCall();
  };
  

  // const today = new Date().toISOString().split('T')[0];

  return (
    <>
      {/* <Header /> */}
      {/* <Sidebar /> */}
      <section className="main-sec">
        <div className="row">
          <div className="col-lg-12">
            <div className="dashboard-title">
              <h4 className="dash-head">
                <i className="fa-solid fa-book  me-2" />
                Edit Ras Package
              </h4>
            </div>
            <div className="custom-bredcump">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/Ras_Package_list">Ras Package</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Edit Ras Package
                  </li>
                </ol>
              </nav>
            </div>
          </div>
          <div className="col-lg-12">
            <div className="cards cstm-form">
              <form onSubmit={handelOnSubmit}>
                <div className="row">
                  <div className="mb-3 col-lg-3">
                    <label className="form-label">Title</label>
                    <input
                      type="text"
                      className="form-control"
                      name="title"
                      placeholder="Title"
                      onChange={handelOnChange}
                      value={formval.title}
                    />
                  </div>
                  <div className="mb-3 col-lg-3">
                    <label className="form-label">Actual Price</label>
                    <input
                      type="number"
                      className="form-control"
                      name="actual_price"
                      onChange={handelOnChange}
                      value={formval.actual_price}
                    />
                  </div>
                  <div className="mb-3 col-lg-3">
                    <label className="form-label">Discount Price</label>
                    <input
                      type="number"
                      className="form-control"
                      name="discount_price"
                      onChange={handelOnChange}
                      value={formval.discount_price}
                      
                    />
                  </div>
                  
                   {/* <div className="col-lg-3 mb-4">
                    <label htmlFor="" className="form-label">
                      Sequence
                    </label>
                    <input
                      type="number"
                      name="sequence"
                      onChange={handelOnChange}
                      value={formval.sequence}
                      className="form-control"
                      min={0}
                    />
                  </div> */}

                   <div className="col-lg-3 mb-4">
                    <label htmlFor="" className="form-label">
                      No of Test
                    </label>
                    <input
                      type="number"
                      name="no_of_test"
                      onChange={handelOnChange}
                      value={formval.no_of_test}
                      className="form-control"
                      min={0}
                    />
                  </div>
                   <div className="col-lg-3 mb-4">
                    <label htmlFor="" className="form-label">
                      Sub Pack Status
                    </label>
                    <input
                      type="number"
                      name="sub_pack_status"
                      onChange={handelOnChange}
                      value={formval.sub_pack_status}
                      className="form-control"
                      min={0}
                    />
                  </div>
                   <div className="col-lg-3 mb-4">
                    <label htmlFor="" className="form-label">
                      Sub Pack Order
                    </label>
                    <input
                      type="number"
                      name="sub_pack_order"
                      onChange={handelOnChange}
                      value={formval.sub_pack_order}
                      className="form-control"
                      min={0}
                    />
                  </div>
                   <div className="col-lg-3 mb-4">
                    <label htmlFor="" className="form-label">
                      Total Pdf
                    </label>
                    <input
                      type="number"
                      name="total_pdf"
                      onChange={handelOnChange}
                      value={formval.total_pdf}
                      className="form-control"
                      min={0}
                    />
                  </div>

                  <div className="mb-3 col-lg-3">
                    <label className="form-label">validity</label>
                    <input
                      type="date"
                      className="form-control"
                      name="validity"
                      onChange={handelOnChange}
                      value={formval?.validity?.split("T")[0]}
                      // min={today}
                    />
                  </div>


                  <div className="mb-3 col-lg-3">
                    <label className="form-label">Photo</label>
                    <input
                      type="file"
                      className="form-control"
                      name="photo"
                      onChange={handelOnChange}
                    />
                    <div className="image-box">
                    <div className="image-box-innr">
                         {formval.photo && < img  src={img||`${BASE_URL}/${formval.photo}`}alt={`photo`} />}
                        </div>
                    </div>
                  </div>
      
                  
                  <div className="mb-3 col-lg-12">
                      <Tinymce
                        disabled={false}
                        data={formval?.description}
                        seteditor={setFormval}
                        fieldName={"description"}
                        editorTitle={"Description"}
                      />
                  </div>

                  <div className="mb-3 col-lg-12 text-center">
                    <button type="submit" className="thm-btn w-25">
                      Submit
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

