import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import BASE_URL from "../config/baseUrl";

const SubPermissionEdit = () => {
  const { id } = useParams();
  const [values, setValues] = useState({
    name: "",
    route: "",
    cat_id: "",
  });

  const [catData, setCatData] = useState([]);
  useEffect(() => {
    fetchsinglePermission();
    fetchCatData();
  }, []);
  const navigate = useNavigate();

// fetch category permission api
  const fetchCatData = async () => {
    try {
      const response = await axios.get(
        `${BASE_URL}/api/permissioncategory/permission-category-get-all`,
        {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        }
      );
      // console.log("response add",response)
      setCatData(response?.data?.data);
    } catch (error) {
      console.log("select category error", error);
    }
  };
// fetch permission api
  const fetchsinglePermission = async () => {
    try {
      const response = await axios(
        `${BASE_URL}/api/PermissionSubCategory/permission-sub-category-get/${id}`,
        {
          method: "GET",
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        }
      );
      // console.log("response", response);
      setValues(response.data.data);
    } catch (error) {
      console.error("Error fetching role data:", error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
  };
  // handle edit api
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.put(
        `${BASE_URL}/api/PermissionSubCategory/permission-sub-category-update/${id}`,
        values,
        {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        }
      );
      navigate("/subpermissioncatlist");
    } catch (error) {
      console.log("error in editing role", error);
    }
  };

  return (
    <>
      <section className="main-sec">
        <div className="row">
          <div className="col-lg-12">
            <div className="dashboard-title">
              <h4 className="dash-head">
                <i className="fas fa-video me-2" />
                Edit Sub Category Permission
              </h4>
            </div>
            <div className="custom-bredcump">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link>Permission</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Edit Sub Category Permission
                  </li>
                </ol>
              </nav>
            </div>
          </div>
          <div className="col-lg-12">
            <div className="cards cstm-form">
              <form action="" onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-lg-4 col-md-6 mb-4">
                    <label htmlFor="" className="form-label">
                      Category
                    </label>
                    <select
                      name="cat_id"
                      onChange={handleChange}
                      value={values.cat_id}
                      className="form-select"
                    >
                      <option value="" disabled>
                        Select Category
                      </option>
                      {catData?.map((arr) => (
                        <option key={arr?._id} value={arr._id}>
                          {arr?.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="col-lg-4 col-md-6 mb-4">
                    <label htmlFor="" className="form-label">
                      Name
                    </label>
                    <input
                      type="text"
                      name="name"
                      className="form-control"
                      placeholder="Enter Permission Name"
                      value={values.name}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col-lg-4 col-md-6 mb-4">
                    <label htmlFor="" className="form-label">
                      Route
                    </label>
                    <input
                      type="text"
                      name="route"
                      className="form-control"
                      placeholder="Enter Route Name"
                      value={values.route}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="col-lg-12 mt-5 mb-5 text-center">
                  <button className="thm-btn" type="submit">
                    Submit Sub Category Permission
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default SubPermissionEdit;
