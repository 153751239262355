import React, { useEffect, useState } from "react";
// import Header from "../Layout/Header";
// import Sidebar from "../Layout/Sidebar";
import { Link ,useNavigate} from "react-router-dom";
// import MyCkeditor from "../ckeditor/MyCKeditor";
import Tinymce from "../ckeditor/Tinymce";
import BASE_URL from "../config/baseUrl";
import axios from "axios";
function Question_Add() {
  let navigate=useNavigate();
  const [subject, setSubject] = useState([]);
//   question, option_a, option_b, option_c, option_d, subject_id, answer, solution
  const [formval, setFormval] = useState({
    question: "",
    option_a: "",
    option_b: "",
    option_c: "",
    option_d: "",
    subject_id: "",
    answer: "",
    isActive: true,
    solution: "",
  });

  const handelOnChange = (e) => {
    setFormval((currVal) => ({ ...currVal, [e.target.name]: e.target.value }));
  };

  useEffect(() => {
    const apiCall = async () => {
      let response = await axios(
        `${BASE_URL}/api/subject/alldata`,
        {
          method: "GET",
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        }
      );
       let filterData = response?.data?.data;
       filterData = response?.data?.data.filter((arr) => arr.isActive === true);
      setSubject(filterData);
    };
    apiCall();
  }, []);

  const handelOnSubmit = (e) => {
    e.preventDefault();
    // console.log(formval);
    if (
      !formval.question ||
      !formval.option_a ||
      !formval.option_b ||
      !formval.option_c ||
      !formval.option_d ||
      !formval.answer ||
      !formval.isActive ||
      !formval.solution ||
      !formval.subject_id
    ) {
      alert("please fill all field");
      return;
    }
    // return  
    const apiCall = async () => {
      let response = await axios(`${BASE_URL}/api/question/add`, {
      //     let response = await axios(`${BASE_URL}/api/testpackege/add`, {
        method: "POST",
        headers: {
          Authorization: localStorage.getItem("token"),
        },
        data: formval,
      });
      // console.log(response?.data);
      if (!response?.data?.status) {
        alert("some error accoured");
        return;
      }
      navigate("/question_list");
    // console.log()
    };
    apiCall();
  };
  return (
    <>
      {/* <Header /> */}
      {/* <Sidebar /> */}
      <section className="main-sec">
        <div className="row">
          <div className="col-lg-12">
            <div className="dashboard-title">
              <h4 className="dash-head">
                <i className="fa-solid fa-book  me-2" />
                Add Question
              </h4>
            </div>
            <div className="custom-bredcump">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/test_list">Test</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Add Question
                  </li>
                </ol>
              </nav>
            </div>
          </div>
         
          <div className="col-lg-12">
            <div className="cards cstm-form">
              <form onSubmit={handelOnSubmit}>
                <div className="row">
                  {/* <div className="mb-3 col-lg-4">
                    <label className="form-label">Select Category</label>
                    <select className="form-select">
                      <option>Select Category</option>
                      <option value="">RAJ. LDC</option>
                      <option value="">RAJ. CET</option>
                    </select>
                  </div> */}
                  <div className="mb-3 col-lg-4">
                    <label className="form-label">Select Subject</label>
                    <select name="subject_id" onChange={handelOnChange}  value={formval.subject_id} className="form-select">
                      <option>Select Subject</option>
                      {subject.map((arr) => (
                        <option key={arr?._id} value={arr?._id}>
                          {arr?.title}
                        </option>
                      ))}
                      {/* <option value="">English</option> */}
                    </select>
                  </div>
                  <div className="mb-3 col-lg-4">
                    <label className="form-label">Select Status</label>
                    <select className="form-select" name="isActive"  onChange={handelOnChange}
                      value={formval.isActive}>
                      <option>Select Status</option>
                      <option value={true}>Active</option>
                      <option value={false}>Inactive</option>
                    </select>
                  </div>
                  <div className="mb-3 col-lg-6">
                    <label className="form-label">Question</label>
                    <input
                      type="text"
                      className="form-control"
                      name="question"
                      onChange={handelOnChange}
                      value={formval.question}
                    />
                  </div>
                  <div className="mb-3 col-lg-3">
                    <label className="form-label">Option A</label>
                    <input
                      type="text"
                      className="form-control"
                      name="option_a"
                      onChange={handelOnChange}
                      value={formval.option_a}
                    />
                  </div>
                  <div className="mb-3 col-lg-3">
                    <label className="form-label">Option B</label>
                    <input
                      type="text"
                      className="form-control"
                      name="option_b"
                      onChange={handelOnChange}
                      value={formval.option_b}
                    />
                  </div>
                  <div className="mb-3 col-lg-3">
                    <label className="form-label">Option C</label>
                    <input
                      type="text"
                      className="form-control"
                      name="option_c"
                      onChange={handelOnChange}
                      value={formval.option_c}
                    />
                  </div>
                  <div className="mb-3 col-lg-3">
                    <label className="form-label">Option D</label>
                    <input
                      type="text"
                      className="form-control"
                      name="option_d"
                      onChange={handelOnChange}
                      value={formval.option_d}
                    />
                  </div>
                  <div className="mb-3 col-lg-6">
                    <label className="form-label">Answer</label>
                    <select
                      className="form-select"
                      name="answer"
                      onChange={handelOnChange}
                      
                    >
                      <option>Select Answer</option>
                      <option value="1">Option A</option>
                      <option value="2">Option B</option>
                      <option value="3">Option C</option>
                      <option value="4">Option D</option>
                    </select>
                  </div>
                  <div className="mb-3 col-lg-12">
                    <Tinymce
                      disabled={false}
                        data={formval?.solution}
                        seteditor={setFormval}
                        fieldName={"solution"}
                      editorTitle={"Explanation"}
                    />
                  </div>
                  <div className="mb-3 col-lg-12 text-center">
                    <button type="submit" className="thm-btn w-25">
                      Submit
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Question_Add;
