import React, { useEffect, useState } from "react";
// import Header from "../Layout/Header";
// import Sidebar from "../Layout/Sidebar";
import axios from "axios";
import { Link, useParams, useNavigate } from "react-router-dom";
import BASE_URL from "../config/baseUrl";
import dummy from "../../assets/images/dummy.png";
export default function User_edit() {
  let navigate = useNavigate();
  let { id } = useParams();
  let [formval, setFormval] = useState({
    profileImg: "",
    name: "",
    firstName: "",
    lastName: "",
    dob: "",
    email: "",
    phone: "",
    city: "",
    pin: "",
    status: "",
    gender: "",
    // user_type: "",
  });
  let [img, setImg] = useState("");
  // let [roleList, setRoleList] = useState([]);

  const handelOnChange = (e) => {
    if (e.target.name === "profileImg") {
      const reader = new FileReader();
      reader.onload = () => {
        setImg(reader.result);
      };
      reader.readAsDataURL(e.target.files[0]);
      setFormval((currVal) => ({ ...currVal, profileImg: e.target.files[0] }));
    } else {
      setFormval((currVal) => ({
        ...currVal,
        [e.target.name]: e.target.value,
      }));
    }
  };

  useEffect(() => {
    let apiCall = async () => {
      let response = await axios(`${BASE_URL}/api/user/user_data/${id}`, {
        method: "GET",
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      // console.log(response?.data);

      setFormval((curVal) => ({ ...curVal, ...response?.data?.data }));
    };
    apiCall();
    // let roleList = async () => {
    //   let response = await axios(`${BASE_URL}/api/role/role-get-all`, {
    //     method: "GET",
    //     headers: {
    //       Authorization: localStorage.getItem("token"),
    //     },
    //   });
    //   console.log("4454", response.data);
    //   setRoleList(response?.data?.data);
    // };
    // roleList();
  }, []);

  const handelOnSubmit = (e) => {
    e.preventDefault();
    console.log(formval);
    // return

    const postData = new FormData();
    postData.append("profileImg", formval.profileImg);
    postData.append("firstName", formval.firstName);
    postData.append("lastName", formval.lastName);
    postData.append("dob", formval.dob);
    postData.append("email", formval.email);
    postData.append("phone", formval.phone);
    postData.append("gender", formval.gender);
    postData.append("city", formval.city);
    postData.append("pin", formval.pin);
    postData.append("status", formval.status);
    postData.append("user_type", formval.user_type);
    const apiCall = async () => {
      let response = await axios(`${BASE_URL}/api/user/edit/${id}`, {
        method: "post",
        headers: {
          Authorization: localStorage.getItem("token"),
        },
        data: postData,
      });
      console.log(response.data);
      if (!response?.data?.status) {
        return alert(response?.data?.message);
      }
      navigate("/user_list");
    };
    apiCall();
  };
  const today = new Date().toISOString().split("T")[0];

  return (
    <>
      <section className="main-sec">
        <div className="row">
          <div className="col-lg-12">
            <div className="dashboard-title">
              <h4 className="dash-head">
                <i className="fas fa-users me-2" />
                Edit User
              </h4>
            </div>
            <div className="custom-bredcump">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Edit user
                  </li>
                </ol>
              </nav>
            </div>
          </div>
          <div className="col-lg-12">
            <div className="cards cstm-form">
              <form action="" onSubmit={handelOnSubmit}>
                <div className="row">
                  <div className="frm-bx mb-4 col-lg-4 mb-3">
                    <label htmlFor="" className="form-label">
                      First Name
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter First Name"
                      name="firstName"
                      value={formval?.firstName || formval?.name}
                      onChange={handelOnChange}
                    />
                  </div>
                  <div className="frm-bx mb-4 col-lg-4 mb-3">
                    <label htmlFor="" className="form-label">
                      Last Name
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Last Name"
                      value={formval?.lastName}
                      name="lastName"
                      onChange={handelOnChange}
                    />
                  </div>
                  <div className="frm-bx mb-4 col-lg-4 mb-3">
                    <label htmlFor="" className="form-label">
                      DOB
                    </label>
                    <input
                      type="date"
                      className="form-control"
                      placeholder=""
                      name="dob"
                      value={formval?.dob?.split("T")[0]}
                      onChange={handelOnChange}
                      max={today}
                    />
                  </div>
                  <div className="frm-bx mb-4 col-lg-4 mb-3">
                    <label htmlFor="" className="form-label">
                      Email Address
                    </label>
                    <input
                      type="email"
                      className="form-control"
                      placeholder="Enter Email Address"
                      name="email"
                      value={formval?.email}
                      onChange={handelOnChange}
                    />
                  </div>
                  <div className="frm-bx mb-4 col-lg-4 mb-3">
                    <label htmlFor="" className="form-label">
                      Phone Number
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      placeholder="Enter Phone Number"
                      name="phone"
                      value={formval?.phone}
                      onChange={handelOnChange}
                      min={0}
                    />
                  </div>
                  <div className="frm-bx mb-4 col-lg-4 mb-3">
                    <label htmlFor="" className="form-label">
                      Gender
                    </label>
                    <select
                      name="gender"
                      value={formval?.gender}
                      onChange={handelOnChange}
                      className="form-select"
                    >
                      <option>Select Gender</option>
                      <option value={"Male"}>Male</option>
                      <option value={"Female"}>Female</option>
                      <option value={"Other"}>Other</option>
                    </select>
                  </div>
                  <div className="frm-bx mb-4 col-lg-4 mb-3">
                    <label htmlFor="" className="form-label">
                      City
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter City"
                      name="city"
                      value={formval?.city}
                      onChange={handelOnChange}
                    />
                  </div>
                  <div className="frm-bx mb-4 col-lg-4 mb-3">
                    <label htmlFor="" className="form-label">
                      PIN
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      placeholder="Enter PIN"
                      name="pin"
                      value={formval?.pin}
                      min={0}
                      onChange={handelOnChange}
                    />
                  </div>
                  <div className="frm-bx mb-4 col-lg-4 mb-3">
                    <label htmlFor="" className="form-label">
                      Status
                    </label>
                    <select
                      name="status"
                      value={formval?.status?.toString()}
                      onChange={handelOnChange}
                      className="form-select"
                    >
                      <option>Select Status</option>
                      <option value={true}>Active</option>
                      <option value={false}>Inactive</option>
                    </select>
                  </div>
                  <div className="frm-bx mb-4 col-lg-4 mb-3">
                    <label htmlFor="" className="form-label">
                      Image
                    </label>
                    <input
                      type="file"
                      className="form-control"
                      name="profileImg"
                      onChange={handelOnChange}
                    />
                    <div className="image-box">
                      <div className="image-box-innr">
                        {/* <img
                          src={
                            `${img}`
                              ? `${img}`
                              : `${BASE_URL}/${formval?.profileImg}`
                          }
                          alt="Preview Image"
                          className="img-50"
                        /> */}
                        <img
                          src={img ? img : `${BASE_URL}/${formval?.profileImg}`}
                          alt=""
                        />
                        <a onClick={() => setImg(dummy)}>
                          <i className="fa fa-times" />
                        </a>
                      </div>
                    </div>
                  </div>
                  {/* <div className="frm-bx mb-4 col-lg-4 mb-3">
                    <label htmlFor="" className="form-label">
                      Assign Role
                    </label>
                    <select
                      value={formval?.user_type}
                      onChange={handelOnChange}
                      name="user_type"
                      className="form-select"
                    >
                      <option>Select Role</option>

                      {roleList?.map((arr) => (
                        <option value={arr?._id} key={arr?._id}>
                          {arr?.name}
                        </option>
                      ))}
                    </select>
                  </div> */}
                  <div className="frm-bx mb-4 col-lg-12 mb-3 text-center">
                    <button className="thm-btn rounded-2" type="submit">
                      Submit
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
