import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import BASE_URL from "../config/baseUrl";
import edit from "../../assets/images/edit.png";
import trash from "../../assets/images/trash.png";
import Swal from "sweetalert2";
const PermissionCatList = () => {
  const [pcatData, setpCatData] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await axios(
        `${BASE_URL}/api/permissioncategory/permission-category-get-all`,
        {
          method: "GET",
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        }
      );
      console.log("1111", response.data);
      setpCatData(response?.data?.data);
    } catch (error) {
      console.log("error in permission category", error);
    }finally{
      setLoading(false)
    }
  };

  //delete role api
  const handleDelete = async (id) => {
    // Show confirmation dialog
    const result = await Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    });

    // If confirmed, proceed with deletion
    if (result.isConfirmed) {
      try {
        // Make the DELETE request
        const delResponse = await axios.delete(
          `${BASE_URL}/api/permissioncategory/permission-category-delete/${id}`,
          {
            headers: {
              Authorization: localStorage.getItem("token"),
            },
          }
        );

        // Update state
        const deletedRole = delResponse.data.data;
        // console.log('delete',deletedRole)
        setpCatData(pcatData.filter((role) => role._id !== deletedRole._id));

        // Show success message
        Swal.fire({
          title: "Deleted!",
          text: "Your file has been deleted.",
          icon: "success",
        });

        // Refresh the data
        fetchData();
      } catch (error) {
        console.error("Error deleting permission category:", error);
        Swal.fire({
          title: "Error!",
          text: "There was a problem deleting the permission category.",
          icon: "error",
        });
      }
    }
  };
  return (
    <>
      <section className="main-sec">
        <div className="row">
          <div className="col-lg-12">
            <div className="dashboard-title">
              <h4 className="dash-head">
                <i className="fas fa-video me-2" />
                Permission List
              </h4>
              <Link to="/permissioncatadd" className="blu-btn">
                <i className="far fa-plus me-2" />
                Add New Permission
              </Link>
            </div>
            <div className="custom-bredcump">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Permission List
                  </li>
                </ol>
              </nav>
            </div>
          </div>
          <div className="col-lg-12">
            <div className="cards bus-list">
              <div className="bus-filter">
                <div className="row">
                  <div className="col-lg-12">
                    <h4 className="table-head">Permission List</h4>
                  </div>
                </div>
              </div>
              <div className="table table-responsive custom-table">
                {
                  loading ? (
                    <div className="text-center my-4">
                    <div className="spinner-border" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                  </div>
                  ):(
                    <table className="table table-borderless">
                    <thead>
                      <tr>
                        <th>SR NO.</th>
                        <th>Category </th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {pcatData?.map((arr, i) => {
                        return (
                          <tr key={arr._id}>
                            <td>{++i}</td>
                            <td>{arr?.name}</td>
                            <td>
                              <Link
                                className="mx-2"
                                 to={`/permissioncatedit/${arr?._id}`}
                              >
                                <img src={edit} alt="edit" />
                              </Link>
                              <Link
                                className="mx-2"
                                to=""
                                onClick={() => handleDelete(arr?._id)}
                              >
                                <img src={trash} alt="delete" />
                              </Link>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                  )
                }
              
                {/* <div className="custom-pagination">
                  <ul className="pagination">
                    <li className="page-item">
                      <a className="page-link" href="#" aria-label="Previous">
                        <span aria-hidden="true">«</span>
                      </a>
                    </li>
                    <li className="page-item">
                      <a className="page-link active" href="#">
                        1
                      </a>
                    </li>
                    <li className="page-item">
                      <a className="page-link" href="#">
                        2
                      </a>
                    </li>
                    <li className="page-item">
                      <a className="page-link" href="#">
                        3
                      </a>
                    </li>
                    <li className="page-item">
                      <a className="page-link" href="#" aria-label="Next">
                        <span aria-hidden="true">»</span>
                      </a>
                    </li>
                  </ul>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default PermissionCatList;
