import React, { useState, useEffect } from "react";
// import Header from "../Layout/Header";
// import Sidebar from "../Layout/Sidebar";
import { Link, useParams } from "react-router-dom";
// import edit from "../../assets/images/edit.png";
// import trash from "../../assets/images/trash.png";
import axios from "axios";
import BASE_URL from "../config/baseUrl";
// import Swal from "sweetalert2";

function Ras_Package_Assign_Test_List() {
  let [data, setData] = useState([]);
  let {id}=useParams();

  // let [updateSequence, setUpdateSequence] = useState();
  // let [searchTestName ,setSearchTestName]=useState("");

  useEffect(() => {
    let apicall = async () => {
      let response = await axios(`${BASE_URL}/api/raspackagetest/alldata/packageid?package_id=${id}`, {
        method: "GET",
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      // console.log(response.data.data);
      setData(response?.data?.data);
    };
    apicall();
  }, []);

  // const handleStatus = (id) => {
  //     let apiCall = async () => {
  //         let response = await axios(`${BASE_URL}/api/test/active/${id}`, {
  //             method: "PUT",
  //             headers: {
  //                 Authorization:  localStorage.getItem("token"),
  //             },
  //         });
  //       //   console.log(response)
  //     };
  //     apiCall();
  // };


  // const handleDelete = (id) => {
  //   let apiCall = async () => {
  //     let response = await axios(`${BASE_URL}/api/test/delete/${id}`, {
  //       method: "POST",
  //       headers: {
  //         Authorization: localStorage.getItem("token"),
  //       },
  //     });
  //     let deletedUser = response?.data?.data;
  //     setData(data.filter((arr) => arr?._id !== deletedUser?._id));
  //   };
  //   Swal.fire({
  //     title: "Are you sure?",
  //     text: "You won't be able to revert this!",
  //     icon: "warning",
  //     showCancelButton: true,
  //     confirmButtonColor: "#3085d6",
  //     cancelButtonColor: "#d33",
  //     confirmButtonText: "Yes, delete it!",
  //   }).then((result) => {
  //     if (result.isConfirmed) {
  //       apiCall();
  //       Swal.fire({
  //         title: "Deleted!",
  //         text: "Test has been deleted.",
  //         icon: "success",
  //       });
  //     }
  //   });
  // };


  // const handleUpdate = async (e) => {
  //   let response = await axios(
  //     `${BASE_URL}/api/test/update_sequence/${e}`,
  //     {
  //       method: "PUT",
  //       headers: {
  //         Authorization: localStorage.getItem("token"),
  //       },
  //       data: { sequence: updateSequence },
  //     }
  //   );
  //   // console.log(response);
  //   setData(response?.data?.data);
  // };


// const searchHandle = async (e) => { 
//   e.preventDefault();
//   let response = await axios(
//     `${BASE_URL}/api/test/alldata?title=${searchTestName}`,
//     {
//       method: "GET",
//       headers: {
//         Authorization: localStorage.getItem("token"),
//       },
//     }
//   );
//   if (!response?.data?.status) {
//     alert(response?.data?.message);
//     return
//   } else if(response?.data?.data?.length == 0){
//       alert("Test Not Find")
//   }else{
//     setData(response?.data?.data);
//   }
// };


  return (
    <>
      {/* <Header /> */}
      {/* <Sidebar /> */}
      <section className="main-sec">
        <div className="row">
          <div className="col-lg-12">
            <div className="dashboard-title">
              <h4 className="dash-head">
                <i className="fa-brands fa-readme  me-2" />
                Ras Package Test List
              </h4>
              {/* <Link className="blu-btn" to="/test_add">
                Add Test
              </Link> */}
            </div>
            <div className="custom-bredcump">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                  Ras Package Test list
                  </li>
                </ol>
              </nav>
            </div>
          </div>
          
          <div className="col-lg-12">
            <div className="cards bus-list">
              <div className="bus-filter">
                <div className="row">
                  <div className="col-lg-12">
                    <h4 className="table-head">Ras Package Test list ({data?.length})</h4>
                  </div>
                  {/* <div className="col-lg-5">
                    <form action="" onSubmit={searchHandle}>
                      <div className="row justify-content-end gx-2 align-items-center">
                        <div className="col-lg-6">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Search Test..."
                            onChange={(e)=>setSearchTestName(e.target.value)}
                          />
                        </div> 
                        <div className="col-lg-2">
                          <button type="submit" className="blu-btn w-100">
                            Search
                          </button>
                        </div>
                      </div>
                    </form>
                  </div> */}
                </div>
              </div>
              <div className="table table-responsive custom-table">
                <table className="table table-borderless">
                  <thead>
                    <tr>
                      <th>SR NO.</th>
                      <th>Title</th>
                      <th>total_marks</th>
                      <th>test_date</th>
                      <th>result_date</th>
                      <th>test_duration</th>
                      <th>test_order</th>
                      <th>status </th>
                      <th>answer_pdf</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data?.map((arr, i) => (
                      <tr key={arr?._id}>
                        <td>{++i}</td>
                        <td>{arr?.title}</td>
                        <td>{arr?.total_marks}</td>
                        <td>{arr?.test_date.split("T")[0]}</td>
                        <td>{arr?.result_date?.split("T")[0]}</td>
                        <td>{arr?.test_duration}</td>
                        <td>{arr?.test_order}</td>
                        <td>
                          <div className="form-check form-switch">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              defaultChecked={arr?.test_status}
                              />
                          </div>
                        </td> 
                              <td>{arr?.answer_pdf_opotion_for_user}</td>
                      </tr>
                    ))}

                  
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Ras_Package_Assign_Test_List;
