import React, { useEffect, useState } from "react";
import { Link  ,useParams} from "react-router-dom";
import Tinymce from "../ckeditor/Tinymce";
import BASE_URL from "../config/baseUrl";
import axios from "axios";
function MegaQuestionView() {

  const [subject, setSubject] = useState([]);
  const {id}=useParams();
  const [formval, setFormval] = useState({ });

  useEffect(() => {
    const apiCall = async () => {
      let response = await axios(
        `${BASE_URL}/api/subject/alldata`,
        {
          method: "GET",
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        }
      );
      setSubject(response?.data?.data);
    };
    apiCall();
    const apiCallfordata = async () => {
      let response = await axios(
        `${BASE_URL}/api/question/view/${id}`,
        {
          method: "GET",
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        }
      );
        // console.log(response.data.data);
      setFormval(response?.data?.data);
    };
    apiCallfordata();
  }, []);

  return (
    <>
      <section className="main-sec">
        <div className="row">
          <div className="col-lg-12">
            <div className="dashboard-title">
              <h4 className="dash-head">
                <i className="fa-solid fa-book  me-2" />
                Details Question
              </h4>
            </div>
            <div className="custom-bredcump">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/megatestlist">Test</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Mega Question Details 
                  </li>
                </ol>
              </nav>
            </div>
          </div>
         
          <div className="col-lg-12">
            <div className="cards cstm-form">
              <form>
                <div className="row">
                  <div className="mb-3 col-lg-4">
                    <label className="form-label">Select Subject</label>
                    <select name="subject_id" disabled  value={formval?.subject_id?._id} className="form-select">
                      <option>No Subject</option>
                      {subject.map((arr) => (
                        <option key={arr?._id} value={arr?._id}>
                          {arr?.sub_name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="mb-3 col-lg-4">
                    <label className="form-label">Select Status</label>
                    <select className="form-select" name="isActive"  
                      value={formval?.isActive} disabled > 
                      <option>Select Status</option>
                      <option value={true}>Active</option>
                      <option value={false}>Inactive</option>
                    </select>
                  </div>
                  <div className="mb-3 col-lg-6">
                    <label className="form-label">Question</label>
                    <input
                      type="text"
                      className="form-control"
                      name="question"
                      disabled
                      defaultValue={formval?.question}
                    />
                  </div>
                  <div className="mb-3 col-lg-3">
                    <label className="form-label">Option A</label>
                    <input
                      type="text"
                      className="form-control"
                      name="option_a"
                      disabled
                      defaultValue={formval?.option_a}
                    />
                  </div>
                  <div className="mb-3 col-lg-3">
                    <label className="form-label">Option B</label>
                    <input
                      type="text"
                      className="form-control"
                      name="option_b"
                      disabled
                      // defaultValue={formval?.tlq_options_hindi?.split("###OPT###")[0]}
                      defaultValue={formval?.option_b}
                    />
                  </div>
                  <div className="mb-3 col-lg-3">
                    <label className="form-label">Option C</label>
                    <input
                      type="text"
                      className="form-control"
                      name="option_c"
                      disabled
                      defaultValue={formval?.option_c}
                    />
                  </div>
                  <div className="mb-3 col-lg-3">
                    <label className="form-label">Option D</label>
                    <input
                      type="text"
                      className="form-control"
                      name="option_d"
                      disabled
                      defaultValue={formval?.option_d}
                    />
                  </div>
                  <div className="mb-3 col-lg-6">
                    <label className="form-label">Answer</label>
                    <select
                      className="form-select"
                      name="answer"
                      disabled
                      value={formval?.answer} 
                    >
                      <option>Select Answer</option>
                      <option value="1">Option A</option>
                      <option value="2">Option B</option>
                      <option value="3">Option C</option>
                      <option value="4">Option D</option>
                    </select>
                  </div>
                  <div className="mb-3 col-lg-12">
                    <Tinymce
                      disabled={true}
                        data={formval?.solution}
                        seteditor={setFormval}
                        fieldName={"solution"}
                      editorTitle={"Explanation"}
                    />
                  </div>
                  <div className="mb-3 col-lg-12 text-center">
                    <Link to="/question_list" className="thm-btn w-25">
                      {"<"} Back
                    </Link>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default MegaQuestionView;
