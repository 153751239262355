import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import edit from "../../assets/images/edit.png";
import trash from "../../assets/images/trash.png";
const TestPackageList = () => {
  const { catid } = useParams();
  console.log("catId", catid);
  //   const [testpackData, setTestPackData] = useState([]);

  //   useEffect(() => {
  //     fetchTestPackage();
  //   }, []);

  //   const fetchTestPackage = async () => {
  //     try {
  //       const response = await axios.post(
  //         "https://qualityeducation.in/app/webapi/package/getPackage",
  //         {user_id:"22541",categoryID:"300"} ,
  //         {
  //             headers:{
  //                 Authorization:localStorage.getItem("token")
  //             }
  //         }
  //       );
  //       console.log("responsetestPackage", response);
  //     } catch (error) {
  //       console.log("fetch test package error", error);
  //     }
  //   };

  const testpackData = {
    status: true,
    message: "package fetch successfully",
    params: {
      blogList: [
        {
          blog_id: 109,
          blog_title: "Update Your App Now",
          blog_image:
            "https://qualityeducation.in/app/uploads/blog_image/15972141193.jpg",
          blog_desc:
            "<p>Dear Aspirant&nbsp;</p><p>First Test Of Schedule will be held on Sunday,16 August,2020&nbsp;</p><p>Be Ready...</p><p>And Update Your App.</p>",
          blog_created: null,
          blog_status: 1,
          blog_slug: "update-your-app-now",
          blog_short_description: "",
          blog_categories: "4",
          blog_meta: "",
          blog_tags: "",
          blog_date: "2020-08-12T12:05:19+05:30",
          blog_meta_desc: "",
          blog_meta_keyword: "",
          blog_category_name: "Information",
        },
      ],
      bannerList: [
        {
          slider_id: 47,
          slider_title: "offer2",
          slider_des: "",
          slider_image:
            "https://qualityeducation.in/app/uploads/slider_image/164836265125-03-2022-banner.jpg",
          slider_status: true,
          slider_order: 0,
          slider_type: 0,
          slider_button_name: "",
          slider_button_url: "",
          slider_redirect_to: 0,
        },
        {
          slider_id: 46,
          slider_title: "offer1",
          slider_des: "",
          slider_image:
            "https://qualityeducation.in/app/uploads/slider_image/1631257259app-banner-2021.jpg",
          slider_status: true,
          slider_order: 0,
          slider_type: 0,
          slider_button_name: "",
          slider_button_url: "",
          slider_redirect_to: 0,
        },
        {
          slider_id: 37,
          slider_title: "RAS Pre",
          slider_des: "",
          slider_image:
            "https://qualityeducation.in/app/uploads/slider_image/1648362688new-banner-for-bann.jpg",
          slider_status: true,
          slider_order: 0,
          slider_type: 0,
          slider_button_name: "",
          slider_button_url: "",
          slider_redirect_to: 0,
        },
      ],
    },
  };
//   console.log("response", testpackData.params.blogList);
  const newpackData = testpackData.params.blogList;
  return (
    <>
      <section className="main-sec">
        <div className="row">
          <div className="col-lg-12">
            <div className="dashboard-title">
              <h4 className="dash-head">
                <i className="fas fa-book me-2" />
                Test Package List
              </h4>
              <Link to="#" className="blu-btn">
                <i className="far fa-plus me-2" />
                Add Package Category
              </Link>
            </div>
            <div className="custom-bredcump">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/testcategorylist">Test Category Management</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Test Package List
                  </li>
                </ol>
              </nav>
            </div>
          </div>
          <div className="col-lg-12">
            <div className="cards bus-list">
              <div className="bus-filter">
                <div className="row justify-content-end">
                  <div className="col-lg-7">
                    <h4 className="table-head">Test Package ({})</h4>
                  </div>
                  <div className="col-lg-5">
                    <form action="">
                      <div className="row justify-content-end gx-2 align-items-center">
                        <div className="col-lg-6">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Search Category..."
                            // onChange={(e)=>setSearchCategoryName(e.target.value)}
                          />
                        </div>
                        <div className="col-lg-2">
                          <button type="submit" className="blu-btn w-100">
                            Search
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div className="table table-responsive custom-table">
                <table className="table table-borderless">
                  <thead>
                    <tr>
                      <th>SR NO.</th>
                      <th>Image</th>
                      <th>TEST PACKAGE NAME</th>
                      {/* <th>SEQUENCE</th> */}
                      <th>STATUS</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {newpackData.map((arr, i) => (
                      <tr key={arr.blog_id}>
                        <th>{i + 1}</th>
                        <th>
                          <img
                            src={arr?.blog_image}
                            className="img-40"
                            alt=""
                            // onError={(e)=>(e.target.src=categoryImg)}
                          />
                        </th>
                        <td>
                          <Link to={`/testlist/${arr?.blog_id}`}>
                            {arr?.blog_category_name}
                          </Link>
                        </td>
                        {/* <td>
                          <div className="d-flex table-box">
                            <input
                              type="number"
                              className="form-control"
                              defaultValue={arr?.exam_cat_order}
                              onChange={(e) =>
                                setUpdateSequence(e.target.value)
                              }
                              min={0}
                            />
                            <button
                              className="btn btn-success ms-2"
                              onClick={() => handleUpdate(arr._id)}
                            >
                              Update
                            </button>
                          </div>
                        </td> */}
                        <td>
                          <div className="form-check form-switch">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              defaultChecked={arr?.blog_status}
                              // onClick={() => handleStatus(arr._id)}
                            />
                          </div>
                        </td>

                        <td>
                          <Link
                            to="#"
                            // to={`/test_category_edit/${arr._id}`}
                            className="mx-2"
                          >
                            <img src={edit} alt="" />
                          </Link>
                          <Link
                            to="#"
                            // onClick={() => handleDelete(arr?._id)}
                            className="mx-2"
                          >
                            <img src={trash} alt="" />
                          </Link>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                {/* { showPagination && <div className="custom-pagination">
                  <ul className="pagination">
                    <li className="page-item">
                      <button
                        className="page-link"
                        to=""
                        aria-label="Previous"
                        disabled={pageNumber === 1 && true}
                        onClick={() => setPageNumber((prev) => prev - 1)}
                      >
                        <span aria-hidden="true">«</span>
                      </button>
                    </li>
                    {pageNumber >= 2 && (
                      <li className="page-item">
                        <Link className="page-link" to="">
                          {pageNumber - 1}
                        </Link>
                      </li>
                    )}
                    <li className="page-item">
                      <Link className="page-link active" to="">
                        {pageNumber}
                      </Link>
                    </li>
                    <li className="page-item">
                      <Link
                        className="page-link"
                        to=""
                        onClick={() => setPageNumber((prev) => prev + 1)}
                      >
                        {pageNumber + 1}
                      </Link>
                    </li>
                    <li className="page-item">
                      <Link
                        className="page-link"
                        to=""
                        onClick={() => setPageNumber((prev) => prev + 2)}
                      >
                        {pageNumber + 2}
                      </Link>
                    </li>
                    <li className="page-item">
                      <Link
                        className="page-link"
                        to=""
                        aria-label="Next"
                        onClick={() => setPageNumber((prev) => prev + 1)}
                      >
                        <span aria-hidden="true">»</span>
                      </Link>
                    </li>
                  </ul>
                </div>} */}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default TestPackageList;
