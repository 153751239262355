import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import React_Quill_Editor from "../ckeditor/React_Quill_Editor";
import BASE_URL from "../config/baseUrl";
import axios from "axios";

// { announcement_time, announcement_date,schedule_time, schedule_date}

const MegaTestAdd = () => {
  let navigate = useNavigate();
  let [formval, setFormval] = useState({
    title: "",
    schedule_date: "",
    announcement_date: "",
    schedule_time: "",
    announcement_time:"",
    description: "",
    subject: "",
    totalQuestions: "",
    duration: "",
    marks: "",
    negMarks: "",
    isActive: true,
    sequence: "",
    syllabuspdf: "",
    
  });
  const [subList, setSubList] = useState([]);
  let [pdf, setPdf] = useState("");
  let [row, setRow] = useState([]);

  useEffect(() => {
    subjectListData();
  }, []);

  //subject list api
  const subjectListData = async () => {
    try {
      const response = await axios(`${BASE_URL}/api/subject/alldata`, {
        method: "GET",
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      console.log("sublist", response.data.data);
      setSubList(response.data.data);
    } catch (error) {
      console.log("subject list getting error", error);
    }
  };

  const handelOnChange = (e) => {
    if (e.target.name === "syllabuspdf") {
      let file = e?.target?.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = () => {
          setPdf(reader?.result);
        };
        reader.readAsDataURL(e?.target?.files[0]);
        return setFormval((currVal) => ({
          ...currVal,
          syllabuspdf: e.target.files[0],
        }));
      }
    }
    if (e.target.type === "number") {
      if (e.target.value < 0) {
        return;
      }
    }
    setFormval((currVal) => ({ ...currVal, [e.target.name]: e.target.value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
 
    try {
      if (
        !formval.title ||
        !formval.description ||
        !formval.duration ||
        !formval.marks ||
        !formval.negMarks ||
        !formval.schedule_date ||
        !formval.announcement_date ||
        !formval.totalQuestions || 
        !formval.announcement_time || 
        !formval.schedule_time 
        
      ) {
        alert("all filed is required");
        return;
      }
      for (let arr of row){
        if (!arr.subject||!arr.marks ||!arr.negMarks ||!arr.totalQuestions) {
          alert("Subect All  Filed is required");
          return
        }
      }
      const formData = new FormData();
      // formData.append("title", formval.title);
      // formData.append("schedule_date", formval.schedule_date);
      // formData.append("announcement_date", formval.announcement_date);
      // formData.append("course_type", formval.course_type);
      // formData.append("subject", formval.subject);
      // formData.append("description", formval.description);
      // formData.append("totalQuestions", formval.totalQuestions);
      // formData.append("duration", formval.duration);
      // formData.append("marks", formval.marks);
      // formData.append("negMarks", formval.negMarks);
      // formData.append("isActive", formval.isActive);
      // formData.append("sequence", formval.sequence);
      if (formval.syllabuspdf) {
        formData.append('syllabuspdf', "");
      }
    formData.append('formval',  JSON.stringify(formval));
    formData.append('testSubject', JSON.stringify(row));
    console.log("formVal",formval)
    const response = await axios.post(
        // `http://localhost:1100/api/megatest/add`,
        `${BASE_URL}/api/megatest/add`,
        formData,
        {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        },
      );
      console.log("responsemega", response.data);
 
      navigate('/megatestlist')
    } catch (error) {
      console.log("adding mega test error",error)
    }
  };

  const addRow = () => {
    setRow([
      ...row,
      {
        subject: "",
        totalQuestions: "",
        marks: "",
        megMarks: "",
      },
    ]);
  };
  const removeRow = (index) => {
     const newRow = row.filter((row,i)=>i !== index)
     setRow(newRow)
  };
  const handleInputChange = (e,index) => {
    const { name, value } = e.target;
  
    setRow(prevRow => {
      const updatedRow = prevRow.map((item, i) =>
        i === index ? { ...item, [name]: value } : item
      );
      return updatedRow;
    });
  };
  const today = new Date().toISOString().split("T")[0];

  return (
    <>
      <section className="main-sec">
        <div className="row">
          <div className="col-lg-12">
            <div className="dashboard-title">
              <h4 className="dash-head">
                <i className="fa-brands fa-readme  me-2" />
                Add Mega Test
              </h4>
            </div>
            <div className="custom-bredcump">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/megatestlist"> Mega Test List</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Add Mega Test
                  </li>
                </ol>
              </nav>
            </div>
          </div>
          <div className="col-lg-12">
            <div className="cards cstm-form">
              <form onSubmit={handleSubmit}>
                <div className="row">
                  {/* <div className="mb-3 col-lg-4">
                    <label className="form-label">Test Category</label>
                    <select
                      className="form-select"
                      name="testCategory"
                      onChange={handelOnChange}
                      value={formval.testCategory}
                    >
                      <option>Select Test Category</option>
                      {testCategory?.map((arr) => (
                        <option key={arr?._id} value={arr?._id}>
                          {arr?.title}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="mb-3 col-lg-4">
                    <label className="form-label">Test Package</label>
                    <select
                      className="form-select"
                      name="testPackage"
                      onChange={handelOnChange}
                      defaultValue={formval?.testPackage}
                      placeholder="select"
                    >
                      <option>Select Test Package</option>
                      {testPackage?.map((arr) => (
                        <option key={arr?._id} value={arr?._id}>
                          {arr?.title}
                        </option>
                      ))}
                    </select>
                  </div> */}
                  <div className="mb-3 col-lg-4">
                    <label className="form-label">Title</label>
                    <input
                      type="text"
                      className="form-control"
                      name="title"
                      onChange={handelOnChange}
                      value={formval.title}
                    />
                  </div>

                  <div className="mb-3 col-lg-3">
                    <label className="form-label">Schedule Date</label>
                    <input
                      type="date"
                      className="form-control"
                      name="schedule_date"
                      onChange={handelOnChange}
                      value={formval?.schedule_date}
                      min={today}
                    />
                  </div>

                  <div className="mb-3 col-lg-3">
                    <label className="form-label">Schedule Time</label>
                    <input
                      type="time"
                      className="form-control"
                      name="schedule_time"
                      onChange={handelOnChange}
                      value={formval?.schedule_time}
                    />
                  </div>

                  <div className="mb-3 col-lg-3">
                    <label className="form-label">Announcement Date</label>
                    <input
                      type="date"
                      className="form-control"
                      name="announcement_date"
                      onChange={handelOnChange}
                      value={formval?.announcement_date}
                      min={today}
                    />
                  </div>
                 
                  <div className="mb-3 col-lg-3">
                    <label className="form-label">Announcement Time</label>
                    <input
                      type="time"
                      className="form-control"
                      name="announcement_time"
                      onChange={handelOnChange}
                      value={formval?.announcement_time}
                    />
                  </div>
                  <div className="mb-3 col-lg-3">
                    <label className="form-label">Total Time (In Mintus)</label>
                    <input
                      type="number"
                      className="form-control"
                      name="duration"
                      onChange={handelOnChange}
                      value={formval.duration}
                      min={0}
                    />
                  </div>
                  <div className="col-lg-3 mb-3">
                    <label htmlFor="" className="form-label">
                      Test Sequence
                    </label>
                    <input
                      type="number"
                      name="sequence"
                      onChange={handelOnChange}
                      value={formval.sequence}
                      className="form-control"
                      min={0}
                    />
                  </div>

                  <div className="col-lg-3 mb-3">
                    <label htmlFor="" className="form-label">
                      Test Status
                    </label>
                    <select
                      className="form-select"
                      name="isActive"
                      onChange={handelOnChange}
                    >
                      <option defaultValue={""}>Select Status</option>
                      <option value={true}>Active</option>
                      <option value={false}>InActive</option>
                    </select>
                  </div>
                  <div className="col-lg-4 mb-3 ">
                    <label htmlFor="" className="form-label">
                      Syllabus Pdf
                    </label>
                    <input
                      type="file"
                      accept=".pdf"
                      name="syllabuspdf"
                      onChange={handelOnChange}
                      className="form-control"
                    />
                  </div>
                  {/* <div className="mb-3 col-lg-3">
                    <label className="form-label">Course Type</label> <br />
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="course_type"
                        onChange={handelOnChange}
                        checked={formval?.course_type === "paid"}
                        value="paid"
                        id="paid"
                      />
                      <label className="form-check-label" htmlFor="paid">
                        Paid
                      </label>
                    </div>
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="course_type"
                        onChange={handelOnChange}
                        checked={formval?.course_type === "unpaid"}
                        value="unpaid"
                        id="unpaid"
                      />
                      <label className="form-check-label" htmlFor="unpaid">
                        Unpaid
                      </label>
                    </div>
                  </div> */}
                </div>

                {pdf && (
                  <div className="mb-3">
                    <iframe src={pdf} width="180" height="100"></iframe>
                  </div>
                )}

                <div className="row align-items-center">
                  <div className="mb-3 col">
                    <label className="form-label">Select Subject</label>
                    <select
                      className="form-select"
                      name="subject"
                      onChange={handelOnChange}
                      defaultValue={formval.subject}
                    >
                      <option>Select Subject</option>
                      {subList?.map((arr) => (
                        <option key={arr?._id} value={arr?._id}>
                          {arr?.sub_name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="mb-3 col">
                    <label className="form-label">Total Question</label>
                    <input
                      type="number"
                      className="form-control"
                      name="totalQuestions"
                      onChange={handelOnChange}
                      value={formval.totalQuestions}
                      min={0}
                    />
                  </div>
                  <div className="mb-3 col">
                    <label className="form-label">Total Marks</label>
                    <input
                      type="number"
                      className="form-control"
                      name="marks"
                      onChange={handelOnChange}
                      value={formval.marks}
                      min={0}
                    />
                  </div>

                  <div className="mb-3 col">
                    <label className="form-label">Negative Mark</label>
                    <input
                      type="number"
                      className="form-control"
                      name="negMarks"
                      onChange={handelOnChange}
                      value={formval?.negMarks}
                      min={0}
                    />
                  </div>
                  <div className="col">
                    <label></label>
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={addRow}
                    >
                      <i className="fas fa-plus"></i>
                    </button>
                  </div>
                </div>
                {row?.map((row, index) => (
                  <div className="row align-items-center" key={index}>
                    <div className="mb-3 col">
                      <label className="form-label">Select Subject</label>
                      <select
                        className="form-select"
                        name="subject"
                        onChange={(e) => handleInputChange(e, index)}
                        defaultValue={row.subject}
                      >
                        <option>Select Subject</option>
                        {subList?.map((arr) => (
                          <option key={arr?._id} value={arr?._id}>
                            {arr?.sub_name}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="mb-3 col">
                      <label className="form-label">Total Question</label>
                      <input
                        type="number"
                        className="form-control"
                        name="totalQuestions"
                        onChange={(e) => handleInputChange(e, index)}
                        value={row.totalQuestions}
                        min={0}
                      />
                    </div>
                    <div className="mb-3 col">
                      <label className="form-label">Total Marks</label>
                      <input
                        type="number"
                        className="form-control"
                        name="marks"
                        onChange={(e) => handleInputChange(e, index)}
                        value={row.marks}
                        min={0}
                      />
                    </div>

                    <div className="mb-3 col">
                      <label className="form-label">Negative Mark</label>
                      <input
                        type="number"
                        className="form-control"
                        name="negMarks"
                        onChange={(e) => handleInputChange(e, index)}
                        value={row?.negMarks}
                        // min={0}
                      />
                    </div>
                    <div className="col">
                      <label></label>
                      <button
                        type="button"
                        className="btn btn-danger"
                        onClick={() => removeRow(index)}
                      >
                        <i className="fas fa-minus"></i>
                      </button>
                    </div>
                  </div>
                ))}

                <div className="row">
                  <div className="mb-3 col-lg-12">
                    <React_Quill_Editor
                      disabled={false}
                      data={formval?.description}
                      seteditor={setFormval}
                      fieldName={"description"}
                      editorTitle={"Short Description"}
                    />
                    {/* <Tinymce
                      disabled={false}
                      data={formval?.description}
                      seteditor={setFormval}
                      fieldName={"description"}
                      editorTitle={"Short Description"}
                    /> */}
                  </div>
                </div>
                <div className="mb-3 col-lg-12 text-center">
                  <button type="submit" className="thm-btn w-25">
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default MegaTestAdd;
