import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import BASE_URL,{baseUrl} from "../config/baseUrl";
import React_Quill_Editor from "../ckeditor/React_Quill_Editor";
import Swal from "sweetalert2";

const ComboOfferAdd = () => {
  const [values, setValues] = useState({
    title: "",
    description: "",
    price: "",
    offer_price: "",
    status: "",
    sequence: "",
    // video_courses: [],
    // test_series: [],
    // live_video_course: [],
    type:"",
    course_id:""
  });
    // console.log("values", values);
  const [imgFile, setImgFile] = useState(null);
  const [bgcImgFile, setBgcImgFile] = useState(null);
  const [videoData,setVideoData] = useState([]);
  const navigate = useNavigate();
  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === 'video_courses' || name === 'test_series' || name === 'live_video_course') {
        setValues((prevValues) => ({
          ...prevValues,
          [name]: value.split(',')
        }));
      }else{
        setValues({ ...values, [name]: value });
      }
  };
 

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(values);
    try {
      const formData = new FormData();
      formData.append("title", values.title);
      formData.append("description", values.description);
      formData.append("price", values.price);
      formData.append("offer_price", values.offer_price);
      formData.append("status", values.status);
      formData.append("sequence", values.sequence);
      formData.append("video_courses", values.video_courses);
      formData.append("test_series", values.test_series);
      formData.append("type[0]", values.type);
      formData.append("course_id[0]", values.course_id);

      formData.append("front_image", imgFile);
      formData.append("background_image", bgcImgFile);
      
      const response = await axios.post(
        `${baseUrl}/api/combo-course-store`,
         formData,
        {
          headers: {
           "Access-Control-Allow-Origin" : "*",
          "Content-type" : "application/json",
          "Content-Type" : "multipart/form-data",
          },
        }
      );
      console.log("postresponse", response);
      navigate("/comboofferlist");
    } catch (error) {
      console.log("error data posting", error);
    }
  };

  const videoCaourseData = async () => {
    try {
      const resposne = await axios.get(
        `${baseUrl}/api/video-category-get`,
        {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        }
      );
      setVideoData(resposne.data.data)
    } catch (error) {
        console.log("error video cat",error)
    }
  };

  useEffect(()=>{
    videoCaourseData();
  },[])
 



  return (
    <>
      <section className="main-sec">
        <div className="row">
          <div className="col-lg-12">
            <div className="dashboard-title">
              <h4 className="dash-head">
                <i className="fas fa-users me-2" />
                Add Combo Offer
              </h4>
            </div>
            <div className="custom-bredcump">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Add Combo Offer
                  </li>
                </ol>
              </nav>
            </div>
          </div>
          <div className="col-lg-12">
            <div className="cards cstm-form">
              <form action="" onSubmit={handleSubmit}>
                <div className="row">
                  <div className="frm-bx mb-4 col-lg-4 mb-3">
                    <label htmlFor="" className="form-label">
                      Title
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      value={values.title}
                      onChange={handleChange}
                      name="title"
                      placeholder="Title"
                    />
                  </div>
                  {/* <div className="frm-bx mb-4 col-lg-4 mb-3">
                    <label htmlFor="" className="form-label">
                    description
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="description"
                      value={values.description}
                      onChange={handleChange}
                      placeholder="description"
                    />
                  </div> */}
                  <div className="frm-bx mb-4 col-lg-4 mb-3">
                    <label htmlFor="" className="form-label">
                      Price
                    </label>
                    <input
                      type="Number"
                      className="form-control"
                      name="price"
                      value={values.price}
                      onChange={handleChange}
                      placeholder="price"
                    />
                  </div>
                  <div className="frm-bx mb-4 col-lg-4 mb-3">
                    <label htmlFor="" className="form-label">
                      Offer Price
                    </label>
                    <input
                      type="Number"
                      className="form-control"
                      name="offer_price"
                      value={values.offer_price}
                      onChange={handleChange}
                      placeholder="offer Price"
                    />
                  </div>
                  {/* <div className="frm-bx mb-4 col-lg-4 mb-3">
                    <label htmlFor="" className="form-label">
                      Video Courses
                    </label>
                    <select
                      className="form-select"
                      // multiple
                      name="video_courses"
                      onChange={handleChange}
                    >
                      <option>Select Video Course</option>
                      {videoData?.map((arr) => (
                        <option key={arr?._id} value={arr?._id}>
                          {arr?.category_name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="frm-bx mb-4 col-lg-4 mb-3">
                    <label htmlFor="" className="form-label">
                      Test Series
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="test_series"
                      value={values.test_series}
                      onChange={handleChange}
                      placeholder=" Test Series"
                    />
                  </div>
                  <div className="frm-bx mb-4 col-lg-4 mb-3">
                    <label htmlFor="" className="form-label">
                      Live Video Course
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="live_video_course"
                      value={values.live_video_course}
                      onChange={handleChange}
                      placeholder=" Test Series"
                    />
                  </div> */}

                  <div className="frm-bx mb-4 col-lg-4 mb-3">
                    <label htmlFor="" className="form-label">
                      Sequence
                    </label>
                    <input
                      type="Number"
                      className="form-control"
                      name="sequence"
                      value={values.sequence}
                      onChange={handleChange}
                      placeholder="Sequence"
                    />
                  </div>
                  <div className="frm-bx mb-4 col-lg-4 mb-3">
                    <label htmlFor="" className="form-label">
                      Status
                    </label>
                    <select
                      name="status"
                      value={values.status}
                      onChange={handleChange}   
                      className="form-select"
                    >
                      <option selected="">Select Status</option>
                      <option value="active">active</option>
                      <option value="inactive">inactive</option>
                    </select>
                  </div>
                  
                  <div className="frm-bx mb-4 col-lg-4 mb-3">
                    <label htmlFor="" className="form-label">
                     Background Image
                    </label>
                    <input
                      type="file"
                      onChange={(e) => setImgFile(e.target.files[0])}
                      className="form-control"
                    />
                    <div className="image-box">
                      {imgFile && (
                        <div className="image-box-innr">
                          <img src={URL.createObjectURL(imgFile)} alt="Image" />
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="frm-bx mb-4 col-lg-4 mb-3">
                    <label htmlFor="" className="form-label">
                      Front Image
                    </label>
                    <input
                      type="file"
                      onChange={(e) => setBgcImgFile(e.target.files[0])}
                      className="form-control"
                    />
                    <div className="image-box">
                      {bgcImgFile && (
                        <div className="image-box-innr">
                          <img src={URL.createObjectURL(bgcImgFile)} alt="Image" />
                           
                        </div>
                      )}
                    </div>
                  </div>

                  {/* <div className="frm-bx mb-4 col-lg-4 mb-3">
                    <label htmlFor="" className="form-label">
                    Type
                    </label>
                    <select
                      name="type"
                      value={values.type}
                      onChange={handleChange}
                      className="form-select"
                    >
                      <option selected="" >Select Type</option>
                      <option value="ras" defaultValue="" >ras</option>
                      <option value="test series" defaultValue="">test series</option>
                      <option value="video" defaultValue="">video</option>
                      <option value="live video" defaultValue="">live video</option>
                    </select>
                  </div>

                 { values.type ==="ras" &&  <div className="frm-bx mb-4 col-lg-4 mb-3">
                    <label htmlFor="" className="form-label">
                    course_id
                    </label>
                    <input
                      type="num ber"
                      className="form-control"
                      value={values.course_id}
                      onChange={handleChange}
                      name="course_id"
                      placeholder="course_id"
                    />
                  </div>} */}
                     <div className="row">
                  <div className="mb-3 col-lg-12">
                    <React_Quill_Editor
                      disabled={false}
                      data={values?.description}
                      seteditor={setValues}
                      fieldName={"description"}
                      editorTitle={"Short Description"}
                    />
                  </div>
                </div>
                  <div className="frm-bx mb-4 col-lg-12 mb-3 text-center">
                    <button className="thm-btn rounded-2" type="submit">
                      Add Combo Offer
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ComboOfferAdd;
