import React, { useState, useEffect } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";

import BASE_URL from "../config/baseUrl";
import axios from "axios";
function Role_edit() {
  let navigate = useNavigate();
  let { id } = useParams();
  // console.log(onSuccess);

  let [data, setData] = useState([]);
  let [perCategory, setPerCategory] = useState([]);
  let [rolePermission, setrolePermission] = useState([]);
  const [formval, setFormval] = useState([]);
  useEffect(() => {
    const apiCall = async () => {
      let response = await axios(
        // `http://localhost:8080/api/rolepermission/show/${id}`,
        `${BASE_URL}/api/rolepermission/show/${id}`,
        {
          // let response = await axios(`${BASE_URL}/api/rolepermission/show/${id}`, {
          method: "GET",
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        }
      );
      setrolePermission(response?.data?.data);
      let filter = response?.data?.data?.permissionCategoryId?.map(
        (arr) => arr?._id
      );
      setFormval(filter);
    };
    apiCall();
  }, []);

  useEffect(() => {
    const apiCall = async () => {
      let response = await fetch(`${BASE_URL}/api/permission/alldata`, {
        method: "GET",
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      const result = await response.json();
      // console.log(result);
      setData([...result?.data]);
    };
    apiCall();
  }, []);

  useEffect(() => {
    const apiCall = async () => {
      let response = await fetch(`${BASE_URL}/api/permissioncategory/alldata`, {
        method: "GET",
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      const result = await response.json();
      console.log(result);
      setPerCategory([...result?.data]);
      //   roleId
    };
    apiCall();
  }, []);

  useEffect(() => {
    const apiCall = async () => {
      let response = await fetch(`http://localhost:8080/api/rolepermission/show/661f9701e4b072715da3d36b`, {
        method: "GET",
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      const result = await response.json();
      console.log(result);
      // setPerCategory([...result?.data]);
      //   roleId
    };
    apiCall();
  }, []);

  const handleChange = (id, e) => {
    setFormval((curval) => {
      if (curval.includes(id)) {
        let data = curval.filter((item) => item !== id);
        return [...data];
      }
      return [...curval, id];
    });
  };

  let handleSubmit = (e) => {
    e.preventDefault();
    console.log(formval);
    // return
    // console.log(rolePermission._id);
    // http://localhost:8080/api/rolepermission/edit/661f7f6078228679d347fa4d
    const apiCall = async () => {
      let response = await axios(
        `${BASE_URL}/api/rolepermission/edit/${rolePermission._id}`,
        {
          method: "POST",
          headers: {
            Authorization: localStorage.getItem("token"),
          },
          data: { permissionCategoryId: formval },
        }
      );
      // console.log(response.data);
      // console.log(response.data?.data.permissionId);
      // setrolePermission(response?.data?.data);
      // setFormval(response?.data?.data?.permissionId)
      if (!response?.data?.status) {
        alert(response?.data?.message);
        return;
      }
      navigate("/role_list");
    };
    apiCall();
  };

  return (
    <>
      {/* <Header /> */}
      {/* <Sidebar />/ */}
      <section className="main-sec">
        <div className="row">
          <div className="col-lg-12">
            <div className="dashboard-title">
              <h4 className="dash-head">
                <i className="fas fa-video me-2" />
                Edit Role
              </h4>
            </div>
            <div className="custom-bredcump">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link>Roles</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Edit Role
                  </li>
                </ol>
              </nav>
            </div>
          </div>
          <div className="col-lg-12">
            <div className="cards cstm-form">
              <form action="" onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-lg-6 mb-4">
                    <label htmlFor="" className="form-label">
                      Name
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder=""
                      defaultValue={rolePermission?.roleId?.role}
                      disabled
                    />
                  </div>
                </div>
                <div className="row">
                  {/* {data?.map((arr)=>
                                    <div className='col-lg-3 mb-4'>
                                        <h4 className='dash-head'>{arr?.category?.category}</h4>
                                        <ul className='roles-manage-list'>
                                            <li>
                                                <input type='checkbox' id='role-create' />
                                                <label for="role-create">{arr?.permission}</label>
                                            </li>
                                            <li>
                                                <input type='checkbox' id='role-edit' />
                                                <label for="role-edit">Role Edit</label>
                                            </li>
                                            <li>
                                                <input type='checkbox' id='role-delete' />
                                                <label for="role-delete">Role Delete</label>
                                            </li>
                                        </ul>
                                    </div>)}  */}
                  {perCategory?.map((row, rowIndex) => (
                    rolePermission?.roleId?.role === 'admin manager' && row?.category === "role Mangement" ||
                    <div className="col-lg-3 role-management" key={rowIndex}>
                      <div className="">
                        <h4 className="dash-head">Permission Name</h4>
                      </div>
                      <input type="checkbox" className="form-check-input" id={row._id} checked={formval?.includes(row?._id) && true} onChange={(e) => handleChange(row?._id, e)}
                      />

                      <label for={row._id}>{row?.category}</label>
                    </div>
                  ))}
                </div>
                <div className="col-lg-12 mt-5 mb-5 text-center">
                  <button className="thm-btn" type="submit">
                    Submit Role
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Role_edit;
