import React, { useState, useEffect } from "react";
// import Header from "../Layout/Header";
// import Sidebar from "../Layout/Sidebar";
import BASE_URL from "../config/baseUrl";

import edit from "../../assets/images/edit.png"
import trash from "../../assets/images/trash.png"
import axios from "axios";
import { Link } from "react-router-dom";
import Swal from 'sweetalert2'

export default function Banner_list() {
  let [data, setData] = useState([]);
  // let [categorySequnce, setCategorySequnce] = useState("");
  let [updateSequence ,setUpdateSequence]=useState();
  let [searchCategoryName ,setSearchCategoryName]=useState("");
  const [pageNumber, setPageNumber] = useState(1)
  const [showPagination, setShowPagination] = useState(true);
  let [count, setCount] = useState(0);
  let [showIndex, setShowIndex] = useState(false);

  useEffect(() => {
    let apiCall = async () => {
      let response = await axios(
        `${BASE_URL}/api/banner/alldata?p=${pageNumber}`,
        {
          method: "GET",
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        } 
      );
      console.log(response?.data  );
      
      setData(response?.data?.data?.allBanner);
      setCount(response?.data?.data?.BannerCount);
    };
    apiCall();
  }, [pageNumber]);

  const handleStatus = (id) => {
      let apiCall = async () => {
          // let response = 
          await axios(`${BASE_URL}/api/banner/status/${id}`, {
              method: "PUT",
              headers: {
                  "Authorization": (localStorage.getItem("token"))
              },
          });
          // const result = await res.json();
          // console.log(response)
          // setTableData((currtable) => {
          //     currtable.map((arr) => {
          //         if (arr._id === result.data._id) {
          //             arr.isActive = (result.data.isActive)
          //         }
          //     });
          //     return [...currtable]
          // })
          // video-course-category-list
          // video_course_category_list
      };
      apiCall();
  };

 
  const handleDelete = (id) => {
    let apiCall = async () => {
      let res = await fetch(`${BASE_URL}/api/banner/delete/${id}`, {
        method: "DELETE",
        headers: {
          "Authorization": (localStorage.getItem("token"))
        },
      });
      const result = await res.json();
      console.log(result)
      let deletedUser = result.data
      setData(data .filter((arr) => arr._id !== deletedUser._id))
      setCount((curr)=>curr-1)
    };
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!"
    }).then((result) => {
      if (result.isConfirmed) {
        apiCall();
        Swal.fire({
          title: "Deleted!",
          text: "Your file has been deleted.",
          icon: "success"
        });
      }
    });
  };

  const searchHandle = async (e) => { 
    e.preventDefault();
    setShowPagination(false)
    setShowIndex(true) 	
    if(searchCategoryName === ""){
      setShowPagination(true)
    }
    let response = await axios(
      `${BASE_URL}/api/banner/search?category=${searchCategoryName}`,
      // `${BASE_URL}/api/videocourse/search?title=${searchCourseName}`,
      {
        method: "GET", 
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }
    );
    // console.log(response.data)
    if (!response?.data?.status) {
      alert(response.data.message);
      return
    } else if(response?.data?.data?.length === 0){
        alert("category not find")
    }else  {
      setData(response?.data?.data);
    }
  };

  return (
    <>
      {/* <Header /> */}
      {/* <Sidebar /> */}
      <section className="main-sec">
        <div className="row">
          <div className="col-lg-12">
            <div className="dashboard-title">
              <h4 className="dash-head">
                <i className="fas fa-video me-2" />
                Banner List
              </h4>
              <Link to="/banner_add" className="blu-btn">
                <i className="far fa-plus me-2" />
                Add Banner
              </Link>
            </div>
            <div className="custom-bredcump">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/banner_list">Banner Management</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                  Banner List
                  </li>
                </ol>
              </nav>
            </div>
          </div>
          <div className="col-lg-12">
            <div className="cards bus-list">
              <div className="bus-filter">
                <div className="row justify-content-end">
                  <div className="col-lg-7">
                    <h4 className="table-head">
                      Total Banner ({count})
                    </h4>
                  </div>
                  <div className="col-lg-5">
                    <form action="" onSubmit={searchHandle}>
                      <div className="row justify-content-end gx-2 align-items-center">
                        <div className="col-lg-6">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Search Category..."
                            onChange={(e)=>setSearchCategoryName(e.target.value)}
                          />
                        </div>
                        <div className="col-lg-2">
                          <button type="submit" className="blu-btn ">
                            Search
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div className="table table-responsive custom-table">
                <table className="table table-borderless">
                  <thead>
                    <tr>
                      <th>SR NO.</th>
                      <th>title</th>
                      <th>Image</th>
                      {/* <th>CATEGORY SEQUENCE</th> */}
                      <th>STATUS</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data?.map((arr,i) => (
                      <tr key={arr._id}>
                       <th>{showIndex ?++i:++i + 10 * (pageNumber - 1) }</th>
                        <td>{arr?.title }</td>
                        <th>
                          <img src={`${BASE_URL}/${arr.image}`} className="img-40" alt="" />
                        </th>
                        {/* <td>
                          <div className="d-flex table-box">
                            <input
                              type="number"
                              name="categorySequence"
                              // value={arr?.categorySequence}
                              onChange={(e)=>setUpdateSequence(e.target.value)}
                              className="form-control"
                              defaultValue={arr?.categorySequence}
                              min={0}
                            />
                            <button className="btn btn-success ms-2" 
                            onClick={()=>handleUpdate(arr._id)}
                            
                            >
                              Update
                            </button>
                          </div>
                        </td> */}
                        <td>
                          <div className="form-check form-switch">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              defaultChecked={arr?.status}
                              onClick={()=>handleStatus(arr._id)}
                            />
                          </div>
                        </td>
                        <td>
                          <Link to={`/banner_edit/${arr._id}`}className="mx-2">
                            <img src={edit} alt="" />
                          </Link>
                          {/* <Link to={`/VideoCourseCategory_edit/${arr._id}`} className="mx-2">
                            <img src={edit} alt="" />
                          </Link> */}
                          <Link to="#" className="mx-2" onClick={()=>handleDelete(arr._id)}>
                            <img src={trash} alt="" />
                          </Link>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                {data?.length >10 && showPagination && <div className="custom-pagination">
                  <ul className="pagination">
                    <li className="page-item">
                      <button
                        className="page-link"
                        to=""
                        aria-label="Previous"
                        disabled={pageNumber === 1 && true}
                        onClick={() => setPageNumber((prev) => prev - 1)}
                      >
                        <span aria-hidden="true">«</span>
                      </button>
                    </li>
                    {pageNumber >= 2 && (
                      <li className="page-item">
                        <Link className="page-link" to="">
                          {pageNumber - 1}
                        </Link>
                      </li>
                    )}
                    <li className="page-item">
                      <Link className="page-link active" to="">
                        {pageNumber}
                      </Link>
                    </li>
                    <li className="page-item">
                      <Link
                        className="page-link"
                        to=""
                        onClick={() => setPageNumber((prev) => prev + 1)}
                      >
                        {pageNumber + 1}
                      </Link>
                    </li>
                    <li className="page-item">
                      <Link
                        className="page-link"
                        to=""
                        onClick={() => setPageNumber((prev) => prev + 2)}
                      >
                        {pageNumber + 2}
                      </Link>
                    </li>
                    <li className="page-item">
                      <Link
                        className="page-link"
                        to=""
                        aria-label="Next"
                        onClick={() => setPageNumber((prev) => prev + 1)}
                      >
                        <span aria-hidden="true">»</span>
                      </Link>
                    </li>
                  </ul>
                </div>}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
