import React, { useEffect, useState } from "react";
// import Header from "../Layout/Header";
// import Sidebar from "../Layout/Sidebar";
import { Link, useNavigate, useParams } from "react-router-dom";
import BASE_URL from "../config/baseUrl";
import axios from "axios";
import Tinymce from "../ckeditor/Tinymce";
export default function Banner_edit() {
  const navigate = useNavigate();
  let [img, setImg] = useState("");
  let { id } = useParams();
  let [formval, setFormval] = useState({
    title: "",
    image: "",
    status: true,
    ras_package_id: "",
    link_type: "",
    link: "",
    video_package_id: "",
    live_video_package_id: "",
    test_category_id: "",
    test_package_id: "",
  });
  let [videoPackage, setVideoPackage] = useState([]);
  let [liveVideoPackage, setliveVideoPackage] = useState([]);
  let [rasPackage, setRasPackage] = useState([]);
  let [testSeries, setTestSeries] = useState([]);
  let [testPackage, setTestPackage] = useState([]);

  useEffect(() => {
    const apiCall = async () => {
      let response = await fetch(`${BASE_URL}/api/banner/details/${id}`, {
        method: "GET",
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      const result = await response.json();
      console.log(result);
      setFormval({ ...result.data });
      if (result.data?.link_type === "video Package") {
        apiCallForSelectTab("api/videocourse/allcourses", setVideoPackage);
      }
      if (result.data?.link_type === "live video Package") {
        apiCallForSelectTab(
          "api/livevideocourse/allcourses",
          setliveVideoPackage
        );
      }
      if (result.data?.link_type === "ras Package") {
        apiCallForSelectTab("api/raspackage/alldata", setRasPackage);
      }
      if (result.data?.link_type === "test Series") {
        apiCallForSelectTab("api/testcategory/alldata", setTestSeries);
        apiCallForSelectTab("api/testpackege/allcourses", setTestPackage);
      }
    };
    apiCall();
  }, []);

  let apiCallForSelectTab = async (url, setState) => {
    let response = await axios(`${BASE_URL}/${url}`, {
      method: "GET",
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    });
    // console.log(response.data?.data);
    setState(response?.data?.data);
  };

  const handelOnChange = (e) => {
    if (e.target.name === "image") {
      const reader = new FileReader();
      reader.onload = () => {
        setImg(reader.result);
      };
      reader.readAsDataURL(e.target.files[0]);
      setFormval((currVal) => ({
        ...currVal,
        image: e.target.files[0],
      }));
    } else {
      setFormval((currVal) => ({
        ...currVal,
        [e.target.name]: e.target.value,
      }));
    }
    if (e.target.name === "link_type") {
      if (e.target.value === "video Package") {
        apiCallForSelectTab("api/videocourse/allcourses", setVideoPackage);
      }
      if (e.target.value === "live video Package") {
        apiCallForSelectTab(
          "api/livevideocourse/allcourses",
          setliveVideoPackage
        );
      }
      if (e.target.value === "ras Package") {
        apiCallForSelectTab("api/raspackage/alldata", setRasPackage);
      }
      if (e.target.value === "test Series") {
        apiCallForSelectTab("api/testcategory/alldata", setTestSeries);
      }
      setFormval((currVal) => ({
        ...currVal,
        [e.target.name]: e.target.value,
      }));
    }
    if (e.target.name === "test_category_id") {
      setFormval((currVal) => ({
        ...currVal,
        [e.target.name]: e.target.value,
      }));
      apiCallForSelectTab("api/testpackege/allcourses", setTestPackage);
    }
  };

  const handelOnSubmit = (e) => {
    e.preventDefault();
    // console.log(formval)
    if(formval.link_type === 'video Package' ){
      delete formval.test_category_id
      delete formval.test_package_id
      delete formval.ras_package_id
      delete formval.live_video_package_id
    }
    if(formval.link_type === 'live video Package' ){
      delete formval.video_package_id
      delete formval.test_package_id
      delete formval.ras_package_id
      delete formval.test_category_id
    }
    if(formval.link_type === 'ras Package' ){
      delete formval.video_package_id
      delete formval.test_package_id
      delete formval.live_video_package_id
      delete formval.test_category_id
    }
    if(formval.link_type === 'test Series' ){
      delete formval.video_package_id
      delete formval.live_video_package_id
      delete formval.ras_package_id
    }
    // console.log(formval);
    const postData = new FormData();
    postData.append("title", formval.title);
    postData.append("image", formval?.image);
    postData.append("link_type", formval?.link_type);
    postData.append("status", formval?.status);
    
    if(formval.link_type === 'video Package' ){
    postData.append("video_package_id", formval?.video_package_id);
    }
    if(formval.link_type === 'live video Package' ){
    postData.append("live_video_package_id", formval?.live_video_package_id);
    }
    if(formval.link_type === 'ras Package' ){
     postData.append("ras_package_id", formval?.ras_package_id);
    }
    if(formval.link_type === 'test Series' ){
      postData.append("test_category_id", formval?.test_category_id);
      postData.append("test_package_id", formval?.test_package_id);
    }

    const apiCall = async () => {
      let response = await axios(`${BASE_URL}/api/banner/edit/${id}`, {
        method: "PUT",
        headers: {
          Authorization: localStorage.getItem("token"),
        },
        data: postData,
      });
      console.log(response, "line no 5");
      navigate("/banner_list");
    };
    apiCall();
  };

  return (
    <>
      <section className="main-sec">
        <div className="row">
          <div className="col-lg-12">
            <div className="dashboard-title">
              <h4 className="dash-head">
                <i className="fas fa-video me-2" />
                Add Banner
              </h4>
            </div>
            <div className="custom-bredcump">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/banner_list">Banner Management</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Add Banner
                  </li>
                </ol>
              </nav>
            </div>
          </div>
          <div className="col-lg-12">
            <div className="cards cstm-form">
              <form action="" onSubmit={handelOnSubmit}>
                <div className="row">
                  <div className="col-lg-4 mb-4">
                    <label htmlFor="" className="form-label">
                      title
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="title"
                      onChange={handelOnChange}
                      value={formval.title}
                    />
                  </div>
                  {/* <div className="col-lg-4 mb-4">
                    <label htmlFor="" className="form-label">
                      Category Sequence
                    </label>
                    <input type="number" className="form-control"  name='categorySequence' min={0}
                      onChange={handelOnChange}
                      value={formval.categorySequence}/>
                  </div> */}
                  <div className="col-lg-4 mb-4">
                    <label htmlFor="" className="form-label">
                      Status
                    </label>
                    <select
                      name="status"
                      onChange={handelOnChange}
                      value={formval.status}
                      className="form-select"
                    >
                      <option>Select Status</option>
                      <option value={true}>Active</option>
                      <option value={false}>Inactive</option>
                    </select>
                  </div>
                  <div className="col-lg-4 mb-4">
                    <label htmlFor="" className="form-label">
                      Image
                    </label>
                    <input
                      type="file"
                      className="form-control"
                      defaultValue=""
                      name="image"
                      onChange={handelOnChange}
                    />
                    {img && (
                      <img
                        src={`${img}`}
                        alt="Preview Image"
                        className="img-50"
                      />
                    )}
                  </div>

                  <div className="col-lg-4 mb-4">
                    <label htmlFor="" className="form-label">
                      Select Type
                    </label>
                    <select
                      name="link_type"
                      onChange={handelOnChange}
                      value={formval?.link_type}
                      className="form-select"
                    >
                      <option>Select</option>
                      <option value={"video Package"}>Video Package </option>
                      <option value={"live video Package"}>
                        Live Video Package{" "}
                      </option>
                      <option value={"ras Package"}>Ras Package</option>
                      <option value={"test Series"}>Test Series</option>
                      <option value={"youTube"}>YouTube</option>
                      <option value={"telegram"}>Telegram</option>
                    </select>
                  </div>

                  {formval?.link_type === "video Package" && (
                    <div className="col-lg-4 mb-4">
                      <label htmlFor="" className="form-label">
                        Select video Package
                      </label>
                      <select
                        name="video_package_id"
                        value={formval?.video_package_id}
                        onChange={handelOnChange}
                        className="form-select"
                      >
                        <option>Select</option>
                        {videoPackage?.map((arr) => (
                          <option key={arr?._id} value={arr?._id}>
                            {arr?.title}
                          </option>
                        ))}
                      </select>
                    </div>
                  )}
                  {formval?.link_type === "live video Package" && (
                    <div className="col-lg-4 mb-4">
                      <label htmlFor="" className="form-label">
                        Select Live Video Package
                      </label>
                      <select
                        name="live_video_package_id"
                        value={formval?.live_video_package_id}
                        onChange={handelOnChange}
                        className="form-select"
                      >
                        <option>Select</option>
                        {liveVideoPackage?.allvideoCourseData?.map((arr) => (
                          <option value={arr?._id} key={arr?._id}>
                            {arr?.title}
                          </option>
                        ))}
                      </select>
                    </div>
                  )}
                  {formval?.link_type === "ras Package" && (
                    <div className="col-lg-4 mb-4">
                      <label htmlFor="" className="form-label">
                        Select Ras Package
                      </label>
                      <select
                        name="ras_package_id"
                        value={formval?.ras_package_id}
                        onChange={handelOnChange}
                        className="form-select"
                      >
                        <option>Select</option>
                        {rasPackage?.map((arr) => (
                          <option key={arr?._id} value={arr?._id}>
                            {arr?.title}
                          </option>
                        ))}
                      </select>
                    </div>
                  )}
                  {formval?.link_type === "test Series" && (
                    <div className="col-lg-4 mb-4">
                      <label htmlFor="" className="form-label">
                        Select Test Category
                      </label>
                      <select
                        name="test_category_id"
                        value={formval?.test_category_id}
                        onChange={handelOnChange}
                        className="form-select"
                      >
                        <option>Select</option>
                        {testSeries?.map((arr) => (
                          <option key={arr?._id} value={arr?._id}>
                            {arr?.title}
                          </option>
                        ))}
                      </select>
                    </div>
                  )}
                  {formval?.link_type === "test Series" && (
                    <div className="col-lg-4 mb-4">
                      <label htmlFor="" className="form-label">
                        Select Test Package
                      </label>
                      <select
                        name="test_package_id"
                        value={formval?.test_package_id}
                        onChange={handelOnChange}
                        className="form-select"
                      >
                        <option>Select</option>
                        {testPackage?.map((arr) => (
                          <option key={arr?._id} value={arr?._id}>
                            {arr?.title}
                          </option>
                        ))}
                      </select>
                    </div>
                  )}
                  {formval?.link_type === "telegram" && (
                    <div className="col-lg-4 mb-4">
                      <label htmlFor="" className="form-label">
                        Telegram Link
                      </label>
                      <input
                        type="url"
                        name="telegram"
                        className="form-control"
                      />
                    </div>
                  )}
                  {formval?.link_type === "youTube" && (
                    <div className="col-lg-4 mb-4">
                      <label htmlFor="" className="form-label">
                        YouTube Link
                      </label>
                      <input
                        type="url"
                        name="youTube"
                        className="form-control"
                      />
                    </div>
                  )}

                  <div className="col-lg-12 mb-4 text-center">
                    <button className="thm-btn rounded-2" type="submit">
                      Submit
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
