import React, { useState,useEffect } from "react";
// import Header from "../Layout/Header";
// import Sidebar from "../Layout/Sidebar";
import { Link,useParams } from "react-router-dom";
import BASE_URL from "../config/baseUrl";
import axios from "axios";
// import MyCkeditor from "../Video_Courses/MyCKeditor";
import MyCkeditor from "../ckeditor/MyCKeditor";
import Tinymce from "../ckeditor/Tinymce";
export default function LiveVideoCourse_details() {

  let {id}=useParams();
  let [formval, setFormval] = useState({
    title: "",
    livevideoCategory: "",
    images: [],
    details: "",
    price: "",
    offerPrice: "",
    isActive: "",
  });
  let [categoryData, setCategoryData] = useState([]);
  


  useEffect(() => {
    const apiCall = async () => {
      let response = await axios(
        `${BASE_URL}/api/livevideocoursescategory/allcourses`,
        {
          method: "GET",
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        }
      );
      setCategoryData(response?.data?.data);
    };
    apiCall();
  }, []);

  
  useEffect(() => {
    const apiCall = async () => {
      let response = await axios(`${BASE_URL}/api/livevideocourse/videoCoursDetails/${id}`, {
        method: "GET",
        headers: {
          "Authorization": (localStorage.getItem("token"))
        },
      });
      // console.log(response?.data?.data)
      setFormval({ ...response?.data?.data })
    };
    apiCall();
  }, []);


  return (
    <>
      {/* <Header /> */}
      {/* <Sidebar /> */}
      <section className="main-sec">
        <div className="row">
          <div className="col-lg-12">
            <div className="dashboard-title">
              <h4 className="dash-head">
                <i className="fas fa-video me-2" />
                Live Video Course Details
              </h4>
            </div>
            <div className="custom-bredcump">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/live_video_course_list">live Video Course Management</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Live Video Course Details
                  </li>
                </ol>
              </nav>
            </div>
          </div>
          <div className="col-lg-12">
            <div className="cards cstm-form">
              <form action="">
                <div className="row">
                  <div className="col-lg-4 mb-4">
                    <label htmlFor="" className="form-label">
                      Video Title
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="title"
                      disabled
                      defaultValue={formval.title}
                    />
                  </div>
                  <div className="col-lg-4 mb-4">
                    <label htmlFor="" className="form-label">
                      Video Category
                    </label>
                    <select
                      name="livevideoCategory"
                      className="form-select"
                      value={formval.videoCategory}
                      disabled
                    >
                      <option >Select Category</option>
                        {categoryData?.map((arr) => (
                        <option key={arr?._id} value={arr._id}>
                          {arr?.category}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="col-lg-4 mb-4">
                    <label htmlFor="" className="form-label">
                      Status
                    </label>
                    <select
                    
                    disabled
                    value={formval.isActive}
                      className="form-select"
                      name="isActive"
                    >
                      <option >Select Status</option>
                      <option value={true}>Active</option>
                      <option value={false}>Inactive</option>
                    </select>
                  </div>
                  <div className="col-lg-4 mb-4">
                    <label htmlFor="" className="form-label">
                      Price
                    </label>
                    <input
                      type="number"
                      name="price"
                      placeholder="Price"
                      disabled
                      defaultValue={formval?.price}
                      className="form-control"
                    />
                  </div>
                  <div className="col-lg-4 mb-4">
                    <label htmlFor="" className="form-label">
                      Offer Price
                    </label>
                    <input
                      type="number"
                      name="offerPrice"
                      placeholder="Offer Price"
                      disabled
                      defaultValue={formval?.offerPrice}
                      className="form-control"
                    />
                  </div>
                  <div className="col-lg-4 mb-4">
                    <label htmlFor="" className="form-label">
                      Image
                    </label>
                    <input
                      type="file"
                      className="form-control"
                      disabled
                      name="images"
                    />
                     <div className="image-box">
                      {
                            formval?.images?.map((arr) => {
                              return (
                                <div key={arr._id} className='image-box-innr'>
                                  <img src={`${BASE_URL}/${arr?.image}`} alt="Preview Image" />
                                  {/* <Link to="#" >
                                    <i className="fa fa-times" />
                                  </Link> */}
                                </div>
                              )
                            })
                          }
                     </div>
                  </div>
                  <div className="col-lg-12 mb-4">
                    <div id="editor">
                      <Tinymce
                        disabled={true}
                        data={formval?.details}
                        seteditor={setFormval}
                        editorTitle={"Video Details"}
                        fieldName={"details"}
                      />
                    </div>
                  </div>
                  <div className="col-lg-12 mb-4 text-center">
                    {/* <button className="thm-btn" type="submit">
                      Add Video Course
                    </button> */}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
