import React, { useState, useEffect } from "react";
// import Header from "../Layout/Header";
// import Sidebar from "../Layout/Sidebar";
import { Link } from "react-router-dom";
import edit from "../../assets/images/edit.png";
import trash from "../../assets/images/trash.png";
import axios from "axios";
import BASE_URL from "../config/baseUrl";
import Swal from "sweetalert2";

function Test_list() {
  let [data, setData] = useState([]);
  let [updateSequence, setUpdateSequence] = useState();
  let [searchTestName, setSearchTestName] = useState("");
  let [pageNumber, setPageNumber] = useState(1);
  const [showPagination, setShowPagination] = useState(true);
  let [testCount, settestCount] = useState([]);
  let [showIndex, setShowIndex] = useState(false);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    apicall();
  }, [pageNumber]);

  let apicall = async () => {
   try {
    let response = await axios(
      `${BASE_URL}/api/test/alldata?p=${pageNumber}`,
      {
        method: "GET",
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }
    );
    if (!response?.data?.status && response.status === 200) {
      setPageNumber(pageNumber - 1);
      return alert("Page Data Not Found ");
    }
    setData(response?.data?.data?.allTestData);
    settestCount(response?.data?.data?.TestCount);
   } catch (error) {
    console.log("error in fetching",error)
   }finally{
    setLoading(false)
   }
  };
  const handleStatus = (id) => {
    let apiCall = async () => {
      await axios(`${BASE_URL}/api/test/active/${id}`, {
        method: "PUT",
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
    };
    apiCall();
  };

  const handleDelete = (id) => {
    let apiCall = async () => {
      let response = await axios(`${BASE_URL}/api/test/delete/${id}`, {
        method: "POST",
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      let deletedUser = response?.data?.data;
      setData(data.filter((arr) => arr?._id !== deletedUser?._id));
    };
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        apiCall();
        Swal.fire({
          title: "Deleted!",
          text: "Test has been deleted.",
          icon: "success",
        });
      }
    });
  };

  const handleUpdate = async (e) => {
    let response = await axios(
      `${BASE_URL}/api/test/update_sequence/${e}?p=${pageNumber}`,
      {
        method: "PUT",
        headers: {
          Authorization: localStorage.getItem("token"),
        },
        data: { sequence: updateSequence },
      }
    );
    setData(response?.data?.data);
  };

  const searchHandle = async (e) => {
    e.preventDefault();
    setShowIndex(true);
    setShowPagination(false);
    let response = await axios(
      `${BASE_URL}/api/test/search?title=${searchTestName}`,
      {
        method: "GET",
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }
    );
    if (!response?.data?.status) {
      alert(response?.data?.message);
      return;
    } else if (response?.data?.data?.length === 0) {
      alert("Test Not Find");
    } else {
      setData(response?.data?.data);
    }
  };

  return (
    <>
      {/* <Header /> */}
      {/* <Sidebar /> */}
      <section className="main-sec">
        <div className="row">
          <div className="col-lg-12">
            <div className="dashboard-title">
              <h4 className="dash-head">
                <i className="fa-brands fa-readme  me-2" />
                Test List
              </h4>
              <Link className="blu-btn" to="/test_add">
                Add Test
              </Link>
            </div>
            <div className="custom-bredcump">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Test List
                  </li>
                </ol>
              </nav>
            </div>
          </div>

          <div className="col-lg-12">
            <div className="cards bus-list">
              <div className="bus-filter">
                <div className="row">
                  <div className="col-lg-7">
                    <h4 className="table-head">Test List ({testCount})</h4>
                  </div>
                  <div className="col-lg-5">
                    <form action="" onSubmit={searchHandle}>
                      <div className="row justify-content-end gx-2 align-items-center">
                        <div className="col-lg-6">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Search Test..."
                            onChange={(e) => setSearchTestName(e.target.value)}
                          />
                        </div>
                        <div className="col-lg-2">
                          <button type="submit" className="blu-btn w-100">
                            Search
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div className="table table-responsive custom-table">
              {
                  loading ? (
                    <div className="text-center my-4">
                      <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                      </div>
                    </div>
                  ) : (
                <table className="table table-borderless">
                  <thead>
                    <tr>
                      <th>SR NO.</th>
                      <th>Title</th>
                      {/* <th>Package Name</th> */}
                      {/* <th>total Question</th>
                                            <th>duration</th>
                                            <th>marks</th>
                                            <th>negative Marks</th> */}
                      <th>Category</th>
                      {/* <th>Sequence</th> */}
                      <th>Status</th>
                      {/* <th>total Question </th> */}
                      <th>ACTION</th>
                      {/* <th>QUESTION ASSIGN</th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {data?.map((arr, i) => (
                      <tr key={arr?._id}>
                        <td>{showIndex ? ++i : ++i + 10 * (pageNumber - 1)}</td>
                        <td>{arr?.test_title}</td>
                        {/* <td>{arr?.test_dept_names}</td> */}
                        {/* <td>{arr?.testPackage?.title}</td> */}
                        <td>{arr?.test_dept_names}</td>
                        {/* <td>
                          <div className="d-flex table-box">
                            <input
                              type="number"
                              className="form-control"
                              defaultValue={arr?.sequence}
                                onChange={(e) =>
                                   setUpdateSequence(e.target.value)
                                }
                                min={0}
                            />
                            <button
                              className="btn btn-success ms-2"
                                onClick={() => handleUpdate(arr._id)}
                            >
                              Update
                            </button>
                          </div>
                        </td> */}
                        <td>
                          <div className="form-check form-switch">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              defaultChecked={arr?.test_status}
                              onClick={() => handleStatus(arr._id)}
                            />
                          </div>
                        </td>
                        {/* <td>{arr?.duration}</td> */}
                        {/* <td>{arr?.marks}</td> */}
                        {/* <td>{arr?.negMarks}</td> */}

                        <td>
                          <Link className="mx-1" to={`/test-edit/${arr?._id}`}>
                            <img src={edit} alt="" />
                          </Link>
                          <Link
                            className="mx-1"
                            onClick={() => handleDelete(arr?._id)}
                          >
                            <img src={trash} alt="" />
                          </Link>
                        </td>
                        {/* <td>
                          <Link
                            to={`/test_assign_duestion_add/${arr._id}`}
                            className="blu-btn"
                          >
                            Assign
                          </Link>
                        </td> */}
                        {/* <td>
                             <Link className='thm-btn rounded-2'>Student List</Link>
                         </td>  */}
                      </tr>
                    ))}
                  </tbody>
                </table>)}
                {showPagination && (
                  <div className="custom-pagination">
                    <ul className="pagination">
                      <li className="page-item">
                        <button
                          className="page-link"
                          to=""
                          aria-label="Previous"
                          disabled={pageNumber === 1 && true}
                          onClick={() => setPageNumber((prev) => prev - 1)}
                        >
                          <span aria-hidden="true">«</span>
                        </button>
                      </li>
                      {pageNumber >= 2 && (
                        <li className="page-item">
                          <Link className="page-link" to="">
                            {pageNumber - 1}
                          </Link>
                        </li>
                      )}
                      <li className="page-item">
                        <Link className="page-link active" to="">
                          {pageNumber}
                        </Link>
                      </li>
                      <li className="page-item">
                        <Link
                          className="page-link"
                          to=""
                          onClick={() => setPageNumber((prev) => prev + 1)}
                        >
                          {pageNumber + 1}
                        </Link>
                      </li>
                      <li className="page-item">
                        <Link
                          className="page-link"
                          to=""
                          onClick={() => setPageNumber((prev) => prev + 2)}
                        >
                          {pageNumber + 2}
                        </Link>
                      </li>
                      <li className="page-item">
                        <Link
                          className="page-link"
                          to=""
                          aria-label="Next"
                          onClick={() => setPageNumber((prev) => prev + 1)}
                        >
                          <span aria-hidden="true">»</span>
                        </Link>
                      </li>
                    </ul>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Test_list;
