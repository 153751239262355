  import { useRef } from 'react';
  // import './App.css';
  import { Editor } from '@tinymce/tinymce-react';
  
  export default function Tinymce({disabled,data,seteditor,editorTitle,fieldName}) {
    const editorRef = useRef(null);
    const log = () => {
      if (editorRef.current) {
        seteditor((curval)=>({...curval, [fieldName]:editorRef.current.getContent()}))
      }
    };

    // console.log(disabled)

    return (
      <>
      <div>{editorTitle} </div>
        <Editor
          apiKey='hpgddg8785uw1wz6qrqk6y352xswhsan7b4ixzy3pqptutkx'
          onInit={(_evt, editor) => {
            editorRef.current = editor
          }}
          disabled={disabled}
          
          initialValue={data}
          init={{
            selector: '#editor',
            height: 500,
             setup : (editor) => {
              editor.on("change", (e) => {
                log()
              });
            },
            menubar: false,
            plugins: [
              'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
              'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
              'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount','image'
            ],
            toolbar: 'undo redo | blocks | ' +
              'bold italic forecolor | alignleft aligncenter ' +
              'alignright alignjustify | bullist numlist outdent indent | ' +
              'removeformat | help | backcolor',
            content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
          }}
        />
        
      </> 
    );
  }
  
  // export default App;
  