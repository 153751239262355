import React, { useState, useEffect } from "react";
// import Header from "../Layout/Header";
// import Sidebar from "../Layout/Sidebar";
import { Link, useParams, useNavigate } from "react-router-dom";

import BASE_URL from "../config/baseUrl";
import axios from "axios";
function Role_edit() {
  let navigate = useNavigate();
  let { id } = useParams();
  let [data, setData] = useState([]);
  let [perCategory, setPerCategory] = useState([]);
  let [rolePermission, setrolePermission] = useState([]);
  const [formval, setFormval] = useState([]);
  useEffect(() => {
    const apiCall = async () => {
      let response = await axios(`${BASE_URL}/api/rolepermission/show/${id}`, {
        // let response = await axios(`${BASE_URL}/api/rolepermission/show/${id}`, {
        method: "GET",
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      console.log(response.data);
      // console.log(response.data?.data.permissionId);
      setrolePermission(response?.data?.data);
      // setFormval(response?.data?.data?.permissionId);
      let filter=response?.data?.data.permissionCategoryId.map((arr)=>(arr?._id))
      setFormval(filter);
    };
    apiCall();
  }, []);

  useEffect(() => {
    const apiCall = async () => {
      let response = await fetch(`${BASE_URL}/api/permission/alldata`, {
        method: "GET",
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      const result = await response.json();
      // console.log(result);
      setData([...result?.data]);
    };
    apiCall();
  }, []);

  useEffect(() => {
    const apiCall = async () => {
      let response = await fetch(`${BASE_URL}/api/permissioncategory/alldata`, {
        method: "GET",
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      const result = await response.json();
      // console.log(result,"all category");
      // let filter=result?.data?.map((arr)=>(arr?._id))
      setPerCategory([...result?.data]);
      //   roleId
    };
    apiCall();
  }, []);

  // useEffect(() => {
  //   const apiCall = async () => {
  //     let response = await fetch(`http://localhost:8080/api/rolepermission/show/661f9701e4b072715da3d36b`, {
  //       method: "GET",
  //       headers: {
  //         Authorization: localStorage.getItem("token"),
  //       },
  //     });
  //     const result = await response.json();
  //     console.log(result);
  //     // setPerCategory([...result?.data]);
  //     //   roleId
  //   };
  //   apiCall();
  // }, []);

  return (
    <>
      {/* <Header /> */}
      {/* <Sidebar /> */}
      <section className="main-sec">
        <div className="row">
          <div className="col-lg-12">
            <div className="dashboard-title">
              <h4 className="dash-head">
                <i className="fas fa-video me-2" />
                Edit Role
              </h4>
            </div>
            <div className="custom-bredcump">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link>Roles</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Edit Role
                  </li>
                </ol>
              </nav>
            </div>
          </div>
          <div className="col-lg-12">
            <div className="cards cstm-form">
              <form action="">
                <div className="row">
                  <div className="col-lg-6 mb-4">
                    <label htmlFor="" className="form-label">
                      Name
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder=""
                      defaultValue={rolePermission?.roleId?.role}
                      disabled
                    />
                  </div>
                </div>
                <div className="row">
                  {/* {data?.map((arr)=>
                                    <div className='col-lg-3 mb-4'>
                                        <h4 className='dash-head'>{arr?.category?.category}</h4>
                                        <ul className='roles-manage-list'>
                                            <li>
                                                <input type='checkbox' id='role-create' />
                                                <label for="role-create">{arr?.permission}</label>
                                            </li>
                                            <li>
                                                <input type='checkbox' id='role-edit' />
                                                <label for="role-edit">Role Edit</label>
                                            </li>
                                            <li>
                                                <input type='checkbox' id='role-delete' />
                                                <label for="role-delete">Role Delete</label>
                                            </li>
                                        </ul>
                                    </div>)}  */}
                  {perCategory?.map((row, rowIndex) => (
                    <div className="col-lg-3 mb-4 form-check"    key={rowIndex}>
                      <input
                        type="checkbox"
                        class="form-check-input"
                        id={row._id}
                        checked={formval?.includes(row?._id) && true}
                        disabled
                      />
                      <h4 className="dash-head ">{row?.category}</h4>
                    </div>
                  ))}

                  {/* <div className='col-lg-3 mb-4'>
                                        <h4 className='dash-head'>Permission</h4>
                                        <ul className='roles-manage-list'>
                                            <li>
                                                <input type='checkbox' id='permission-create' />
                                                <label for="permission-create">Permission Create</label>
                                            </li>
                                            <li>
                                                <input type='checkbox' id='permission-edit' />
                                                <label for="permission-edit">Permission Edit</label>
                                            </li>
                                            <li>
                                                <input type='checkbox' id='permission-delete' />
                                                <label for="permission-delete">Permission Delete</label>
                                            </li>
                                            <li>
                                                <input type='checkbox' id='permission-read' />
                                                <label for="permission-read">Permission Read</label>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className='col-lg-3 mb-4'>
                                        <h4 className='dash-head'>User Managment</h4>
                                        <ul className='roles-manage-list'>
                                            <li>
                                                <input type='checkbox' id='user-read' />
                                                <label for="user-read">User Read</label>
                                            </li>
                                            <li>
                                                <input type='checkbox' id='user-edit' />
                                                <label for="user-edit">User Edit</label>
                                            </li>
                                            <li>
                                                <input type='checkbox' id='user-delete' />
                                                <label for="user-delete">User Delete</label>
                                            </li>
                                            <li>
                                                <input type='checkbox' id='user-create' />
                                                <label for="user-create">User Create</label>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className='col-lg-3 mb-4'>
                                        <h4 className='dash-head'>Banner</h4>
                                        <ul className='roles-manage-list'>
                                            <li>
                                                <input type='checkbox' id='banner-add' />
                                                <label for="banner-add">Banner Add</label>
                                            </li>
                                            <li>
                                                <input type='checkbox' id='banner-edit' />
                                                <label for="banner-edit">Banner Edit</label>
                                            </li>
                                            <li>
                                                <input type='checkbox' id='banner-delete' />
                                                <label for="banner-delete">Banner Delete</label>
                                            </li>
                                            <li>
                                                <input type='checkbox' id='banner-list' />
                                                <label for="banner-list">Banner List</label>
                                            </li>
                                            <li>
                                                <input type='checkbox' id='banner-view' />
                                                <label for="banner-view">Banner View</label>
                                            </li>
                                            <li>
                                                <input type='checkbox' id='banner-status' />
                                                <label for="banner-status">Banner Status</label>
                                            </li>
                                        </ul>
                                    </div> */}
                </div>
                <div className="col-lg-12 mb-4 text-center">
                  <button className="thm-btn" type="submit">
                    Submit Role
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Role_edit;
