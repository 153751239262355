import React, { useState, useEffect } from "react";
// import Header from "../Layout/Header";
// import Sidebar from "../Layout/Sidebar";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import BASE_URL from "../config/baseUrl";
// import MyCkeditor from "../ckeditor/MyCKeditor";
// import edit from '../../assets/images/edit.png'
// import trash from '../../assets/images/trash.png'

export default function Test_Assign_Question_add() {
  const navigate = useNavigate();
  const [question, setQuestion] = useState([]);
  const [formval, setFormval] = useState([]);
  const [count, setCount] = useState();

  const { testid, subjectid, totalQuestion } = useParams();
  const handleCheck = (id, e) => {
    if (formval.length >= totalQuestion && e.target.checked) {
      return alert(
        `You Reach Out Your Question Limit  .  Question Limit Only - ${totalQuestion}`
      );
    }
    setFormval((curval) => {
      if (curval.includes(id)) {
        let data = curval.filter((item) => item !== id);
        return [...data];
      }
      return [...curval, id];
    });

  };

  useEffect(() => {
    if (subjectid == "undefined") {
      alert("Please Select Subject First");
      return navigate(`/test-edit/${testid}`);
    }
    //   let apicallAllQuestion = async () => {
    //     let response = await axios(
    //       `${BASE_URL}/api/question/all_question`,
    //       {
    //         method: "GET",
    //         headers: {
    //           Authorization: localStorage.getItem("token"),
    //         },
    //       }
    //     );
    //     console.log(response.data)
    //     let filterData = response?.data?.data?.allQuestion
    //     filterData = filterData.filter((arr) => arr.isActive === true);
    //     setQuestion(filterData);
    //     setCount(response?.data?.data?.questionCount)
    //   };

    let test_assign_question = async () => {
      let response = await axios(
        `${BASE_URL}/api/test_assign_question/details/${testid}/${subjectid}`,
        {
          method: "GET",
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        }
      );
      // console.log(response)
      setFormval(response?.data?.data?.questions || []);
    };
    let subject_Allquestion = async () => {
      let response = await axios(
        `${BASE_URL}/api/question/subject_allquestion/${subjectid}`,
        {
          method: "GET",
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        }
      );
      // setFormval(response?.data?.data?.questions || [])
      console.log(response.data);
      let filterData = response?.data?.data?.allQuestion;
      filterData = filterData.filter((arr) => arr.isActive === true);
      setQuestion(filterData || []);
      // setQuestion( response?.data?.data?.allQuestion || []);
      setCount(response?.data?.data?.questionCount);
    };
    //   apicallAllQuestion();
    subject_Allquestion();
    test_assign_question();
  }, []);

  let handleClick = () => {
    if (formval.length > totalQuestion) {
      return alert(
        `You Reach Out Your Question Limit  .  Question Limit Only - ${totalQuestion}`
      );
    }
    let apicall = async () => {
      let response = await axios(
        `${BASE_URL}/api/test_assign_question/assigntestquestion/${testid}`,
        
        {
          method: "POST",
          headers: {
            Authorization: localStorage.getItem("token"),
          },
          data: { test_id: testid, questions: formval, subjectid: subjectid },
        }
      );
      // console.log(response.data);
      navigate("/test_list");
    };
    apicall();
  };

  return (
    <>
      {/* <Header /> */}
      {/* <Sidebar /> */}
      <section className="main-sec">
        <div className="row">
          <div className="col-lg-12">
            <div className="dashboard-title">
              <h4 className="dash-head">
                <i className="fa-solid fa-book  me-2" />
                Question Assign Managment
              </h4>
            </div>
            <div className="custom-bredcump">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/test_list">Test</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Question Assign List
                  </li>
                </ol>
              </nav>
            </div>
          </div>
          <div className="col-lg-12">
            <div className="cards bus-list">
              <div className="bus-filter">
                <div className="row">
                  <div className="col-lg-12">
                    <h4 className="table-head">
                      Question Assign List ({question.length}){" "}
                    </h4>
                  </div>
                  <span>Total Selected Questions ({formval?.length})</span>
                  <span>Questions Limit ({totalQuestion})</span>
                </div>
              </div>
              <Link
                className="btn btn-success mb-2 me-2"
                to={`/test-edit/${testid}`}
              >
                &lt; Back
              </Link>
              <button className="btn btn-success mb-2" onClick={handleClick}>
                POST
              </button>
              <div className="table table-responsive custom-table">
                <table className="table table-borderless">
                  <thead>
                    <tr>
                      <th>SR NO.</th>
                      <th>Checked</th>
                      <th>Title</th>
                      <th className="">Subject</th>
                    </tr>
                  </thead>
                  <tbody>
                    {question && question?.length > 0 ? (
                      question?.map((arr, i) => (
                        <tr key={arr?._id}>
                          <td>{++i}</td>
                          <td>
                            <div className="form-check">
                              <input
                                type="checkbox"
                                className="form-check-input"
                                onChange={(e) => handleCheck(arr?._id, e)}
                                checked={formval?.includes(arr?._id) && true}
                                id=""
                              />
                            </div>
                          </td>
                          <td>{arr?.question}</td>
                          <td>{arr?.subject_id?.title || "null"}</td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td>
                          Question not find Please Add Subject or Assign
                          Question first
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
                {/* <div className="custom-pagination">
                                  <ul className="pagination">
                                      <li className="page-item">
                                          <Link className="page-link" to="" aria-label="Previous">
                                              <span aria-hidden="true">«</span>
                                          </Link>
                                      </li>
                                      <li className="page-item">
                                          <Link className="page-link active" to="">
                                              1
                                          </Link>
                                      </li>
                                      <li className="page-item">
                                          <Link className="page-link" to="">
                                              2
                                          </Link>
                                      </li>
                                      <li className="page-item">
                                          <Link className="page-link" to="">
                                              3
                                          </Link>
                                      </li>
                                      <li className="page-item">
                                          <Link className="page-link" to="" aria-label="Next">
                                              <span aria-hidden="true">»</span>
                                          </Link>
                                      </li>
                                  </ul>
                              </div> */}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
