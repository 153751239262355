import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import BASE_URL from "../config/baseUrl";
import axios from "axios";
function NewRole() {
  let navigate = useNavigate();
  let [data, setData] = useState([]);
  let [perCategory, setPerCategory] = useState([]);
  let [role, setRole] = useState("");
  const [formval, setFormval] = useState([]);

  useEffect(() => {
    const permissionData = async () => {
      let response = await fetch(`${BASE_URL}/api/permission/alldata`, {
        method: "GET",
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      const result = await response.json();
      console.log(result);
      setData([...result?.data]);
    };
    permissionData();
    const permissionCategoryData = async () => {
      let response = await fetch(`${BASE_URL}/api/permissioncategory/alldata`, {
        method: "GET",
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      const result = await response.json();
      setPerCategory([...result?.data]);
    };
    permissionCategoryData();
  }, []);

  const handleChange = (id, e) => {
    setFormval((curval) => {
      if (curval.includes(id)) {
        let data = curval.filter((item) => item !== id);
        return [...data];
      }
      return [...curval, id];
    });
  };

  let onSubmitHandle = (e) => {
    e.preventDefault();
    console.log(formval);
    console.log(role);
    // return;
    let roleId;
    let addRoleApi = async () => {
      let responseRoleApi = await axios(`${BASE_URL}/api/role/add`, {
        method: "POST",
        headers: {
          Authorization: localStorage.getItem("token"),
        },
        data: { role: role },
      });
      console.log(responseRoleApi);
      roleId = responseRoleApi?.data?.data?._id;
      if (!responseRoleApi?.data?.status) {
        alert(responseRoleApi?.data?.message);
        return;
      }
      let responseRolePermission = await axios(
        `${BASE_URL}/api/rolepermission/add`,
        {
          method: "POST",
          headers: {
            Authorization: localStorage.getItem("token"),
          },
          data: { roleId: roleId, permissionCategoryId: formval },
        }
      );
      console.log(responseRolePermission);
      if (!responseRolePermission?.data?.status) {
        alert(responseRolePermission?.data?.message);
        return;
      }
      navigate("/role_list");
    };
    addRoleApi();
  };

  return (
    <>
      <section className="main-sec">
        <div className="row">
          <div className="col-lg-12">
            <div className="dashboard-title">
              <h4 className="dash-head">
                <i className="fas fa-video me-2" />
                Add New Role
              </h4>
            </div>
            <div className="custom-bredcump">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link>Roles</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Add New Role
                  </li>
                </ol>
              </nav>
            </div>
          </div>
          <div className="col-lg-12">
            <div className="cards cstm-form">
              <form action="" onSubmit={onSubmitHandle}>
                <div className="row">
                  <div className="col-lg-6 mb-4">
                    <label htmlFor="" className="form-label">
                      Name
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Role Name"
                      onChange={(e) => setRole(e.target.value)}
                    />
                  </div>
                </div>
                <div className="row">
                  {perCategory?.map((row, rowIndex) => (
                    <div className="col-lg-3 mb-4" key={rowIndex}>
                      <input
                        class="form-check-input"
                        type="checkbox"
                        id={row?._id}
                        onChange={(e) => handleChange(row?._id, e)}
                        checked={formval?.includes(row?._id) && true}
                      />

                      <h4 className="dash-head">{row?.category}</h4>
                    </div>
                  ))}
                </div>
                <div className="col-lg-12 mb-4 text-center">
                  <button className="thm-btn" type="submit">
                    Submit Role
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default NewRole;
