import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import BASE_URL from "../config/baseUrl";
import axios from "axios";

const RoleAdd = () => {
  let navigate = useNavigate();
  const [formval, setFormval] = useState({
    name: "",
  });

  const handlepostRole = async (e) => {
    e.preventDefault();
    if(!formval.name){
      alert("Emply Field Not Require")
      return
    }
    try {
      const response = await axios.post(
        `${BASE_URL}/api/role/role-create`,
        formval,
        {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        }
      );
      console.log("responseAdd", response.data.data);
      console.log("responseAdd", response.data.status);
      if(response?.data?.status){
        navigate("/rolelist");
      }else{
        alert(response?.data?.msg)
      }
    } catch (error) {
      console.log("error in role add", error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormval({ ...formval, [name]: value });
  };

  return (
    <>
      <section className="main-sec">
        <div className="row">
          <div className="col-lg-12">
            <div className="dashboard-title">
              <h4 className="dash-head">
                <i className="fas fa-video me-2" />
                Add New Role
              </h4>
            </div>
            <div className="custom-bredcump">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link>Roles</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Add New Role
                  </li>
                </ol>
              </nav>
            </div>
          </div>
          <div className="col-lg-12">
            <div className="cards cstm-form">
              <form onSubmit={handlepostRole}>
                <div className="row">
                  <div className="col-lg-6 mb-4">
                    <label htmlFor="" className="form-label">
                      Name
                    </label>
                    <input
                      type="text"
                      name="name"
                      className="form-control"
                      placeholder="Enter Role Name"
                      value={formval.name}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="col-lg-12 mb-4 text-center">
                  <button className="thm-btn" type="submit">
                    Submit  
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default RoleAdd;
