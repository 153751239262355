import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import view from "../../assets/images/view.png";
import edit from "../../assets/images/edit.png";
import trash from "../../assets/images/trash.png";
import { baseUrl } from "../config/baseUrl";
import axios from "axios";
const TopicListData = () => {
  const {id,topicid} = useParams();
  // console.log("id",id)
  // console.log("topicId",topicid)
    const [topicData, setTopicData] = useState([]);
    const [loading, setLoading] = useState(true);
// console.log("topicData",topicData.length)
  useEffect(() => {
    fetchLiveData();
  }, []);

  const fetchLiveData = async () => {
    try {
      const response = await axios.get(
        `${baseUrl}/api/subject-get/${id}/${topicid}`,
        // { user_id: "22541", type: "live" },
        {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        }
      );
      // console.log("responsetopic", response.data.data);
      setTopicData(response.data.data);
    } catch (error) {
      console.log("error", error);
    }finally{
      setLoading(false)
    }
  };
  return (
    <>
    <section className="main-sec">
        <div className="row">
          <div className="col-lg-12">
            <div className="dashboard-title">
              <h4 className="dash-head">
                <i className="fas fa-video me-2" />
                Topic List
              </h4>
              <Link to="#" className="blu-btn">
                <i className="far fa-plus me-2" />
                Add  Topic
              </Link>
            </div>
            <div className="custom-bredcump">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/videocourselist">
                       Video Course Management
                    </Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                  Topic List
                  </li>
                </ol>
              </nav>
            </div>
          </div>
          <div className="col-lg-12">
            <div className="cards bus-list">                        
              <div className="bus-filter">
                <div className="row justify-content-end">
                  <div className="col-lg-7">
                    <h4 className="table-head"> Topic List  ({topicData.length})</h4>
                  </div>
                  <div className="col-lg-5">
                    <form action="">
                      <div className="row justify-content-end gx-2 align-items-center">
                        {/* <div className="col-lg3"> */}
                        {/* <input
                            type="text"
                            className="form-control"
                            placeholder="Search..."
                            name="search"
                            onChange={(e) => setSearch(e.target.value)}
                            value={search}
                          />
                        </div> */}
                        <div className="col-lg-6">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Course Name"
                            // onChange={(e) =>
                            //   setSearchCourseName(e.target.value)
                            // }
                          />
                        </div>
                        {/* <div className="col-lg-3"><input type="text" className="form-control" onChange={(e)=>setSearchCategory(e.target.value)} placeholder="Category Name"/></div> */}
                        {/* <div className="col-lg-3"><input type="text" className="form-control" placeholder="Price"/></div> */}
                        {/* <div className="col-lg-3"><button type="button" className="blu-btn w-100">Search</button></div> */}
                        <div className="col-lg-2">
                          <button type="submit" className="blu-btn ">
                            Search
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div className="table table-responsive custom-table">
              {
                  loading ? (
                    <div className="text-center my-4">
                      <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                      </div>
                    </div>
                  ) : (
                <table className="table table-borderless">
                  <thead>
                    <tr>
                      <th>SR NO.</th>
                      <th>Image</th>
                      <th>SUBJECT NAME</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {topicData.map((arr, i) => (
                      <tr key={arr.topic_id}>
                        <th>{i + 1}</th>
                        <th>
                          <img
                            src={`${arr?.topic_image}`}
                            className="img-40"
                            alt=""
                          />
                        </th>
                        <td>{arr?.topic_name}</td>
                        <td>
                          <Link
                            to="#"
                            // to={`/live_video_course_details/${arr._id}`}
                            className="mx-2"
                          >
                            <img src={view} alt="" />
                          </Link>
                          <Link
                            to="#"
                            // to={`/live_video_course_edit/${arr._id}`}
                            className="mx-2"
                          >
                            <img src={edit} alt="" />
                          </Link>
                          <Link
                            to="#"
                            // onClick={() => handleDelete(arr._id)}
                            className="mx-2"
                          >
                            <img src={trash} alt="" />
                          </Link>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>)}
                {/* {showPagination && (
                  <div className="custom-pagination">
                    <ul className="pagination">
                      <li className="page-item">
                        <button
                          className="page-link"
                          to=""
                          aria-label="Previous"
                          disabled={pageNumber === 1 && true}
                          onClick={() => setPageNumber((prev) => prev - 1)}
                        >
                          <span aria-hidden="true">«</span>
                        </button>
                      </li>
                      {pageNumber >= 2 && (
                        <li className="page-item">
                          <Link className="page-link" to="">
                            {pageNumber - 1}
                          </Link>
                        </li>
                      )}
                      <li className="page-item">
                        <Link className="page-link active" to="">
                          {pageNumber}
                        </Link>
                      </li>
                      <li className="page-item">
                        <Link
                          className="page-link"
                          to=""
                          onClick={() => setPageNumber((prev) => prev + 1)}
                        >
                          {pageNumber + 1}
                        </Link>
                      </li>
                      <li className="page-item">
                        <Link
                          className="page-link"
                          to=""
                          onClick={() => setPageNumber((prev) => prev + 2)}
                        >
                          {pageNumber + 2}
                        </Link>
                      </li>
                      <li className="page-item">
                        <Link
                          className="page-link"
                          to=""
                          aria-label="Next"
                          onClick={() => setPageNumber((prev) => prev + 1)}
                        >
                          <span aria-hidden="true">»</span>
                        </Link>
                      </li>
                    </ul>
                  </div>
                )} */}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default TopicListData