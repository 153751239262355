import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import BASE_URL from "../config/baseUrl";
import edit from "../../assets/images/edit.png";
import trash from "../../assets/images/trash.png";
const AttendTestList = () => {
  const [megaTest, setMegaTest] = useState([]);
  const [updateSequence, setUpdateSequence] = useState();
  const [mtestStatus, setMtestStatus] = useState([]);
  const [searchTest, setSearchTest] = useState("");
  const [isSearching, setIsSearching] = useState(false);
  const [loading, setLoading] = useState(true);
  let {id}=useParams()
  useEffect(() => {
    if (isSearching) {
      handleMegaTestSearch();
    } else {
      fetchData();
    }
  }, [isSearching]);
  const fetchData = async () => {
    try {
      // const response = await axios(`${BASE_URL}/api/attendtest/show/${id}`, {
      const response = await axios(`${BASE_URL}/api/attendtest//testdata/${id}`, {
        method: "GET",
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      // console.log("responsemtest", response);
      const megaTestRes = response.data.data
       const sortedMegaTest = megaTestRes.sort((a,b)=>a.sequence - b.sequence)
      setMegaTest(sortedMegaTest);
      const initialStatus = megaTestRes.map((mtest) => ({
        id: mtest._id,
        status: mtest.isActive,
      }));
      //   console.log("initialstatus", initialStatus);
      setMtestStatus(initialStatus);
    } catch (error) {
      console.log("fetching megaTest error", error);
    }finally{
      setLoading(false)
    }
  };
  //update Sequence
  const handleSequenceUpdate = async (id) => {
    try {
      const response = await axios.put(
        `${BASE_URL}/api/megatest/update_sequence/${id}`,
        { sequence: updateSequence },
        {
          headers: { 
            Authorization: localStorage.getItem("token"),
          },
        }
      );
      //   console.log("response",response.data.data)
      //   fetchData();
      setMegaTest(response.data.data);
    } catch (error) {
      console.log("sequence updating error", error);
    }
  };

  // toggle status
  const handleStatus = async (id) => {
    try {
      const response = await axios.put(
        `${BASE_URL}/api/megatest/active/${id}`,
        {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        }
      );
      //   console.log("aaya", response.data.data);
      const updatedStatus = mtestStatus.map((mtest) =>
        mtest.id === id
          ? { ...mtest, status: response.data.data.isActive }
          : mtest
      );
      setMtestStatus(updatedStatus);
    } catch (error) {
      console.log("updating status error", error);
    }
  };

  // search test
  const handleMegaTestSearch = async () => {
    try {
      const response = await axios(
        `${BASE_URL}/api/megatest/search?title=${searchTest}`,
        {
          method: "GET",
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        }
      );
      // console.log("responsesearch", response);
      setMegaTest(response.data.data);
      setSearchTest("");
      // setIsSearching(false);
    } catch (error) {
      console.log("searching test error", error);
    }
  };

  const handleSearch = (e) => {
    e.preventDefault();
    setIsSearching(true);
  };

  const handleDelete = async (id) => {
    try {
      const response = await axios.delete(
        `${BASE_URL}/api/megatest/delete/${id}`,
        {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        }
      );
      // console.log("responsedelete", response);
      setMegaTest(megaTest.filter((mtest) => mtest._id !== id));
      fetchData();
    } catch (error) {
      console.log("deleting mega test error", error);
    }
  };
  return (
    <>
      <section className="main-sec">
        <div className="row">
          <div className="col-lg-12">
            <div className="dashboard-title">
              <h4 className="dash-head">
                <i className="fa-brands fa-readme  me-2" />
                Attend Test List
              </h4>
              <Link className="blu-btn" to="">
                Add Attend Test
              </Link>
            </div>
            <div className="custom-bredcump">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                  Attend Test List
                  </li>
                </ol>
              </nav>
            </div>
          </div>

          <div className="col-lg-12">
            <div className="cards bus-list">
              <div className="bus-filter">
                <div className="row">
                  <div className="col-lg-7">
                    <h4 className="table-head">
                      Attend Test List ({megaTest.length})
                    </h4>
                  </div>
                  <div className="col-lg-5">
                    <form action="" onSubmit={handleSearch}>
                      <div className="row justify-content-end gx-2 align-items-center">
                        <div className="col-lg-6">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Search Test..."
                            onChange={(e) => setSearchTest(e.target.value)}
                          />
                        </div>
                        <div className="col-lg-2">
                          <button type="submit" className="blu-btn w-100">
                            Search
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div className="table table-responsive custom-table">
              {
                  loading ? (
                    <div className="text-center my-4">
                    <div className="spinner-border" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                  </div>
                  ):(
                <table className="table table-borderless">
                  <thead>
                    <tr>
                      <th>SR NO.</th>
                      <th>Test Title</th>
                      <th>student</th>
                      <th>Rank</th>
                      {/* <th>Subject</th> */}
                      {/* <th>Sequence</th>
                      <th>Status</th> */}
                      <th>duration</th>
                      <th>marks</th>
                      <th>negative Marks</th>
                      <th>total Question </th>
                      <th>ACTION</th>
                    </tr>
                  </thead>
                  <tbody>
                    {megaTest?.map((arr, i) => (
                      <tr key={arr?._id}>
                        <td>{i + 1}</td>
                        <td>{arr?.test_id?.title}</td>
                        {/* <td>{arr?.test_id?.title}</td> */}
                        <td><Link to={`/user_details/${arr?.userData?._id}`}>{arr?.userData?.first_name}</Link></td>
                        <td>{arr?.rank }</td>
                        {/* <td>
                          <div className="d-flex table-box">
                            <input
                              type="number"
                              className="form-control"
                              defaultValue={arr?.sequence}
                              onChange={(e) =>
                                setUpdateSequence(e.target.value)
                              }
                              min={0}
                            />
                            <button
                              className="btn btn-success ms-2"
                              onClick={() => handleSequenceUpdate(arr._id)}
                            >
                              Update
                            </button>
                          </div>
                        </td>
                        <td>
                          <div className="form-check form-switch">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              defaultChecked={arr?.isActive}
                              checked={
                                mtestStatus.find(
                                  (mtest) => mtest.id === arr._id
                                ).status
                              }
                              onClick={() => handleStatus(arr._id)}
                            />
                          </div>
                        </td> */}
                        {/* <td>{arr?.subject?.sub_name || "not Find"}</td> */}
                        <td>{arr?.duration || "null"}</td>
                        <td>{arr?.marks}</td>
                        <td>{arr?.negMarks}</td>
                        <td>{arr?.totalQuestions}</td>
                        <td>
                          <Link
                            className="mx-1"
                            
                          >
                            <img src={edit} alt="" />
                          </Link>
                          <Link
                            className="mx-1"
                            // onClick={() => handleDelete(arr?._id)}
                          >
                            <img src={trash} alt="" />
                          </Link>
                        </td>
                        
                      </tr>
                    ))}
                  </tbody>
                </table>)}
                {/* {showPagination && (
                  <div className="custom-pagination">
                    <ul className="pagination">
                      <li className="page-item">
                        <button
                          className="page-link"
                          to=""
                          aria-label="Previous"
                          disabled={pageNumber === 1 && true}
                          onClick={() => setPageNumber((prev) => prev - 1)}
                        >
                          <span aria-hidden="true">«</span>
                        </button>
                      </li>
                      {pageNumber >= 2 && (
                        <li className="page-item">
                          <Link className="page-link" to="">
                            {pageNumber - 1}
                          </Link>
                        </li>
                      )}
                      <li className="page-item">
                        <Link className="page-link active" to="">
                          {pageNumber}
                        </Link>
                      </li>
                      <li className="page-item">
                        <Link
                          className="page-link"
                          to=""
                          onClick={() => setPageNumber((prev) => prev + 1)}
                        >
                          {pageNumber + 1}
                        </Link>
                      </li>
                      <li className="page-item">
                        <Link
                          className="page-link"
                          to=""
                          onClick={() => setPageNumber((prev) => prev + 2)}
                        >
                          {pageNumber + 2}
                        </Link>
                      </li>
                      <li className="page-item">
                        <Link
                          className="page-link"
                          to=""
                          aria-label="Next"
                          onClick={() => setPageNumber((prev) => prev + 1)}
                        >
                          <span aria-hidden="true">»</span>
                        </Link>
                      </li>
                    </ul>
                  </div>
                )} */}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AttendTestList;
