import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import edit from "../../assets/images/edit.png";
import trash from "../../assets/images/trash.png";
const VideoCourseCategoryList = () => {
  const [videoCatData, setVideoCatData] = useState([]);
  const [loading, setLoading] = useState(true);
  // console.log("videoCatData", videoCatData);
  useEffect(() => {
    fetchCatData();
  }, []);
  const fetchCatData = async () => {
   try {
    const response = await axios.get(
      "https://test.qualityeducation.in/api/video-category-get"
    );
    // console.log("responseCAt", response.data.data);
    setVideoCatData(response.data.data);
   } catch (error) {
    console.log("error fetching",error)
   }finally{
    setLoading(false)
   }
  };
  return (
    <>
      <section className="main-sec">
        <div className="row">
          <div className="col-lg-12">
            <div className="dashboard-title">
              <h4 className="dash-head">
                <i className="fas fa-video me-2" />
                Video Course Category List
              </h4>
              <Link to="/video_course_category_add" className="blu-btn">
                <i className="far fa-plus me-2" />
                Add Course Category
              </Link>
            </div>
            <div className="custom-bredcump">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/video_course_list">Video Course Management</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Video Course Category List
                  </li>
                </ol>
              </nav>
            </div>
          </div>
          <div className="col-lg-12">
            <div className="cards bus-list">
              <div className="bus-filter">
                <div className="row justify-content-end">
                  <div className="col-lg-7">
                    <h4 className="table-head">
                      Total Video Course Category ({videoCatData.length})
                    </h4>
                  </div>
                  <div className="col-lg-5">
                    <form action="">
                      <div className="row justify-content-end gx-2 align-items-center">
                        <div className="col-lg-6">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Search Category..."
                            // onChange={(e) =>
                            //   setSearchCategoryName(e.target.value)
                            // }
                          />
                        </div>
                        <div className="col-lg-2">
                          <button type="submit" className="blu-btn ">
                            Search
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div className="table table-responsive custom-table">
              {
                  loading ? (
                    <div className="text-center my-4">
                      <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                      </div>
                    </div>
                  ) : (
                <table className="table table-borderless">
                  <thead>
                    <tr>
                      <th>SR NO.</th>
                      <th>Image</th>
                      <th>Category NAME</th>
                      <th>CATEGORY SEQUENCE</th>
                      <th>STATUS</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {videoCatData?.map((arr, i) => (
                      <tr key={arr.id}>
                        <th>{i + 1}</th>
                        <th>
                          <img src={arr?.image} className="img-40" alt="" />
                        </th>
                        <td>
                          <Link to={`/videocourselist/${arr?.id}`}>
                            {arr?.category_name}
                          </Link>
                        </td>
                        <td>
                          <div className="d-flex table-box">
                            <input
                              type="number"
                              name="categorySequence"
                              // value={arr?.categorySequence}
                              //   onChange={(e)=>setUpdateSequence(e.target.value)}
                              className="form-control"
                              defaultValue={arr?.sequence}
                              min={0}
                            />
                            <button
                              className="btn btn-success ms-2"
                              // onClick={()=>handleUpdate(arr._id)}
                            >
                              Update
                            </button>
                          </div>
                        </td>
                        <td>
                          <div className="form-check form-switch">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              defaultChecked={arr?.status}
                              //   onClick={()=>handleStatus(arr._id)}
                            />
                          </div>
                        </td>
                        <td>
                          <Link
                            to="#"
                            //   to={`/VideoCourseCategory_edit/${arr._id}`}
                            className="mx-2"
                          >
                            <img src={edit} alt="" />
                          </Link>
                          <Link
                            to="#"
                            className="mx-2"
                            //   onClick={()=>handleDelete(arr._id)}
                          >
                            <img src={trash} alt="" />
                          </Link>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>)}
                {/* { showPagination &&  */}
                <div className="custom-pagination">
                  <ul className="pagination">
                    <li className="page-item">
                      <button
                        className="page-link"
                        to=""
                        aria-label="Previous"

                        // disabled={pageNumber === 1 && true}
                        // onClick={() => setPageNumber((prev) => prev - 1)}
                      >
                        <span aria-hidden="true">«</span>
                      </button>
                    </li>
                    {/* {pageNumber >= 2 && ( */}
                    <li className="page-item">
                      <Link className="page-link" to="">
                        {/* {pageNumber - 1} */} 1
                      </Link>
                    </li>
                    {/* )} */}
                    <li className="page-item">
                      <Link className="page-link active" to="">
                        {/* {pageNumber} */} 2
                      </Link>
                    </li>
                    <li className="page-item">
                      <Link
                        className="page-link"
                        to=""
                        // onClick={() => setPageNumber((prev) => prev + 1)}
                      >
                        {/* {pageNumber + 1} */}3
                      </Link>
                    </li>
                    <li className="page-item">
                      <Link
                        className="page-link"
                        to=""
                        // onClick={() => setPageNumber((prev) => prev + 2)}
                      >
                        {/* {pageNumber + 2} */}4
                      </Link>
                    </li>
                    <li className="page-item">
                      <Link
                        className="page-link"
                        to=""
                        aria-label="Next"
                        // onClick={() => setPageNumber((prev) => prev + 1)}
                      >
                        <span aria-hidden="true">»</span>
                      </Link>
                    </li>
                  </ul>
                </div>
                {/* } */}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default VideoCourseCategoryList;
