import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import BASE_URL from "../config/baseUrl";

const RolePermissionAdd = () => {
  let navigate = useNavigate();
  let [formval, setFormval] = useState({});
  // const [catData, setCatData] = useState([]);
  let [role, setrole] = useState([]);
  let [subCategory, setSubCategory] = useState([]);
  let [checkedPermissions, setCheckedPermissions] = useState([]);
  let [checkedCategories, setCheckedCategories] = useState({});
  // console.log("11", checkedPermissions);
  useEffect(() => {
    // fetchCatData();
    fetchRole();
    apiCall();
  }, []);
  const handleCheckboxChange = (id) => {
    setCheckedPermissions((prev) =>
      prev.includes(id) ? prev.filter((permId) => permId !== id) : [...prev, id]
    );
  };

  const fetchRole = async () => {
    try {
      let response = await axios(`${BASE_URL}/api/role/role-get-all`, {
        method: "GET",
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      console.log("responserole", response);
      setrole(response?.data?.data);
    } catch (error) {
      console.log("error getting role", error);
    }
  };
  // console.log("role_id", formval.role_id);
  const handlepostPermission = async (e) => {
    e.preventDefault();
    if(!formval?.role_id ){
      alert("Please Select Role ")
      return
    }
     
    if(checkedPermissions.length === 0){
      return alert("Please Select Role Permission")
    }
 
    
    const response = await axios.post(
      `${BASE_URL}/api/rolepermission/role-permission-create`,
      { role_id: formval.role_id, sub_cat_id: checkedPermissions },
      {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }
    );
    // console.log("123", response?.data);
    navigate("/rolepermissionlist");
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormval({ ...formval, [name]: value });
  };

  //fetch sub category api
  const apiCall = async () => {
    let response = await axios(
      `${BASE_URL}/api/permissioncategory/permission-n-sub-category-get-all`,
      {
        method: "GET",
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }
    );
    // console.log("result", response);
    setSubCategory(response?.data?.data);
  };

  const handleCategoryCheckboxChange = (categoryId, subCategories) => {
    const isCategoryChecked = !checkedCategories[categoryId];
 
    setCheckedCategories((prev) => ({
      ...prev,
      [categoryId]: isCategoryChecked,
    }));
    const allSubCategoryIds = subCategories.map((sub) => sub._id);
    // Update the checked permissions state
    setCheckedPermissions((prev) => {
      if (isCategoryChecked) {
        // Add all subcategories of the checked category
        return [...new Set([...prev, ...allSubCategoryIds])];
      } else {
        // Remove all subcategories of the unchecked category
        return prev.filter((id) => !allSubCategoryIds.includes(id));
      }
    });
  };
  return (
    <>
      <section className="main-sec">
        <div className="row">
          <div className="col-lg-12">
            <div className="dashboard-title">
              <h4 className="dash-head">
                <i className="fa-solid fa-book  me-2" />
                Add Role Permission
              </h4>
            </div>
            <div className="custom-bredcump">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="#">Test</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Add Role Permission
                  </li>
                </ol>
              </nav>
            </div>
          </div>
          <div className="col-lg-12">
            <div className="cards cstm-form">
              <form onSubmit={handlepostPermission}>
                <div className="row">
                  <div className="col-lg-4 col-md-6 mb-4">
                    <label htmlFor="" className="form-label">
                      Role
                    </label>
                    <select
                      name="role_id"
                      onChange={handleChange}
                      className="form-select"
                    >
                      <option defaultValue>Select Role</option>
                      {role?.map((arr) => (
                        <option key={arr?._id} value={arr._id}>
                          {arr?.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="row">
                    {subCategory.map((category) => (
                      <div key={category._id} className="col-lg-3 role-management">
                        <div>
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id={`category-${category._id}`}
                            checked={!!checkedCategories[category._id]}
                            onChange={() =>
                              handleCategoryCheckboxChange(
                                category._id,
                                category.sub_categories
                              )
                            }
                          />
                          <label for={category._id}>{category?.name}</label>
                        </div>
                        {/* <ul>
                          {category.sub_categories.map((subCategory) => (
                            <>
                              <input
                                type="checkbox"
                                className="form-check-input"
                                id={subCategory._id}
                                checked={checkedPermissions.includes(
                                  subCategory._id
                                )}
                                onChange={() =>
                                  handleCheckboxChange(subCategory._id)
                                }
                              />
                              <label for={subCategory._id}>
                                {subCategory?.name}
                              </label>
                            </>
                          ))}
                        </ul> */}
                        <ul className="col-lg-3 role-management w-100">
                          {category.sub_categories.map((subCategory) => (
                            <li key={subCategory._id}>
                              <input
                                type="checkbox"
                                className="form-check-input"
                                id={subCategory._id}
                                checked={checkedPermissions.includes(
                                  subCategory._id
                                )}
                                onChange={() =>
                                  handleCheckboxChange(subCategory._id)
                                }
                              />
                              <label htmlFor={subCategory._id}>
                                {subCategory.name}
                              </label>
                            </li>
                          ))}
                        </ul>
                      </div>
                    ))}
                  </div>
                </div>
                <div className="col-lg-12 mb-4 text-center">
                  <button className="thm-btn" type="submit">
                    Submit 
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default RolePermissionAdd;
