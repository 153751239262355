import React from 'react'
import { Outlet } from 'react-router-dom'
import Header from "./Component/Layout/Header";
import Sidebar from "./Component/Layout/Sidebar";
export default function Layout() {
  return (
    <>
    <Header/>
    <Sidebar/>
 
    <Outlet/>
    </>
  )
}
 