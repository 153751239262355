import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import BASE_URL, { baseUrl } from "../config/baseUrl";
import React_Quill_Editor from "../ckeditor/React_Quill_Editor";
import Swal from "sweetalert2";
import { useLocation } from 'react-router-dom';
import { type } from "@testing-library/user-event/dist/type";

const ComboOfferAddPackage = () => {

  let { id } = useParams()
  const [row, setRow] = useState([]);
  const [formval, setFormval] = useState({
    type: "",
    course_id: "",
  });
  // console.log("formValue.type",formval)
  const [imgFile, setImgFile] = useState(null);
  const [bgcImgFile, setBgcImgFile] = useState(null);
  const [videoData, setVideoData] = useState([]);
  const [courseId, setCourseId] = useState([]);

  const [rascourseId, setRasCourseId] = useState([]);
  const [testcourseId, setTestCourseId] = useState([]);
  const [liveVideoCourseId, setLiveVideoCourseId] = useState([]);
  const [videoCourseId, setVideoeCourseId] = useState([]);

  const [videoCategories, setVideoCategories] = useState([]);
  const [selectedVideoCategory, setSelectedVideoCategory] = useState("");
  const [course, setCourses] = useState([]);
  // console.log("row", row)
  useEffect(() => {
    fetchDataAllData()
  }, []);


  useEffect(() => {
    fetchCoursesByCategory();
    fetchLiveVideoCourses();
    fetchRasData();
    fetchTestSeriesCourses();
  }, []);
  const fetchDataAllData = async () => {
    try {
      const response = await axios.post(`${baseUrl}/api/combo-course-list`, {
      });
      // console.log("responsseoffer", response.data.data.data);
      let filterData = response.data.data.data.filter((arr) => arr.id == id)
      // console.log("listaayi", filterData)
      let maindata = filterData[0].course_detail
      
      // console.log("listaayi11", maindata);
      // console.log(maindata);
      setFormval(maindata[0])
      if (maindata.length > 0) {
        
        setRow(maindata || []) 
        // setRow(() => (maindata.filter((arr, i) => i !== 0)))
      }

      // setdata(response?.data?.data?.data);
    } catch (error) {
      console.log("error in fetching data", error);
    }
  };

  // live video
  const fetchLiveVideoCourses = async (url) => {
    try {
      const response = await axios.post(`${baseUrl}/api/offline-course`, {
        "type": "live", 'user_id': "22541"
      });
      // console.log("responsseoffer", response.data.data);

      setLiveVideoCourseId(response?.data?.data);
    } catch (error) {
      console.log("error in fetching data", error);
    }
  };
  // ras video
  const fetchRasData = async (url) => {
    try {
      const response = await axios.post(`https://test.qualityeducation.in/api/package/ras-getPackage`, {
        'user_id': "22541"
      });
      // console.log("rasaaa", response.data.params.packageList);

      setRasCourseId(response?.data?.params.packageList);
    } catch (error) {
      console.log("error in fetching data", error);
    }
  };

  const fetchCoursesByCategory = async () => {
    try {
      const response = await axios.get(`${baseUrl}/api/combo-get/22541/0`, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      // console.log("viedeores",response.data.data)
      setCourses(response.data.data.video || []);
    } catch (error) {
      console.log("Error fetching courses", error);
    }
  };

  const fetchTestSeriesCourses = async (url) => {
    try {
      const response = await axios.post(`https://test.qualityeducation.in/api/package/getPackage`, {
        'user_id': "22541"
      });
      // console.log("setTestCourseId", response.data.params.packageList);

      setTestCourseId(response?.data?.params.packageList);
    } catch (error) {
      console.log("error in fetching data", error);
    }
  };

  const navigate = useNavigate();
  const handleChange = (e) => {
    const { name, value } = e.target;
    // console.log("111name", name)
    setFormval((prevFormVal) => ({
      ...prevFormVal,
      [name]: value,
    }));
    // if (name === "type") {
    // setFormval((curval) => ({ ...curval, [name]: value }))
    // // fetchData("/api/offline-course")
    // }
    if (name === "type") {
      if (value === "video") {
        fetchCoursesByCategory();
      } else if (value === "ras") {
        fetchRasData();
      } else if (value === "live video") {
        fetchLiveVideoCourses();
      } else if (value === "test series") {
        fetchTestSeriesCourses();
      }
    }
    // if (name === 'course_id' || name === 'video' || name === 'test_series' || name === 'live_video_course') {
    //   setFormval((prevValues) => ({
    //     ...prevValues,
    //     [name]: value
    //   }));
    // }
    // Handle fetching courses based on category selection
    // if (name === "course_id" && formval.type === "video") {
    //   // console.log("value",value)
    //   handleCategoryChange(value);
    // }
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
     // Ensure all rows have valid type and course_id
  // const validRows = row.filter((r) => r.type && r.course_id);
  // console.log("god",validRows)
  // if (!validRows.type || !validRows.course_id) {
  //   alert("Please select a type and course ID for the main form.");
  //   return;
  // }

  // if (validRows.length === 0) {
  //   alert("Please add at least one valid row with a type and course ID.");
  //   return;
  // }
  
 
  
// return
    try {
      const formData = new FormData();
      // console.log("idbhar",id)
      formData.append("combo_course_id", id);
      formData.append("type[0]", formval?.type);
      formData.append("course_id[0]", formval?.course_id);
      row.map((arr, i) => {
        // formData.append("combo_course_id", arr.id);
        // console.log("++++++++++++", arr?.type,arr?.course_id,arr.id)
        formData.append(`type[${i}]`, arr?.type);
        formData.append(`course_id[${i}]`, arr?.course_id);
      })
     
      const response = await axios.post(
        `${baseUrl}/api/combo-course-assign-new-package`,
        formData,
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-type": "application/json",
            "Content-Type": "multipart/form-data",
          },
        }
      );
      console.log("postresponse", response);
      navigate("/comboofferlist");
    } catch (error) {
      console.log("error data posting", error);
    }
  };


  const addRow = () => {
    setRow([
      ...row,
      { type: "", course_id: "" },
    ]);
  };

  const removeRow = (index) => {
    console.log("index", index)
    const newRows = row.filter((row, i) => i !== index);
    setRow(newRows);
  };
  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    setRow((prevRows) =>
      prevRows.map((row, i) =>
        i === index ? { ...row, [name]: value } : row
      )
    );

    if (name === "type") {
      if (value === "video") {
        fetchCoursesByCategory();
      } else if (value === "ras") {
        fetchRasData();
      } else if (value === "live video") {
        fetchLiveVideoCourses();
      } else if (value === "test series") {
        fetchTestSeriesCourses();
      }
    }

  };
  const deletetestSubject = async (courseId) => {
    let response = await axios.post(`https://test.qualityeducation.in/api/combo-course-assign-to-remove`, {
      id: courseId
    });
    // console.log(response.data);

    if (response?.data?.status_code === 200) {
      setRow((prv) => {
        prv = prv.filter((arr) => arr?.id !== courseId)
        return prv
      });
    }
  }
  // update combo
  const updateCombo = async (courseId, value) => {
    console.log("courseid11",courseId)
    console.log("courseid11",value)

    if (value === "formval") {
      console.log("formVal",formval)
      console.log("courseId",courseId)
      delete formval.created_at;
      delete formval.updated_at
      console.log("formval.course_id",formval.course_id)
      let response = await axios.post(`https://test.qualityeducation.in/api/combo-course-assign-update`, {
        id: id, combo_course_id: id, course_id: formval.course_id, type: formval.type
      });
      console.log("response update11", response);
      // if (response?.data?.status_code === 200) {
      //   setFormval((prv) => {
      //     prv = prv.filter((arr) => arr?.id !== courseId)
      //     return prv
      //   });
      // }
      // alert(response.data.msg)
      fetchDataAllData()
      return
    }
    console.log("**", courseId);
    console.log("-+++", row);

    let filterdata = row.filter((arr) => arr?.id === courseId)
    console.log("aayabahi", filterdata);
    let fomdata1 = filterdata[0]
    console.log("****1", fomdata1.type)
    console.log("****11", fomdata1.course_id)
    console.log("****111", fomdata1.id)
    delete fomdata1.created_at;
    delete fomdata1.updated_at;

    let formData = new FormData();

    formData.append('combo_course_id', fomdata1.combo_course_id);
    formData.append('course_id', fomdata1.course_id);
    formData.append('type', fomdata1.type)
    formData.append('id', fomdata1.id)

    let response = await axios.post(`https://test.qualityeducation.in/api/combo-course-assign-update`, {
      id: fomdata1.id, combo_course_id: id, course_id: fomdata1.course_id, type: fomdata1.type
    });
    console.log("response data2", response);

    if (response?.data?.status_code === 200) {
      setRow((prv) => {
        prv = prv.filter((arr) => arr?.id !== courseId)
        return prv
      });
    }
  }
  const handleformdelete = () => {
    setFormval({
      type: "",
      course_id: ""
    });
  };
  return (
    <>
      <section className="main-sec">
        <div className="row">
          <div className="col-lg-12">
            <div className="dashboard-title">
              <h4 className="dash-head">
                <i className="fas fa-users me-2" />
                Add Combo Offer
              </h4>
            </div>
            <div className="custom-bredcump">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Add Combo Offer
                  </li>
                </ol>
              </nav>
            </div>
          </div>
          <div className="col-lg-12">
            <div className="cards cstm-form">
              <form action="" onSubmit={handleSubmit}>
                <div className="row">
                  <div className="row align-items-center">
                    <div className="col-12 text-end border-0">
                    <button
                      type="button"
                      className="btn btn-primary me-3"
                      onClick={addRow}
                    >
                      <i className="fas fa-plus"></i>
                    </button>
                    </div>
                    {/* <div className="mb-3 col">
                      <label htmlFor="" className="form-label">
                        Type
                      </label>
                      <select
                        name="type"
                        value={formval?.type || ''}
                        onChange={handleChange}
                        className="form-select"
                      >
                        <option defaultValue="" >Select Type</option>
                        <option value="ras" defaultValue="">ras</option>
                        <option value="test series" defaultValue="">test series</option>
                        <option value="video" defaultValue="">video</option>
                        <option value="live video" defaultValue="">live video</option>
                      </select>
                    </div> */}

                    {/* {formval?.type === "video" && (
                      <div className="mb-3 col">
                        <label htmlFor="courses" className="form-label">Course ID</label>
                        <select name="course_id" value={formval.course_id} onChange={handleChange} className="form-select">
                          <option value="">Select Course</option>
                          {course.map((course) => {
                            console.log("course", course)
                            return (<option key={course.id} value={course.id}>{course.title}</option>)
                          })}
                        </select>
                      </div>
                    )}
                    {formval?.type === "live video" && (
                      <div className="mb-3 col">
                        <label className="form-label">Live Video Course ID</label>
                        <select name="course_id" value={formval.course_id} onChange={handleChange} className="form-select">
                          <option value="">Select Course</option>
                          {liveVideoCourseId.map((course) => (
                            <option key={course.id} value={course.id}>{course.title}</option>
                          ))}
                        </select>
                      </div>
                    )}
                    {formval?.type === "ras" && (
                      <div className="mb-3 col">
                        <label className="form-label">RAS Course ID</label>
                        <select name="course_id" value={formval.course_id} onChange={handleChange} className="form-select">
                          <option value="">Select Course</option>
                          {rascourseId.map((course) => (
                            <option key={course.id} value={course.id}>{course.title}</option>
                          ))}
                        </select>
                      </div>
                    )}
           
                    {formval?.type === "test series" && (
                      <div className="mb-3 col">
                        <label className="form-label">Test Series ID</label>
                        <select name="course_id" value={formval.course_id} onChange={handleChange} className="form-select">
                          <option value="">Select Course</option>
                          {testcourseId.map((course) => (
                            <option key={course.sub_pack_id} value={course.sub_pack_id}>{course.sub_pack_title}</option>
                          ))}
                        </select>
                      </div>
                    )}
                    <div className="col ">
                      <label></label>
                     
                      <button
                        type="button"
                        className="btn btn-danger me-3"
                        onClick={handleformdelete}
                      >
                        <i className="fas fa-trash"></i>
                      </button>
                      <button
                        type="button"
                        className="btn btn-info me-3"
                        onClick={() => updateCombo(formval.id, "formval")}
                      >
                        Update
                      </button>
                    </div> */}
                  </div>
                  {
                    row?.map((row, index) => (
                      <div key={index} className="row align-items-center">
                        <div className="mb-3 col">
                          <label htmlFor="" className="form-label">
                            Type
                          </label>
                          <select
                            name="type"
                            value={row?.type || ''}
                            onChange={(e) => handleInputChange(e, index)}
                            className="form-select"
                          >
                            <option defaultValue="" >Select Type</option>
                            <option value="ras" defaultValue="" >ras</option>
                            <option value="test series" defaultValue="">test series</option>
                            <option value="video" defaultValue="">video</option>
                            <option value="live video" defaultValue="">live video</option>
                          </select>
                        </div>
                        {row?.type === "video" && (
                          <div className="mb-3 col">
                            <label htmlFor="courses" className="form-label">Course ID</label>
                            <select name="course_id" value={row.course_id} onChange={(e) => handleInputChange(e, index)} className="form-select">
                              <option value="">Select Course</option>
                              {course.map((course) => (
                                <option key={course.id} value={course.id}>{course.title}</option>
                              ))}
                            </select>
                          </div>
                        )}
                        {row.type === "ras" && (
                          <div className="mb-3 col">
                            <label className="form-label">RAS Course ID</label>
                            <select name="course_id" value={row.course_id} onChange={(e) => handleInputChange(e, index)} className="form-select">
                              <option value="">Select Course</option>
                              {rascourseId.map((course) => (
                                <option key={course.id} value={course.id}>{course.title}</option>
                              ))}
                            </select>
                          </div>
                        )}

                        {row?.type === "test series" && (
                          <div className="mb-3 col">
                            <label className="form-label">Test Series ID</label>
                            <select name="course_id" value={row.course_id} onChange={(e) => handleInputChange(e, index)} className="form-select">
                              <option value="">Select Course</option>
                              {testcourseId.map((course) => (
                                <option key={course.sub_pack_id} value={course.sub_pack_id}>{course.sub_pack_title}</option>
                              ))}
                            </select>
                          </div>
                        )}

                        {row?.type === "live video" && (
                          <div className="mb-3 col">
                            <label className="form-label">Live Video Course ID</label>
                            <select name="course_id" value={row.course_id} onChange={(e) => handleInputChange(e, index)} className="form-select">
                              <option value="">Select Course</option>
                              {liveVideoCourseId.map((course) => (
                                <option key={course.id} value={course.id}>{course.title}</option>
                              ))}
                            </select>
                          </div>
                        )}
                        <div className="col ">
                          <label></label>
                          <button
                            type="button"
                            className="btn btn-danger me-3"
                            onClick={row?.id ? () => deletetestSubject(row?.id) : () => removeRow(index)}
                          >
                            <i className="fas fa-trash"></i>
                          </button>
                          {row?.id && <button
                            type="button"
                            className="btn btn-info me-3"
                            // onClick={row?.id?() => deletetestSubject(row?.id):() => removeRow(index)}
                            // onClick={row?.id?() => deletetestSubject(row?.id):() => removeRow(index)}
                            onClick={() => updateCombo(row.id)}
                          >
                            Update
                          </button>}
                        </div>
                      </div>
                    ))
                  }
                  <div className="frm-bx mb-4 col-lg-12 mb-3 text-center">
                    <Link className="btn btn-success" to="/comboofferlist" type="submit">
                      back
                    </Link>
                    &nbsp;   &nbsp;   &nbsp;
                    <button className="thm-btn rounded-2" type="submit">
                      Add Courses
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ComboOfferAddPackage;
