import axios from "axios";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import trash from "../../assets/images/trash.png";
import BASE_URL from "../config/baseUrl";
import Swal from "sweetalert2";
const DeepLinkList = () => {
  const [deepData, setDeepData] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    fetchData();
  }, []);

  //fetch deep link api
  const fetchData = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/api/deeplink/deepLink`, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      setDeepData(response.data.data);
      // console.log("deepresp", response.data);
    } catch (error) {
      console.log("fetching deeplink error", error);
    }finally{
      setLoading(false)
    }
  };

  // delete api 
  const handleDelete = async (id) => {
    const result = await Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    });
    if (result.isConfirmed) {
      try {
        const response = await axios(`${BASE_URL}/api/deeplink/delete/${id}`, {
          method: "DELETE",
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        });
        // console.log("responsedelete",response);
        const deletedUser = await response.data;
        setDeepData(deepData.filter((deep) => deep._id !== deletedUser._id));
        Swal.fire({
          title: "Deleted!",
          text: "Your file has been deleted.",
          icon: "success",
        });
        fetchData();
      } catch (error) {
        console.error("Error deleting banner:", error);
        Swal.fire({
          title: "Error!",
          text: "There was a problem deleting the banner.",
          icon: "error",
        });
      }
    }
  };

  return (
    <>
      <section className="main-sec">
        <div className="row">
          <div className="col-lg-12">
            <div className="dashboard-title">
              <h4 className="dash-head">
                <i className="far fa-chart-bar me-2" />
                Deep Link List
              </h4>
              <Link to="#" className="blu-btn">
                <i className="far fa-plus me-2" />
                Add Deep Link
              </Link>
            </div>
            <div className="custom-bredcump">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Deep Link
                  </li>
                </ol>
              </nav>
            </div>
          </div>
          <div className="col-lg-12">
            <div className="cards bus-list">
              <div className="bus-filter">
                <div className="row justify-content-start">
                  <div className="col-lg-7">
                    <h4 className="table-head">
                      Total Deep Link ({deepData.length}){" "}
                    </h4>
                  </div>
                </div>
              </div>
              <div className="table table-responsive custom-table">
              {
                  loading ? (
                    <div className="text-center my-4">
                    <div className="spinner-border" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                  </div>
                  ):(
                <table className="table table-borderless">
                  <thead>
                    <tr>
                      <th>SR NO.</th>
                      <th>DeepLink</th>
                      <th>Type</th>
                      <th>VideoId</th>
                      {/* <th>SEQUENCE</th>
                      <th>Show Status</th> */}
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {deepData.map((curElem, index) => {
                      // console.log("imagecheck", curElem);
                      return (
                        <tr key={curElem._id}>
                          <td>{index + 1}</td>
                          <td>{curElem.deepLink}</td>
                          <td>{curElem.type}</td>
                          <td>{curElem.videoId}</td>
                          {/* <td>
                            <div className="d-flex table-box">
                              <input
                                type="number"
                                className="form-control"
                                defaultValue={curElem?.sequence}
                                // onChange={(e) =>
                                //   setUpdateSequence(e.target.value)
                                // }
                                min={0}
                              />
                              <button
                                className="btn btn-success ms-2"
                                onClick={() =>
                                  handleSequenceUpdate(curElem._id)
                                }
                              >
                                Update
                              </button>
                            </div>
                          </td> */}
                          {/* <td>
                            <div className="form-check form-switch">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                // defaultChecked={curElem.show_yes_no}
                                onChange={() => handleBannerStatus(curElem._id)}
                                checked={
                                  bannerStatus.find(
                                    (banner) => banner.id === curElem._id
                                  )?.show_yes_no
                                }
                              />
                            </div>
                          </td> */}
                          <td>
                            <button className="mx-2">
                              <img
                                src={trash}
                                alt="delete"
                                onClick={() => handleDelete(curElem._id)}
                              />
                            </button>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>)}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default DeepLinkList;
