import React, { useState ,useEffect} from "react";
// import Header from "../Layout/Header";
// import Sidebar from "../Layout/Sidebar";
import { Link, useParams } from "react-router-dom";
import BASE_URL from "../config/baseUrl";
import axios from "axios";
// import MyCkeditor from "./MyCKeditor";
import MyCkeditor from "../ckeditor/MyCKeditor";
import Tinymce from "../ckeditor/Tinymce";
import React_Quill_Editor from "../ckeditor/React_Quill_Editor";
export default function VideoCourse_details() {
  let {id}=useParams();
  let [formval,setFormval]=useState({})
  let [categoryData,setCategoryData]=useState([])

 useEffect(() => {
    const apiCall = async () => {
      let response = await axios(
        `${BASE_URL}/api/videocoursecategory/allcourses`,
        {
          method: "GET",
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        }
      );
    setCategoryData(response?.data?.data)
    };
    apiCall();
  }, []);

  
  useEffect(() => {
    const apiCall = async () => {
      let response = await fetch(`${BASE_URL}/api/videocourse/videoCoursDetails/${id}`, {
        method: "GET",
        headers: {
          "Authorization": (localStorage.getItem("token"))
        },
      });
      const result = await response.json();
      setFormval({ ...result.data })
    };
    apiCall();
  }, []);

  
  return (
    <>
      {/* <Header /> */}
      {/* <Sidebar /> */}
      <section className="main-sec">
        <div className="row">
          <div className="col-lg-12">
            <div className="dashboard-title">
              <h4 className="dash-head">
                <i className="fas fa-video me-2" />
                 Details Video Course
              </h4>
            </div>
            <div className="custom-bredcump">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/video_course_list">Video Course Management</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Video Course details
                  </li>
                </ol>
              </nav>
            </div>
          </div>
          <div className="col-lg-12">
            <div className="cards cstm-form">
              <form action="" >
                <div className="row">
                  <div className="col-lg-4 mb-4">
                    <label htmlFor="" className="form-label">
                      Course Name
                    </label>
                    <input
                      type="text"
                      name="title"
                      disabled
                      defaultValue={formval.title}
                      className="form-control"
                    />
                  </div>
                  <div className="col-lg-4 mb-4">
                    <label htmlFor="" className="form-label">
                      Category
                    </label>
                    <select name="videoCategory" value={formval?.videoCategory} disabled className="form-select">
                        <option >Select Category</option>
                        {
                            categoryData?.map((arr)=>(
                                <option key={arr?._id} value={arr._id}>{arr?.category}</option>
                            ))
                        }
                    </select>
                  </div>
                  <div className="col-lg-3 mb-4">
                    <label htmlFor="" className="form-label">
                      Price
                    </label>
                    <input
                      type="text"
                      name="price"
                      disabled
                      defaultValue={formval?.price}
                      className="form-control"
                    />
                  </div>
                  <div className="col-lg-4 mb-4">
                    <label htmlFor="" className="form-label">
                      Offer Price
                    </label>
                    <input
                      type="text"
                      name="offerPrice"
                      disabled
                      defaultValue={formval?.offerPrice}
                      className="form-control"
                    />
                  </div>
                  <div className="col-lg-4 mb-4">
                    <label htmlFor="" className="form-label">
                      Status
                    </label>
                    <select name="isActive" disabled value={formval.isActive} className="form-select">
                      <option >Select Status</option>
                      <option value={true}>Active</option>
                      <option value={false}>Inactive</option>
                    </select>
                  </div>
                  <div className="col-lg-4 mb-4">
                    <label htmlFor="" className="form-label">
                      Image
                    </label>
                    <input
                      type="file"
                      className="form-control"
                      name="images"
                     disabled
                    />
                    <div className="image-box">
                      { formval?.images?.map((arr,i)=>{
                        return (<div key={i} className="image-box-innr">
                          <img src={`${BASE_URL}/${arr?.image}`} alt="" />
                          {/* <Link to="#" >
                            <i className="fa fa-times" />
                          </Link> */}
                        </div>)
                        })}    
                    </div>
                  </div>
                  <div className="col-lg-12 mb-4">
                    <div id="editor">
                    {/* <Tinymce
                        disabled={true}
                        data={formval?.details}
                        seteditor={setFormval}
                        editorTitle={"Video Details"}
                        fieldName={"details"}
                      /> */}
                    <React_Quill_Editor
                        disabled={true}
                        data={formval?.details}
                        seteditor={setFormval}
                        editorTitle={"Video Details"}
                        fieldName={"details"}
                      />
                    {/* <MyCkeditor
                        disabled={true}
                        data={formval?.details}
                        seteditor={setFormval}
                        editorTitle={"Video Details"}
                        fieldName={"details"}
                      /> */}
                    </div>
                  </div>
                  <div className="col-lg-12 mb-4 text-center">
                    {/* <button className="thm-btn" type="submit">
                      Add Video Course
                    </button> */}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
