import React, { useState, useEffect } from "react";
// import Header from "../Layout/Header";
// import Sidebar from "../Layout/Sidebar";
import { Link, useNavigate,useParams } from "react-router-dom";
import BASE_URL from "../config/baseUrl";
import axios from "axios";
// import MyCkeditor from "../ckeditor/MyCKeditor";
import Tinymce from "../ckeditor/Tinymce";
import React_Quill_Editor from "../ckeditor/React_Quill_Editor";
import dummy from '../../assets/images/dummy.png'
export default function TestPackage_edit() {
  let navigate = useNavigate();
  const {id}=useParams();
  const today = new Date().toISOString().split('T')[0];
  let [formval, setFormval] = useState({
    sub_pack_title: "",
    sub_pack_dept: "",
    images: [],
    sub_pack_image:"" ,
    sub_pack_desc: "",
    sub_pack_price: "",
    sub_actual_price: "",
    course_validity:"",
    // isActive: true,
    sub_pack_order: "",
    sub_pack_bgimage:"",
    thumbnail:''
 
  });
  let [categoryData, setCategoryData] = useState([]);
  const [image, setImages] = useState([]);
  let [img, setImg] = useState("");

  const handelOnChange = (e) => {
    console.log( e.target.name)
    console.log( e.target.value)
    if (e.target.name == "photos") {
      let arr = [];
      let imagesArray = [];
      for (let i of e.target.files) {
        arr.push(i);
        const reader = new FileReader();
        reader.onload = (event) => {
          imagesArray.push(event.target.result);
          
          if (imagesArray.length === e.target.files.length) {
            setImages(imagesArray);
          }
        };
        reader.readAsDataURL(i);
      }
      return setFormval((currVal) => ({ ...currVal, photos:e.target.files[0] }));
    } else if(e.target.name === 'thumbnail'){
      const reader = new FileReader();
      reader.onload = () => {
        setImg(reader.result);
      };
      reader.readAsDataURL(e.target.files[0]);
      return  setFormval((currVal) => ({ ...currVal, thumbnail: e.target.files[0] }));
    }else {
     return setFormval((currVal) => ({
        ...currVal,
        [e.target.name]: e.target.value,
      }));
    }
    
  };

  useEffect(() => {
    const apiCall = async () => {
      let response = await axios(
        `${BASE_URL}/api/testcategory/alldata`,
        {
          method: "GET",
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        }
      );
      // console.log(response?.data?.data)
      let filterData = response?.data?.data;
      filterData = filterData?.filter((arr) => arr?.exam_cat_status === 1);
      setCategoryData(filterData);
    };
    apiCall();
    const apiCall2 = async () => {
      let response = await axios(
        `${BASE_URL}/api/testpackege/details/${id}`,
        {
          method: "GET",
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        }
      );
      // console.log(response?.data)
        setFormval(response?.data?.data);
      
    };
    apiCall2();
  }, []);

 
 

  const handelOnSubmit = (e) => {
    e.preventDefault();
    console.log(formval)
    if (
      // !formval.title ||
      // !formval.testcategory ||
      // !formval.price ||
      // !formval.offerPrice ||
      // !formval.images ||
      // !formval.description||
      !formval.course_validity
    ) {
      alert("Please Fill All Field");
      return;
    }
    const postData = new FormData();
    // formval?.photos?.map((arr) => {
    //   postData.append("images", arr);
    // }); 
    console.log(formval)
    // return
    // return

    // postData.append("sub_pack_image", formval.images[0]);
    // postData.append("sub_pack_title", formval.title);
    // postData.append("sub_pack_dept", formval.testcategory);
    // postData.append("sub_pack_price", formval.price);
    // postData.append("sub_actual_price", formval.offerPrice);
    // postData.append("sub_pack_desc", formval.description);
    // postData.append("course_validity", formval.course_validity);
    // postData.append("sub_pack_bgimage", formval.thumbnail);
    // return
    postData.append("sub_pack_image", formval?.photos);
    postData.append("sub_pack_title", formval.sub_pack_title);
    postData.append("sub_pack_dept", formval.sub_pack_dept);
    // postData.append("sub_actual_price", formval.price);
    postData.append("sub_pack_price", formval.sub_pack_price);
    postData.append("sub_actual_price", formval.sub_actual_price);
    postData.append("sub_pack_desc", formval.sub_pack_desc);
    postData.append("course_validity", formval.course_validity);
    postData.append("sub_pack_order", formval.sub_pack_order);
    postData.append("sub_pack_bgimage", formval.thumbnail);
    // postData.append("sub_pack_status", formval.issub_pack_statusActive);


    const apiCall = async () => {
        let response = await axios(`${BASE_URL}/api/testpackege/edit/${id}`, {
        method: "POST",
        headers: {
          Authorization: localStorage.getItem("token"),
        },
        data: postData,
      });
      console.log(response.data)
      if (!response?.data?.status) {
        alert("Some Error Accoured");
        console.log(response)
        return;
      }
      navigate("/test_package_list");
    };
    apiCall();
  };

  const handleImgDelete = (imgid) => {
    const apiCall = async () => {
      let response = await axios(
        `${BASE_URL}/api/testpackege/${id}/deleteimage/${imgid}`,
        {
          method: "POST",
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        }
      );
      setFormval({ ...response?.data?.data });
    };
    apiCall();
  };

  return (
    <>
      {/* <Header /> */}
      {/* <Sidebar /> */}
      <section className="main-sec">
        <div className="row">
          <div className="col-lg-12">
            <div className="dashboard-title">
              <h4 className="dash-head">
                <i className="fa-solid fa-book  me-2" />
                Edit Test Package
              </h4>
            </div>
            <div className="custom-bredcump">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/test_list">Test</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Edit Test Package
                  </li>
                </ol>
              </nav>
            </div>
          </div>
          <div className="col-lg-12">
            <div className="cards cstm-form">
              <form onSubmit={handelOnSubmit}>
                <div className="row">
                  <div className="mb-3 col-lg-4">
                    <label className="form-label">Select Category</label>
                    <select
                      className="form-select"
                      name="sub_pack_dept"
                      onChange={handelOnChange}
                      value={formval?.sub_pack_dept}
                    >
                      <option>Select Category</option>
                      {categoryData?.map((arr) => (
                        <option key={arr?._id} value={arr?.exam_cat_id}>{arr?.exam_cat_name}</option>
                      ))}
                    </select>
                  </div>
                  <div className="mb-3 col-lg-4">
                    <label className="form-label">Title</label>
                    <input
                      type="text"
                      className="form-control"
                      name="sub_pack_title"
                      onChange={handelOnChange}
                      value={formval?.sub_pack_title}
                    />
                  </div>
                  <div className="mb-3 col-lg-4">
                    <label className="form-label">Price</label>
                    <input
                      type="number"
                      className="form-control"
                      name="sub_actual_price"
                      onChange={handelOnChange}
                      value={formval?.sub_actual_price}
                    />
                  </div>
                  <div className="mb-3 col-lg-4">
                    <label className="form-label">Offer Price</label>
                    <input
                      type="number"
                      className="form-control"
                      name="sub_pack_price"
                      onChange={handelOnChange}
                      value={formval?.sub_pack_price}
                    />
                  </div>
                   <div className="mb-3 col-lg-4">
                    <label className="form-label">validity</label>
                    <input
                      type="date"
                      className="form-control"
                      // min={today}
                      name="course_validity"
                      // onChange={handelOnChange}
                      // value={formval?.course_validity?.split('T')[0]}
                      onChange={handelOnChange}
                      value={formval?.course_validity?.split("T")[0]}
                      min={today}
                    />
                  </div>

                  <div className="mb-3 col-lg-4">
                    <label className="form-label">Image</label>
                    <input
                      type="file"
                      className="form-control"
                      name="photos"
                      onChange={handelOnChange}
                    />
                    
                     <div className="image-box">
                      {formval?.images?.map((arr, i) => {
                        return (
                          <div key={i} className="image-box-innr">
                            <img src={`${BASE_URL}/${arr?.image}`} alt="" onError={(e)=>e.target.src=dummy} />
                            <Link
                              to="#"
                              onClick={() => handleImgDelete(arr._id)}
                            >
                              <i className="fa fa-times" />
                            </Link>
                          </div>
                        );
                      })}
                      {formval?.photos && image?.map((imageUrl, index) => (
                        <div
                          key={index}
                          className="image-box-innr"
                        >
                          <img
                            key={index}
                            src={imageUrl}
                            alt={`Image ${index}`}
                          />
                        </div>
                      ))}
                    </div>
                  </div>


                  <div className="mb-3 col-lg-4">
                    <label className="form-label">Thumbnail</label>
                    <input
                      type="file"
                      className="form-control"
                      name="thumbnail"
                      onChange={handelOnChange}
                    />
                    <div className="image-box">
                    <div className="image-box-innr">
                         {formval?.thumbnail && < img  src={img || `${BASE_URL}/${formval?.thumbnail}`} alt={`thumbnail`} />}
                        </div>
                    </div>
                  </div>


                  <div className="mb-3 col-lg-12">
                      <React_Quill_Editor
                        disabled={false}
                        data={formval?.sub_pack_desc}
                        seteditor={setFormval}
                        fieldName={"sub_pack_desc"}
                        editorTitle={"Description"}
                      />
                      
                  </div>

                  <div className="mb-3 col-lg-12 text-center">
                    <button type="submit" className="thm-btn w-25">
                      Submit
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
