import React, { useState, useEffect } from "react";
// import Header from "../Layout/Header";
// import Sidebar from "../Layout/Sidebar";
import { Link } from "react-router-dom";
// import edit from "../../assets/images/edit.png";
// import trash from "../../assets/images/trash.png";
import axios from "axios";
import BASE_URL from "../config/baseUrl";
import edit from "../../assets/images/edit.png";
import trash from "../../assets/images/trash.png";
import Swal from "sweetalert2";
function Ras_Package_list() {
  let [data, setData] = useState([]);
  // let [searchCategoryName ,setSearchCategoryName]=useState("");
  let [pageNumber, setPageNumber] = useState(1)
  const [showPagination, setShowPagination] = useState(true);
  let [rasPackageCount, setRasPackageCount] = useState([]);
  let [showIndex, setShowIndex] = useState(false);

  // let [updateSequence, setUpdateSequence] = useState();
  let [searchRasPackage, setSearchRasPackage] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    apicall();
  }, [pageNumber]);
  let apicall = async () => {
    try {
      let response = await axios(`${BASE_URL}/api/raspackage/alldata?p=${pageNumber}`, {
        method: "GET",
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      if (!(response?.data?.status) && response.status === 200) {
        setPageNumber(pageNumber - 1)
        return alert("Page Data Not Found ")
      }
      setData(response?.data?.data?.allRasPackageData);
      setRasPackageCount(response?.data?.data?.RasPackageCount);
      // {allRasPackageData,RasPackageCount}
    } catch (error) {
      console.log("error in fetching", error)
    } finally {
      setLoading(false)
    }
  };
  const handleStatus = (id) => {
    let apiCall = async () => {
      await axios(`${BASE_URL}/api/raspackage/active/${id}`, {
        method: "PUT",
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
    };
    apiCall();
  };


  const handleDelete = (id) => {
    let apiCall = async () => {
      let response = await axios(`${BASE_URL}/api/raspackage/delete/${id}`, {
        method: "DELETE",
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      let deletedUser = response?.data?.data;
      console.log(response?.data?.data)
      setData(data.filter((arr) => arr?._id !== deletedUser?._id));
      setRasPackageCount((cur) => cur - 1)
    };
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        apiCall();
        Swal.fire({
          title: "Deleted!",
          text: "Test has been deleted.",
          icon: "success",
        });
      }
    });
  };

  const searchHandle = async (e) => {
    e.preventDefault();
    setShowIndex(true)
    setShowPagination(false)
    let response = await axios(
      `${BASE_URL}/api/raspackage/search?rasTitle=${searchRasPackage}`,
      // `${BASE_URL}/api/testcategory/search?category=${searchCategoryName}`,
      {
        method: "GET",
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }
    );
    if (!response?.data?.status) {
      alert(response?.data?.message);
      return
    } else if (response?.data?.data?.length === 0) {
      alert("Test Category Not Find")
    } else {
      setData(response?.data?.data);
    }
  };
  //   let response = await axios(
  //     `${BASE_URL}/api/test/update_sequence/${e}`,
  //     {
  //       method: "PUT",
  //       headers: {
  //         Authorization: localStorage.getItem("token"),
  //       },
  //       data: { sequence: updateSequence },
  //     }
  //   );
  //   // console.log(response);
  //   setData(response?.data?.data);
  // };


  // const searchHandle = async (e) => { 
  //   e.preventDefault();
  //   let response = await axios(
  //     `${BASE_URL}/api/test/alldata?title=${searchTestName}`,
  //     {
  //       method: "GET",
  //       headers: {
  //         Authorization: localStorage.getItem("token"),
  //       },
  //     }
  //   );
  //   if (!response?.data?.status) {
  //     alert(response?.data?.message);
  //     return
  //   } else if(response?.data?.data?.length == 0){
  //       alert("Test Not Find")
  //   }else{
  //     setData(response?.data?.data);
  //   }
  // };


  return (
    <>
      {/* <Header /> */}
      {/* <Sidebar /> */}
      <section className="main-sec">
        <div className="row">
          <div className="col-lg-12">
            <div className="dashboard-title">
              <h4 className="dash-head">
                <i className="fa-brands fa-readme  me-2" />
                Ras Package list
              </h4>
              <Link className="blu-btn" to="/ras_package_add">
                Ras Package Add
              </Link>
            </div>
            <div className="custom-bredcump">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Ras Package list
                  </li>
                </ol>
              </nav>
            </div>
          </div>

          <div className="col-lg-12">
            <div className="cards bus-list">
              <div className="bus-filter">
                <div className="row">
                  <div className="col-lg-7">
                    <h4 className="table-head">Ras Package list  ({rasPackageCount})</h4>
                  </div>
                  <div className="col-lg-5">
                    <form action="" onSubmit={searchHandle}>
                      <div className="row justify-content-end gx-2 align-items-center">
                        <div className="col-lg-6">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Search Ras Package..."
                            onChange={(e) => setSearchRasPackage(e.target.value)}
                          />
                        </div>
                        <div className="col-lg-2">
                          <button type="submit" className="blu-btn w-100">
                            Search
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div className="table table-responsive custom-table">
              {
                  loading ? (
                    <div className="text-center my-4">
                      <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                      </div>
                    </div>
                  ) : (
                <table className="table table-borderless">
                  <thead>
                    <tr>
                      <th>SR NO.</th>
                      <th>Title</th>
                      <th>actual_price</th>
                      <th>discount_price</th>
                      <th>no_of_test</th>
                      {/* <th>sub_pack_status </th> */}
                      <th>status </th>
                      <th>total_pdf </th>
                      <th>validity</th>
                      <th>Action</th>

                    </tr>
                  </thead>
                  <tbody>
                    {data?.map((arr, i) => (
                      <tr key={arr?._id}>
                        <td>{++i}</td>
                        <td>{arr?.title}</td>
                        <td>{arr?.actual_price}</td>
                        <td>{arr?.discount_price}</td>
                        <td>{arr?.no_of_test}
                          <Link
                            className="btn btn-sm btn-outline-dark ms-2"
                            to={`/Ras_Package_Assign_Test_List/${arr._id}`}>
                            Test List
                          </Link>
                        </td>
                        {/* <td className="text-center"> {arr?.sub_pack_status}
                        </td> */}
                        <td>
                          <div className="form-check form-switch">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              defaultChecked={arr?.sub_pack_status === "0" ? false : true}
                              onClick={() => handleStatus(arr._id)}
                            />
                          </div>
                        </td>
                        <td>{arr?.total_pdf}</td>
                        <td>{arr?.validity?.split("T")[0]}</td>
                        <td>
                          <Link className="mx-1" to={`/ras_package_edit/${arr?._id}`}>
                            <img src={edit} alt="" />
                          </Link>
                          <Link
                            className="mx-1"
                            onClick={() => handleDelete(arr?._id)}
                          >
                            <img src={trash} alt="" />
                          </Link>
                        </td>
                      </tr>
                    ))}


                  </tbody>
                </table>)}
                {showPagination && (
                  <div className="custom-pagination">
                    <ul className="pagination">
                      <li className="page-item">
                        <button
                          className="page-link"
                          to=""
                          aria-label="Previous"
                          disabled={pageNumber === 1}
                          onClick={() => setPageNumber((prev) => prev - 1)}
                        >
                          <span aria-hidden="true">«</span>
                        </button>
                      </li>
                      {pageNumber >= 2 && (
                        <li className="page-item">
                          <Link className="page-link" to="">
                            {pageNumber - 1}
                          </Link>
                        </li>
                      )}
                      <li className="page-item">
                        <Link className="page-link active" to="">
                          {pageNumber}
                        </Link>
                      </li>
                      <li className="page-item">
                        <Link
                          className="page-link"
                          to=""
                          onClick={() => setPageNumber((prev) => prev + 1)}
                        >
                          {pageNumber + 1}
                        </Link>
                      </li>
                      <li className="page-item">
                        <Link
                          className="page-link"
                          to=""
                          onClick={() => setPageNumber((prev) => prev + 2)}
                        >
                          {pageNumber + 2}
                        </Link>
                      </li>
                      <li className="page-item">
                        <Link
                          className="page-link"
                          to=""
                          aria-label="Next"
                          onClick={() => setPageNumber((prev) => prev + 1)}
                        >
                          <span aria-hidden="true">»</span>
                        </Link>
                      </li>
                    </ul>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Ras_Package_list;
