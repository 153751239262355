import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import BASE_URL from "../config/baseUrl";

const PermissionEdit = () => {
    const { id } = useParams();
  const [values, setValues] = useState({
    name: "",
  });
  useEffect(() => {
    fetchsinglePermission();
  }, []);
  const navigate = useNavigate();
  const fetchsinglePermission = async () => {
    try {
      const response = await axios(`${BASE_URL}/api/permissioncategory/permission-category-get/${id}`, {
        method: "GET",
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      console.log("response", response);
      setValues(response.data.data);
    } catch (error) {
      console.error("Error fetching role data:", error);
    }
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.put(`${BASE_URL}/api/permissioncategory/permission-category-update/${id}`, values, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      navigate("/permissioncatlist");
    } catch (error) {
      console.log("error in editing role", error);
    }
  };
  return (
    <>
     <section className="main-sec">
        <div className="row">
          <div className="col-lg-12">
            <div className="dashboard-title">
              <h4 className="dash-head">
                <i className="fas fa-video me-2" />
                Edit Permission
              </h4>
            </div>
            <div className="custom-bredcump">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link>Permission</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Edit Permission
                  </li>
                </ol>
              </nav>
            </div>
          </div>
          <div className="col-lg-12">
            <div className="cards cstm-form">
              <form action="" onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-lg-6 mb-4">
                    <label htmlFor="" className="form-label">
                      Name
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder=""
                      name="name"
                      value={values.name}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="col-lg-12 mt-5 mb-5 text-center">
                  <button className="thm-btn" type="submit">
                    Submit Permission
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default PermissionEdit