import React, { useState, useEffect } from "react";
// import Header from "../Layout/Header";
// import Sidebar from "../Layout/Sidebar";
import { Link, useParams, useNavigate } from "react-router-dom";
import BASE_URL from "../config/baseUrl";
import axios from "axios";
// import MyCkeditor from "./MyCKeditor";s
import MyCkeditor from "../ckeditor/MyCKeditor";
import Tinymce from "../ckeditor/Tinymce";
import React_Quill_Editor from "../ckeditor/React_Quill_Editor";
// import Avtar from "../../assets/images/avtar.jpg";

// let arr = []
// let imagesArray = []
// for (let i of e.target.files) {
//   arr.push(i)
//   const reader = new FileReader();
//   reader.onload = (event) => {
//     imagesArray.push(event.target.result);
//     if (imagesArray.length === (e.target.files).length) {
//       setImages(imagesArray);
//     }
//   };
//   reader.readAsDataURL(i);
// }
export default function VideoCourse_edit() {
  let navigate = useNavigate();
  let { id } = useParams();
  let [formval, setFormval] = useState({
    title: "",
    videoCategory: "",
    photos: [],
    details: "",
    price: "",
    offerPrice: "",
    isActive:true
  });
  const [image, setImages] = useState([]);
  let [categoryData, setCategoryData] = useState([]);

  const handelOnChange = (e) => {
    if (e.target.name == "photos") {
      let arr = [];
      let imagesArray = [];
      for (let i of e.target.files) {
        arr.push(i);
        const reader = new FileReader();
        reader.onload = (event) => {
          imagesArray.push(event.target.result);
          if (imagesArray.length === e.target.files.length) {
            setImages(imagesArray);
          }
        };
        reader.readAsDataURL(i);
      }
      setFormval((currVal) => ({ ...currVal, photos: arr }));
    } else {
      setFormval((currVal) => ({
        ...currVal,
        [e.target.name]: e.target.value,
      }));
    }
  };

  useEffect(() => {
    (async () => {
      let response = await axios(
        `${BASE_URL}/api/videocoursecategory/allcourses`,
        {
          method: "GET",
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        }
      );
      let filterData = response?.data?.data
      filterData = filterData.filter((arr) => arr.isActive === true);
      setCategoryData(filterData)
      // setCategoryData(response?.data?.data);
    })();
  }, []);

  useEffect(() => {
    const apiCall = async () => {
      let response = await axios(
        `${BASE_URL}/api/videocourse/videoCoursDetails/${id}`,
        {
          method: "GET",
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        }
      );
      setFormval(response?.data?.data);
    };
    apiCall();
  }, []);

  const handelOnSubmit = (e) => {
    e.preventDefault();
    // console.log(formval)
    // return
    const postData = new FormData();
    formval?.photos?.map((arr) => {
      postData.append("photos", arr);
    });

    postData.append("title", formval.title);
    postData.append("videoCategory", formval.videoCategory);
    postData.append("details", formval.details);
    postData.append("price", formval.price);
    postData.append("offerPrice", formval.offerPrice);
    postData.append("isActive", formval.isActive);
    const apiCall = async () => {
      let response = await axios(`${BASE_URL}/api/videocourse/edit/${id}`, {
        method: "POST",
        headers: {
          Authorization: localStorage.getItem("token"),
        },
        data: postData,
      });
      navigate("/video_course_list");
    };
    apiCall();
  };

  const handleImgDelete = (imgid) => {
    const apiCall = async () => {
      let response = await axios(
        `${BASE_URL}/api/videocourse/${id}/deleteimage/${imgid}`,
        {
          method: "POST",
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        }
      );
      // const result = await response.json();
      // console.log(response?.data?.data);
      // console.log(response?.data);
      setFormval({ ...response?.data?.data });
    };
    apiCall();
  };


  return (
    <>
      <section className="main-sec">
        <div className="row">
          <div className="col-lg-12">
            <div className="dashboard-title">
              <h4 className="dash-head">
                <i className="fas fa-video me-2" />
                 Video Course Edit
              </h4>
            </div>
            <div className="custom-bredcump">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/video_course_list">Video Course Management</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                   Video Course Edit
                  </li>
                </ol>
              </nav>
            </div>
          </div>
          <div className="col-lg-12">
            <div className="cards cstm-form">
              <form action="" onSubmit={handelOnSubmit}>
                <div className="row">
                  <div className="col-lg-4 mb-4">
                    <label htmlFor="" className="form-label">
                      Course Name
                    </label>
                    <input
                      type="text"
                      placeholder="Video Course Name"
                      name="title"
                      onChange={handelOnChange}
                      value={formval.title}
                      className="form-control"
                    />
                  </div>
                  <div className="col-lg-4 mb-4">
                    <label htmlFor="" className="form-label">
                      Category
                    </label>
                    <select
                      name="videoCategory"
                      onChange={handelOnChange}
                      className="form-select"
                      value={formval?.videoCategory}
                    >
                      <option defaultValue>Select Category</option>
                      {categoryData?.map((arr) => (
                        <option key={arr?._id} value={arr._id}>
                          {arr?.category}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="col-lg-4 mb-4">
                    <label htmlFor="" className="form-label">
                      Price
                    </label>
                    <input
                      type="number"
                      name="price"
                      placeholder="Price"
                      onChange={handelOnChange}
                      value={formval?.price}
                      className="form-control"
                      min={0}
                    />
                  </div>
                  <div className="col-lg-4 mb-4">
                    <label htmlFor="" className="form-label">
                      Offer Price
                    </label>
                    <input
                      type="number"
                      name="offerPrice"
                      placeholder="Offer Price"
                      onChange={handelOnChange}
                      value={formval?.offerPrice}
                      className="form-control"
                      min={0}
                    />
                  </div>
                  <div className="col-lg-4 mb-4">
                    <label className="form-label">Status</label>
                    <select
                      name="isActive"
                      onChange={handelOnChange}
                      value={formval.isActive}
                      className="form-select"
                    >
                      <option>Select Status</option>
                      <option value={true}>Active</option>
                      <option value={false}>Inactive</option>
                    </select>
                  </div>
                  <div className="col-lg-4 mb-4">
                    <label htmlFor="" className="form-label">
                      Image
                    </label>
                    <input
                      type="file"
                      className="form-control"
                      name="photos"
                      onChange={handelOnChange}
                      multiple
                    />
                    <div className="image-box">
                      {formval?.images?.map((arr, i) => {
                        return (
                          <div key={i} className="image-box-innr">
                            <img src={`${BASE_URL}/${arr?.image}`} alt="" />
                            <Link
                              to="#"
                              onClick={() => handleImgDelete(arr._id)}
                            >
                              <i className="fa fa-times" />
                            </Link>
                          </div>
                        );
                      })}
                      {image.map((imageUrl, index) => (
                        <div
                          key={index}
                          className="image-box-innr"
                        >
                          <img
                            key={index}
                            src={imageUrl}
                            alt={`Image ${index}`}
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="col-lg-12 mb-4">
                    <div id="editor">
                      {/* <MyCkeditor
                        disabled={false}
                        data={formval?.details}
                        seteditor={setFormval}
                        editorTitle={"Video Details"}
                        fieldName={"details"}
                      /> */}
                      {/* <Tinymce  disabled={false}
                        data={formval?.details}
                        seteditor={setFormval}
                        editorTitle={"Video Details"}
                        fieldName={"details"}/>  */}


                        <React_Quill_Editor disabled={false}
                        data={formval?.details}
                        seteditor={setFormval}
                        editorTitle={"Video Details"}
                        fieldName={"details"} />
                        
                    </div>
                  </div>

                  <div className="col-lg-12 mb-4 text-center">
                    <button className="thm-btn" type="submit">
                    Submit
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
