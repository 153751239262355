import React, { useState, useEffect } from "react";
// import Header from "../Layout/Header";
// import Sidebar from "../Layout/Sidebar";
import { Link, useNavigate } from "react-router-dom";
import BASE_URL from "../config/baseUrl";
import axios from "axios";
import MyCkeditor from "../ckeditor/MyCKeditor";
import Tinymce from "../ckeditor/Tinymce";
export default function Ras_Package_Test_add() {
  let navigate = useNavigate();
  let [formval, setFormval] = useState({
    packageId:"",
    title:"",
    test_order:"",
    total_marks:"",
    sylebus_pdf:"",
    admin_question_pdf:"",
    result_date:"",
    test_date:"",
    description:"",
    result_announced:"",
    test_status:"",
    test_duration:"",
    answer_pdf_opotion_for_user:"",
    admin_ans_pdf:"",
   
  });

  let [img, setImg] = useState("");
  let [rasPackges,setRasPackges]=useState([])

  const handelOnChange = (e) => {
    console.log()
    if (e.target.type === "file") {
      const reader = new FileReader();
      reader.onload = () => {
        setImg(reader.result);
      };
      reader.readAsDataURL(e?.target?.files[0]);
      setFormval((currVal) => ({ ...currVal, [e.target.name]: e.target.files[0] }));
    } else {
      setFormval((currVal) => ({
        ...currVal,
        [e.target.name]: e.target.value,
      }));
    }
  }; 

  useEffect(() => {
    let RasPackge = async () => {
      let response = await axios(`${BASE_URL}/api/raspackage/alldata`, {
        method: "GET",
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      // console.log(response?.data?.data) 
      setRasPackges(response?.data?.data)
      // setFormval(response?.data?.data?.userData);
      // setRow(response?.data?.data?.subjectDetails)
      // setSubjectCount(response?.data?.data?.subjectCount)
    };
    RasPackge();


  }, []);

  const handelOnSubmit = (e) => {
    e.preventDefault();
    console.log(formval)
    // return
    if (
         !formval.packageId  
      || !formval.title  
      || !formval.test_order  
      || !formval.total_marks  
      || !formval.sylebus_pdf  
      || !formval.admin_question_pdf  
      || !formval.result_date  
      || !formval.test_date  
      || !formval.description  
      || !formval.result_announced  
      || !formval.test_status  
      || !formval.test_duration    
      || !formval.answer_pdf_opotion_for_user  
      || !formval.admin_ans_pdf  
 
      ) {
      alert("please fill all field");
      return;
    }
    // return
    const postData = new FormData();

    postData.append("sylebus_pdf", formval.sylebus_pdf);
    postData.append("admin_ans_pdf", formval.admin_ans_pdf);
    postData.append("admin_question_pdf", formval.admin_question_pdf);
    postData.append("packageId", formval.packageId);
    postData.append("title", formval.title);
    postData.append("test_order", formval.test_order);
    postData.append("total_marks", formval.total_marks);
    postData.append("result_date", formval.result_date);
    postData.append("test_date", formval.test_date);
   
    postData.append("description", formval.description);
    postData.append("result_announced", formval.result_announced);

    postData.append("test_status", formval.test_status);
    postData.append("test_duration", formval.test_duration);
    postData.append("answer_pdf_opotion_for_user", formval.answer_pdf_opotion_for_user);
   

    const apiCall = async () => {
      let response = await axios(`http://101.53.148.75:8080/api/raspackagetest/add`, {
        method: "POST",
        headers: {
          Authorization: localStorage.getItem("token"),
        },
        data: postData,
      });
      console.log(response?.data);
      if (!response?.data?.status) {
        alert("some error accoured");
        return;
      }
      navigate("/ras_package_test_list");
    };
    apiCall();
  };

  const today = new Date().toISOString().split("T")[0];

  return (
    <>
      {/* <Header /> */}
      {/* <Sidebar /> */}
      <section className="main-sec">
        <div className="row">
          <div className="col-lg-12">
            <div className="dashboard-title">
              <h4 className="dash-head">
                <i className="fa-solid fa-book  me-2" />
                Add Ras Package Test
              </h4>
            </div>
            <div className="custom-bredcump">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/ras_package_test_list">Ras Package Test</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Add Ras Package Test
                  </li>
                </ol>
              </nav>
            </div>
          </div>
          <div className="col-lg-12">
            <div className="cards cstm-form">
              <form onSubmit={handelOnSubmit}>
                <div className="row">
                 
                  <div className="mb-3 col-lg-3">
                    <label className="form-label">Title</label>
                    <input
                      type="text"
                      className="form-control"
                      name="title"
                      placeholder="Title"
                      onChange={handelOnChange}
                      value={formval.title}
                    />
                  </div>

                  <div className="mb-3 col-lg-3">
                    <label className="form-label">Total Marks</label>
                    <input
                      type="number"
                      className="form-control"
                      name="total_marks"
                      onChange={handelOnChange}
                      value={formval.total_marks}
                      min={0}
                    />
                  </div>

                  <div className="mb-3 col-lg-3">
                    <label className="form-label">Test Duration (In Minites)</label>
                    <input
                      type="text"
                      className="form-control"
                      name="test_duration"
                      onChange={handelOnChange}
                      value={formval.test_duration}
                    />
                  </div>

                  <div className="mb-3 col-lg-3">
                    <label className="form-label">Ras Test Package</label>
                    <select
                      className="form-select"
                      name="packageId"
                      onChange={handelOnChange}
                      defaultValue={formval?.packageId}>
                      <option>Select Ras Test Package</option>
                      {
                        rasPackges.map((arr)=> <option key={arr._id} value={arr._id}>{arr.title}</option>)
                      }
                    </select>
                  </div>

                  <div className="mb-3 col-lg-3">
                    <label className="form-label">Test Announcememt Date</label>
                    <input
                      type="date"
                      className="form-control"
                      name="test_date"
                      onChange={handelOnChange}
                      value={formval.test_date}
                      min={today}
                    />
                  </div>

                  <div className="mb-3 col-lg-3">
                    <label className="form-label">Test Result Date</label>
                    <input
                      type="date"
                      className="form-control"
                      name="result_date"
                      onChange={handelOnChange}
                      value={formval.result_date}
                      min={today}
                    />
                  </div>

                  <div className="mb-3 col-lg-3">
                    <label className="form-label">Test Order</label>
                    <input
                      type="number"
                      className="form-control"
                      name="test_order"
                      onChange={handelOnChange}
                      value={formval.test_order}
                      min={0}
                    />
                  </div>
                  

                  
                  <div className="mb-3 col-lg-3">
                    <label className="form-label">answer pdf opotion for_user</label>
                    <select
                      className="form-select"
                      name="answer_pdf_opotion_for_user"
                      onChange={handelOnChange}
                      defaultValue={formval?.answer_pdf_opotion_for_user}>
                      <option >Select</option>
                      <option value={"yes"}>yes</option>
                      <option value={"no"}>no</option>
                    </select>
                  </div>

                 
                  <div className="mb-3 col-lg-3">
                    <label className="form-label">Test Status</label>
                    <select
                      className="form-select"
                      name="test_status"
                      onChange={handelOnChange}
                      defaultValue={formval?.test_status}>
                      <option >Select</option>
                      <option value={1}>active</option>
                      <option value={0}>InActive</option>
                    </select>
                  </div>

                  <div className="mb-3 col-lg-3">
                    <label className="form-label">Result Announced</label>
                    <input
                      type="text"
                      className="form-control"
                      name="result_announced"
                      onChange={handelOnChange}
                      value={formval.result_announced}
                    />
                  </div>

                  <div className="col-lg-3 mb-4">
                    <label htmlFor="" className="form-label">
                    syllabus pdf
                    </label>
                    <input
                      type="file"
                      name="sylebus_pdf"
                      onChange={handelOnChange}
                      // value={formval.sylebus_pdf}
                      className="form-control"
                       accept=".pdf"
                    />
                  </div>

                  <div className="col-lg-3 mb-4">
                    <label htmlFor="" className="form-label">
                       Admin Question Pdf
                    </label>
                    <input
                      type="file"
                      name="admin_question_pdf"
                      onChange={handelOnChange}
                      // value={formval.admin_question_pdf}
                      className="form-control"
                       accept=".pdf"
                    />
                  </div>
                  <div className="col-lg-3 mb-4">
                    <label htmlFor="" className="form-label">
                    Admin Ans Pdf
                    </label>
                    <input
                      type="file"
                      name="admin_ans_pdf"
                      onChange={handelOnChange}
                      // value={formval.admin_ans_pdf}
                      className="form-control"
                       accept=".pdf"
                    />
                  </div>

                  

                  {/* <div className="mb-3 col-lg-4">
                    <label className="form-label">Image</label>
                    <input
                      type="file"
                      className="form-control"
                      name="images"
                      onChange={handelOnChange}
                      //   value={formval.images}
                      multiple
                    />

                    <div className="image-box">
                      {image.map((imageUrl, index) => (
                        <div className="image-box-innr" key={index}>
                          <img src={imageUrl} alt={`Image ${index}`} />
                          <Link to="#" onClick={() => handleImgDelete(index)}>
                            <i className="fa fa-times" />
                          </Link>
                        </div>
                      ))}
                    </div>
                  </div> */}

                  {/* <div className="mb-3 col-lg-3">
                    <label className="form-label">Photo</label>
                    <input
                      type="file"
                      className="form-control"
                      name="photo"
                      onChange={handelOnChange}
                    />
                    <div className="image-box">
                      <div className="image-box-innr">
                        {img && <img src={img} alt={`photo`} />}
                      </div>
                    </div>
                  </div> */}

                  <div className="mb-3 col-lg-12">
                    <Tinymce
                      disabled={false}
                      data={formval?.description}
                      seteditor={setFormval}
                      fieldName={"description"}
                      editorTitle={"Description"}
                    />
                  </div>

                  <div className="mb-3 col-lg-12 text-center">
                    <button type="submit" className="thm-btn w-25">
                      Submit
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
