import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import BASE_URL from "../config/baseUrl";

const PermissionAdd = () => {
  let navigate = useNavigate();
  let [formval, setFormval] = useState({
    name: "",
  });

  const handlepostPermission = async (e) => {
    e.preventDefault();
    const response = await axios.post(
      `${BASE_URL}/api/permissioncategory/permission-category-create`,formval,
      {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }
    );
    // console.log("123",response?.data)
    // if (!response?.data?.status) {
    //   alert("some error accoured");
    //   return;
    // }
    navigate("/permissioncatlist");
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormval({ ...formval, [name]: value });
  };
  return (
    <>
      <section className="main-sec">
        <div className="row">
          <div className="col-lg-12">
            <div className="dashboard-title">
              <h4 className="dash-head">
                <i className="fa-solid fa-book  me-2" />
                Add Permission Category
              </h4>
            </div>
            <div className="custom-bredcump">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/permissioncatlist">Test</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Add Permission Category
                  </li>
                </ol>
              </nav>
            </div>
          </div>
          <div className="col-lg-12">
            <div className="cards cstm-form">
            <form onSubmit={handlepostPermission}>
                <div className="row">
                  <div className="col-lg-6 mb-4">
                    <label htmlFor="" className="form-label">
                      Name
                    </label>
                    <input
                      type="text"
                      name="name"
                      className="form-control"
                      placeholder="Enter Permission Name"
                      value={formval.name}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="col-lg-12 mb-4 text-center">
                  <button className="thm-btn" type="submit">
                    Submit Permission
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default PermissionAdd;
