import React, { useState, useEffect } from "react";
import Loginimg from "../../assets/images/login-img.png";
import logo from "../../assets/images/login-logo.png";
import axios from "axios";
import { useNavigate } from "react-router-dom";
// import { ToastMessgae } from "../utils/toast";
import BASE_URL from "../config/baseUrl";
export default function Login() {
  let navigate = useNavigate();
  let token = localStorage.getItem("token");
  // console.log("token",token)
  useEffect(() => {
    if (token != null) {
      navigate("/");
    }
  });


  let [formval, setFormval] = useState({ email: "", password: "" });
  let ChangeHandler = (e) => {
    setFormval((currVal) => {
      return { ...currVal, [e.target.name]: e.target.value };
    });
  };

  let handleSubmit = async (e) => {
    e.preventDefault();
    formval.email=formval.email.trim()
    formval.password=formval.password.trim()
    if (!formval.email || !formval.password) {
      return alert("all fileds is required");
    }
    try {
      const response = await axios(
        `${BASE_URL}/api/user/login-user`,
        {
          method: "POST",
          data: formval,
        }
      );
      // console.log("login",response.data);
      // console.log(response.token);
      if (response.data.token) {
        localStorage.setItem("token", response?.data.token);
        localStorage.setItem(
          "user",
          JSON.stringify(response?.data?.data || "")
        );
        // localStorage.setItem(
        //   "role",
        //   JSON.stringify(response?.data?.role || "")
        // );
        // localStorage.setItem(
        //   "RolePermissionData",
        //   JSON.stringify(response?.data?.rolepermissssion || "")
        // );
        // console.log(response?.data?.user?.user_type?._id);
        
        // onSuccess(response?.data?.data)
        navigate("/");
      } else {
        alert(response?.data?.msg || `Something Went Wrong From Server Side`);
      }
    } catch (error) {
      console.log(error);
      
      alert("Something Went Wrong From Server Side")
      // console.log("Error :", error?.response?.data);
    }
  };
  // let handleSubmit = async (e) => {
  //   e.preventDefault();
  //   formval.email = formval.email.trim();
  //   formval.password = formval.password.trim();
    
  //   if (!formval.email || !formval.password) {
  //     return alert("All fields are required");
  //   }
  
  //   try {
  //     const response = await axios.post(`${BASE_URL}/api/user/login-user`, formval);
  //     console.log("login", response);
  
  //     if (response.data && response.data.token) {
  //       // Safely set items in localStorage
  //       localStorage.setItem("token", response.data.token);
        
  //       // Ensure you store JSON only if the response contains valid data
  //       if (response.data.user) {
  //         localStorage.setItem("user", JSON.stringify(response.data.user));
  //       }
        
  //       // if (response.data.role) {
  //       //   localStorage.setItem("role", JSON.stringify(response.data.role));
  //       // }
        
  //       // if (response.data.RolePermissionData) {
  //       //   localStorage.setItem("RolePermissionData", JSON.stringify(response.data.RolePermissionData));
  //       // }
        
  //       // Execute success callback and navigate
  //       if (onSuccess) {
  //         onSuccess(response.data.user?.user_type?._id);
  //       }
        
  //       navigate("/");
  //     } else {
  //       alert(response?.data?.message || "Something went wrong from server side");
  //     }
  //   } catch (error) {
  //     console.error("Error:", error);
  //     alert("Something went wrong from server side");
  //   }
  // };
  
  return (
    <>
      <section className="main-login">
        <div className="container-fluid">
          <div className="row">
            <div
              className="col-xl-6 b-center bg-size d-none d-lg-block d-xl-block"
              style={{
                backgroundImage: `url(${Loginimg})`,
                backgroundSize: "cover",
                backgroundPosition: "center center",
                display: "block"
              }}
            >
              <div className="login-lft">
                <div className="login-lft-innr">
                  <h3>Welcome</h3>
                  <img src={logo} alt="" />
                  <p className="text-white">
                    Lorem ipsum dolor, sit amet consectetur adipisicing elit. Optio
                    vel, laborum omnis atque quisquam ducimus, eius expedita alias!
                    Repellat alias sint at dignissimos adipisci quis vitae quod culpa
                    labore voluptatum.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-xl-6 p-0">
              <div className="login-card">
                <form action="" onSubmit={handleSubmit} className="login-form">
                  <h4>
                    <span>Login</span>
                  </h4>
                  <div className="mb-4 frm-bx">
                    <label htmlFor="" className="form-label">
                      User Name/Mobile Number
                    </label>
                    <input
                      type="email"
                      className="form-control"
                      name="email"
                      placeholder="Enter User Name/Mobile Number"
                      onChange={ChangeHandler}
                      value={formval.email}
                    />
                    <span className="fas fa-user" />
                  </div>
                  <div className="mb-5 frm-bx">
                    <label htmlFor="" className="form-label">
                      Password
                    </label>
                    <input
                      type="password"
                      className="form-control"
                      placeholder="Enter Password"
                      onChange={ChangeHandler}
                      name="password"
                      value={formval.password}
                    />
                    <span className="fas fa-lock" />
                  </div>
                  <div className="mb-4 mt-5 frm-bx">
                    <button className="frm-btn w-100" type="submit">
                      Login
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>

    </>
  );
}


// import React, { useState, useEffect } from "react";
// import Loginimg from "../../assets/images/login-img.png";
// import logo from "../../assets/images/login-logo.png";
// import axios from "axios";
// import { useNavigate } from "react-router-dom";
// // import { ToastMessgae } from "../utils/toast";
// import BASE_URL from "../config/baseUrl";
// // assets/images/login-img.png
// export const Login = () => {
//   const [phone, setPhone] = useState("");
//   const [pin, setPin] = useState("");

//   const navigate = useNavigate();
//   useEffect(() => {
//     const isLoggedIn = localStorage.getItem("token");
//     if (isLoggedIn) {
//       navigate("/", { replace: false });
//     }
//   });
//   const handleLogin = async (e) => {
//     e.preventDefault();
//     //  console.log({phone,pin});
//     await axios
//       .post(`${BASE_URL}/api/user/login`, { phone, pin })
//       .then((res) => {
//         console.log("res", res);
//         if (res.data.status === false) {
//           const token = res.data.token;
//           localStorage.setItem("token", token);
//           navigate("/");
//           window.location.reload();
//         } else {
//           alert(res.data.message);
//         }
//       })
//       .catch((error) => {
//         console.log("login error", error);
//       });
//   };
//   return (
//     <>
//       <section className="main-login">
//         <div className="container-fluid">
//           <div className="row">
//             <div
//               className="col-xl-6 b-center bg-size d-none d-lg-block d-xl-block"
//               style={{
//                 backgroundImage: `url(${Loginimg})`,
//                 backgroundSize: "cover",
//                 backgroundPosition: "center center",
//                 display: "block",
//               }}
//             >
//               <div className="login-lft">
//                 <div className="login-lft-innr">
//                   <h3>Welcome</h3>
//                   <img src={logo} alt="" />
//                   <p className="text-white">
//                     Lorem ipsum dolor, sit amet consectetur adipisicing elit.
//                     Optio vel, laborum omnis atque quisquam ducimus, eius
//                     expedita alias! Repellat alias sint at dignissimos adipisci
//                     quis vitae quod culpa labore voluptatum.
//                   </p>
//                 </div>
//               </div>
//             </div>
//             <div className="col-xl-6 p-0">
//               <div className="login-card">
//                 <form action="" className="login-form" onSubmit={handleLogin}>
//                   <h4>
//                     <span>Login</span>
//                   </h4>
//                   <div className="mb-4 frm-bx">
//                     <label htmlFor="" className="form-label">
//                       User Name/Mobile Number
//                     </label>
//                     <input
//                       type="text"
//                       className="form-control"
//                       value={phone}
//                       onChange={(e) => setPhone(e.target.value)}
//                       placeholder="Enter User Name/Mobile Number"
//                     />
//                     <span className="fas fa-user" />
//                   </div>
//                   <div className="mb-5 frm-bx">
//                     <label htmlFor="" className="form-label">
//                       Password/Pin
//                     </label>
//                     <input
//                       type="password"
//                       value={pin}
//                       className="form-control"
//                       placeholder="Enter Password/Pin"
//                       onChange={(e) => setPin(e.target.value)}
//                     />
//                     <span className="fas fa-lock" />
//                   </div>
//                   <div className="mb-4 mt-5 frm-bx">
//                     <button className="frm-btn w-100" type="submit">
//                       Login
//                     </button>
//                   </div>
//                 </form>
//               </div>
//             </div>
//           </div>
//         </div>
//       </section>
//     </>
//   );
// };
