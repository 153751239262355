import axios from "axios";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import BASE_URL from "../config/baseUrl";
const BannerAdd = () => {
  const [values, setValues] = useState({
    start_time: "",
    end_time: "",
    show_time: "",
    sequence: "",
    show_yes_no: "",
  });
  const [imageFile, setImageFile] = useState(null);
  const navigate = useNavigate();
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const formData = new FormData();
      formData.append("start_time", values.start_time);
      formData.append("end_time", values.end_time);
      formData.append("show_time", values.show_time);
      formData.append("sequence", values.sequence);
      formData.append("show_yes_no", values.show_yes_no);
      if (imageFile) {
        formData.append("image", imageFile);
      }
      const response = await axios.post(
        `${BASE_URL}/api/bannershow/add`,
        formData
      );
      // http://216.48.184.213:8100/api/bannershow/add
      console.log("responseaddb", response);
      navigate("/bannershowlist");
    } catch (error) {
      console.log("error adding popup", error);
    }
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
  };

  const handleImgChange = (e) => {
    setImageFile(e.target.files[0]);
  };
  return (
    <>
      <section className="main-sec">
        <div className="row">
          <div className="col-lg-12">
            <div className="dashboard-title">
              <h4 className="dash-head">
                <i className="fas fa-users me-2" />
                Add Banner
              </h4>
            </div>
            <div className="custom-bredcump">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Add Banner
                  </li>
                </ol>
              </nav>
            </div>
          </div>
          <div className="col-lg-12">
            <div className="cards cstm-form">
              <form action="" onSubmit={handleSubmit}>
                <div className="row">
                  <div className="frm-bx mb-4 col-lg-4 mb-3">
                    <label htmlFor="" className="form-label">
                      Start Time
                    </label>
                    <input
                      type="date"
                      className="form-control"
                      value={values.start_time}
                      onChange={handleChange}
                      name="start_time"
                      placeholder="Start Time"
                    />
                  </div>
                  <div className="frm-bx mb-4 col-lg-4 mb-3">
                    <label htmlFor="" className="form-label">
                      End Time
                    </label>
                    <input
                      type="date"
                      className="form-control"
                      name="end_time"
                      value={values.end_time}
                      onChange={handleChange}
                      placeholder="End Time"
                    />
                  </div>
                  <div className="frm-bx mb-4 col-lg-4 mb-3">
                    <label htmlFor="" className="form-label">
                      Show Time
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="show_time"
                      value={values.show_time}
                      onChange={handleChange}
                      placeholder="Show Time"
                    />
                  </div>
                  <div className="frm-bx mb-4 col-lg-4 mb-3">
                    <label htmlFor="" className="form-label">
                      Image
                    </label>
                    <input
                      type="file"
                      onChange={handleImgChange}
                      className="form-control"
                    />
                    <div className="image-box">
                      {imageFile && (
                        <div className="image-box-innr">
                          <img
                            src={URL.createObjectURL(imageFile)}
                            alt="Image"
                          />
                          <a href="javascript:void(0);">
                            <i className="far fa-times" />
                          </a>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="frm-bx mb-4 col-lg-4 mb-3">
                    <label htmlFor="" className="form-label">
                      Sequence
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="sequence"
                      value={values.sequence}
                      onChange={handleChange}
                      placeholder="Show Time"
                    />
                  </div>
                  <div className="frm-bx mb-4 col-lg-4 mb-3">
                    <label htmlFor="" className="form-label">
                      Show
                    </label>
                    <select
                      name="show_yes_no"
                      value={values.show_yes_no}
                      onChange={handleChange}
                      className="form-select"
                    >
                      <option selected="">Select Status</option>
                      <option value="true">Yes</option>
                      <option value="false">No</option>
                    </select>
                  </div>
                  <div className="frm-bx mb-4 col-lg-12 mb-3 text-center">
                    <button className="thm-btn rounded-2" type="submit">
                      Add Banner
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default BannerAdd;
