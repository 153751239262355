import React, { useEffect } from 'react';
import { io } from 'socket.io-client';

export default function CommentSocket() {
  let url = "http://localhost:1100";

  useEffect(() => {
    const newSocket = io(url);  // Create the socket connection
    console.log(newSocket);

    // Listen for 'reply' messages from the server
    newSocket.on('reply', (message) => {
      console.log('Received message:', message);
    });

    newSocket.on('add Comment', (message) => {
      console.log('Received message:', message);
    });
 
    return () => {
      newSocket.disconnect();
    };
  }, [url]);

  return (
    <>
      <section className="main-sec">
        <div className="row">
          <div className="col-lg-12">
            <div className="dashboard-title">
              <h4 className="dash-head">
                <i className="fa-brands fa-readme  me-2" />
                Attend Test List
              </h4>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
