import React ,{ useState } from "react";
// import Header from "../Layout/Header";
// import Sidebar from "../Layout/Sidebar";
import { Link, useNavigate } from "react-router-dom";
import BASE_URL from "../config/baseUrl";
import axios from "axios";
import Tinymce from "../ckeditor/Tinymce";
export default function VideoCourseCategory_add() {
  const navigate = useNavigate();
  let [img, setImg] = useState("");
  let [formval, setFormval] = useState({
    category: "",
    categoryPhoto: "",
    isActive: true, 
    categorySequence: "",
  });
  // const handelOnChange = (e) => {
  //   if (e.target.name === "categoryPhoto") {
  //     setFormval((currVal) => ({
  //       ...currVal,
  //       categoryPhoto: e.target.files[0],
  //     }));
  //   } else {
  //     setFormval((currVal) => ({
  //       ...currVal,
  //       [e.target.name]: e.target.value,
  //     }));
  //   }
  // };



  const handelOnChange = (e) => {
    if (e.target.name === "categoryPhoto") {
      const reader = new FileReader();
      reader.onload = () => {
        setImg(reader.result);
      };
      reader.readAsDataURL(e.target.files[0]);
      setFormval((currVal) => ({
        ...currVal,
        categoryPhoto: e.target.files[0],
      }));
    } else {
      setFormval((currVal) => ({
        ...currVal,
        [e.target.name]: e.target.value,
      }));
    }
  };


  const handelOnSubmit = (e) => {
    e.preventDefault();

    if (
      !formval.category ||
      !formval.categoryPhoto ||
      !formval.categorySequence
    ) {
      alert("all filds is required!");
      return;
    }
     
    const postData = new FormData();
    postData.append("categoryPhoto", formval.categoryPhoto);
    postData.append("category", formval.category);
    postData.append("categorySequence", formval.categorySequence);
    postData.append("isActive", formval.isActive);

    const apiCall = async () => {
      let response = await axios(`${BASE_URL}/api/videocoursecategory/add`, {
        method: "POST",
        headers: {
          Authorization: localStorage.getItem("token"),
        },
        data: postData,
      });
      console.log(response,"line no 5")
      navigate("/video_course_category_list")
    };
    apiCall();
  };

  return (
    <>
      {/* <Header /> */}
      {/* <Sidebar /> */}
      <section className="main-sec">
        <div className="row">
          <div className="col-lg-12">
            <div className="dashboard-title">
              <h4 className="dash-head">
                <i className="fas fa-video me-2" />
                Add Video Course Category
              </h4>
            </div>
            <div className="custom-bredcump">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/video_course_list">Video Course Management</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Add Video Course Category
                  </li>
                </ol>
              </nav>
            </div>
          </div>
          <div className="col-lg-12">
            <div className="cards cstm-form">
              <form action="" onSubmit={handelOnSubmit}>
                <div className="row">
                  <div className="col-lg-4 mb-4">
                    <label htmlFor="" className="form-label">
                    Category Name
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="category"
                      onChange={handelOnChange}
                      value={formval.category}
                    />
                  </div>
                  <div className="col-lg-4 mb-4">
                    <label htmlFor="" className="form-label">
                      Category Sequence
                    </label>
                    <input type="number" className="form-control"  name='categorySequence' min={0}
                      onChange={handelOnChange}
                      value={formval.categorySequence}/>
                  </div>
                  <div className="col-lg-4 mb-4">
                    <label htmlFor="" className="form-label">
                      Status
                    </label>
                    <select name="isActive" onChange={handelOnChange} className="form-select">
                      <option >Select Status</option>
                      <option value={true}>Active</option>
                      <option value={false}>Inactive</option>
                    </select>
                  </div>
                  <div className="col-lg-4 mb-4">
                    <label htmlFor="" className="form-label">
                      Image
                    </label>
                    <input
                      type="file"
                      className="form-control"
                      defaultValue=""
                      name="categoryPhoto"
                      onChange={handelOnChange}
                      // value={formval.avatar}
                    //   multiple    
                    />
                    {img&& <img src={`${img}`} alt="Preview Image" className='img-50' />}
                  </div>
                  <div className="col-lg-12 mb-4 text-center">
                    <button className="thm-btn rounded-2" type="submit">
                    Submit
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
