import React, { useState, useEffect } from "react";
// import Header from "../Layout/Header";
// import Sidebar from "../Layout/Sidebar";
import { Link,useNavigate } from "react-router-dom";
import BASE_URL from "../config/baseUrl";
import axios from "axios";
// import MyCkeditor from "./MyCKeditor2";
import MyCkeditor from "../ckeditor/MyCKeditor";
import Tinymce from "../ckeditor/Tinymce";
import React_Quill_Editor from "../ckeditor/React_Quill_Editor";
// let arr = []
// let imagesArray = []
// for (let i of e.target.files) {
//   arr.push(i)
//   const reader = new FileReader();
//   reader.onload = (event) => {
//     imagesArray.push(event.target.result);
//     if (imagesArray.length === (e.target.files).length) {
//       setImages(imagesArray);
//     }
//   };
//   reader.readAsDataURL(i);
// }
export default function VideoCourse_add() {
  let navigate = useNavigate();
  let [formval, setFormval] = useState({
    title: "",
    videoCategory: "",
    images: [],
    details: "",
    price: "",
    offerPrice: "",
    isActive: true,
    sequence:""
  });
  let [categoryData, setCategoryData] = useState([]);
  const [image, setImages] = useState([]);

  const handelOnChange = (e) => {
    if (e.target.name === "images") {
      // console.log(e.target.files.length)
      // console.dir(e)
      let arr = []
      let imagesArray = []
      for (let i of e.target.files) {
        arr.push(i)
        const reader = new FileReader();
        reader.onload = (event) => {
          imagesArray.push(event.target.result);
          if (imagesArray.length === (e.target.files).length) {
            // setImages(imagesArray);
            setImages((prv)=>{
              return [...prv,...imagesArray] 
            });
          }
        };
        reader.readAsDataURL(i);
      }
      setFormval((currVal) => ({ ...currVal, images: [...formval.images,...arr] }));
      // e.target.files=formval.images
    } else {
      setFormval((currVal) => ({ ...currVal, [e.target.name]: e.target.value }));
    }
  };

  useEffect(() => {
    const apiCall = async () => {
      let response = await axios(
        `${BASE_URL}/api/videocoursecategory/allcourses`,
        {
          method: "GET",
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        }
      );

    let filterData= response?.data?.data
     filterData=response?.data?.data.filter((arr)=>arr.isActive ===  true)
      setCategoryData(filterData);
    };
    apiCall();
  }, []);

  const handelOnSubmit = (e) => {
    e.preventDefault();
    console.log(formval)
    return 
    if (image.length < 4) {
      e.target.value = ''; 
      return alert('You can add minimum select up to 4 images.');
    }
    if (image.length > 10) {
      e.target.value = '';
      return alert('You can select max to 10 images.');
    }
    if (
      !formval.title ||
      !formval.details ||
      !formval.videoCategory ||
      !formval.price ||
      !formval.offerPrice||
      !formval.sequence
    ) {
      alert("please fill all field");
      return;
    }

    const postData = new FormData();
    formval?.images?.map((arr) => {
      postData.append("images", arr);
    });
    postData.append("title", formval.title);
    postData.append("videoCategory", formval.videoCategory);
    postData.append("details", formval.details);
    postData.append("price", formval.price);
    postData.append("offerPrice", formval.offerPrice);
    postData.append("isActive", formval.isActive);
    postData.append("sequence", formval.sequence);

    // http://localhost:8080
    const apiCall = async () => {
      let response = await axios(`${BASE_URL}/api/videocourse/add`, {
        method: "POST",
        headers: {
          Authorization: localStorage.getItem("token"),
        },
        data: postData,
      });
      if (!(response?.data?.status)) {
        alert("some error accoured");
        return;
      }
      navigate("/video_course_list"); 
    };
    apiCall();
  };

 let handleImgDelete=(e)=>{
  setImages( image.filter((item, index) => index !== e));
  let array = formval.images.filter((item, index) => index !== e);
  setFormval((currVal) => ({ ...currVal, images: array }));
 }

  return (
    <>
      {/* <Header /> */}
      {/* <Sidebar /> */}
      <section className="main-sec">
        <div className="row">
          <div className="col-lg-12">
            <div className="dashboard-title">
              <h4 className="dash-head">
                <i className="fas fa-video me-2" />
                Add Video Course
              </h4>
            </div>
            <div className="custom-bredcump">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/video_course_list">Video Course Management</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Add Video Course
                  </li>
                </ol>
              </nav>
            </div>
          </div>
          <div className="col-lg-12">
            <div className="cards cstm-form">
              <form id="imageUploadForm" action="" onSubmit={handelOnSubmit}>
                <div className="row">
                  <div className="col-lg-4 mb-4">
                    <label htmlFor="" className="form-label">
                      Course Name
                    </label>
                    <input
                      type="text"
                      placeholder="Video Course Name"
                      name="title"
                      onChange={handelOnChange}
                      value={formval.title}
                      className="form-control"
                    />
                  </div>
                  <div className="col-lg-4 mb-4">
                    <label htmlFor="" className="form-label">
                      Category
                    </label>
                    <select
                      name="videoCategory"
                      onChange={handelOnChange}
                      className="form-select"
                    >
                      <option defaultValue >Select Category</option>
                      {categoryData?.map((arr) => (
                        <option key={arr?._id} value={arr._id}>
                          {arr?.category}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="col-lg-4 mb-4">
                    <label htmlFor="" className="form-label">
                      Video Sequence
                    </label>
                    <input type="number" className="form-control"  name='sequence' min={0}
                      onChange={handelOnChange}
                      value={formval.sequence}/>
                  </div>
                  <div className="col-lg-4 mb-4">
                    <label htmlFor="" className="form-label">
                      Price
                    </label>
                    <input
                      type="number"
                      name="price"
                      placeholder="Price"
                      onChange={handelOnChange}
                      value={formval?.price}
                      min={0}
                      className="form-control"
                    />
                  </div>
                  <div className="col-lg-4 mb-4">
                    <label htmlFor="" className="form-label">
                      Offer Price
                    </label>
                    <input
                    // id="imageInput"
                      type="number"
                      name="offerPrice"
                      placeholder="Offer Price"
                      onChange={handelOnChange}
                      value={formval?.offerPrice}
                      className="form-control"
                      min={0}
                    />
                  </div>
                  <div className="col-lg-4 mb-4">
                    <label className="form-label">Status</label>
                    <select
                      name="isActive"
                      onChange={handelOnChange}
                      className="form-select"
                    >
                      <option>Select Status</option>
                      <option value={true}>Active</option>
                      <option value={false}>Inactive</option>
                    </select>
                  </div>
                  <div className="col-lg-4 mb-4">
                    <label htmlFor="" className="form-label">
                      Image
                    </label>
                    <input
                      type="file"
                      className="form-control"
                      name="images"
                      onChange={handelOnChange}
                      multiple
                      // value={10}
                    />
                     <div className='image-box'>
                      {image.map((imageUrl, index) => (
                              <div className='image-box-innr' key={index}>
                                <img  src={imageUrl} alt={`Image ${index}`} />
                              <Link
                              to="#"
                              onClick={() => handleImgDelete(index)}
                            >
                              <i className="fa fa-times" />
                            </Link>
                              </div>
                            ))}
                     </div>
                  </div>
                  <div className="col-lg-12 mb-4">
                    <div id="editor">
                      {/* <MyCkeditor
                        disabled={false}
                        data={formval?.details}
                        seteditor={setFormval}
                        editorTitle={"Video Details"}
                        fieldName={"details"}
                      /> */}
                      <React_Quill_Editor  disabled={false}
                        data={formval?.details}
                        seteditor={setFormval}
                        editorTitle={"Video Details"}
                        fieldName={"details"}/>
                        
                    </div>
                  </div>

                  <div className="col-lg-12 mb-4 text-center">
                    <button className="thm-btn" type="submit">
                      Submit
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
