import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import React_Quill_Editor from "../ckeditor/React_Quill_Editor";
import BASE_URL from "../config/baseUrl";
import axios from "axios";

const MegaTestEdit = () => {
  let navigate = useNavigate();
  let { id } = useParams();
  //   console.log("id aa ", id);
  let [formval, setFormval] = useState({
    title: "",
    description: "",
    totalQuestions: "",
    duration: "",
    marks: "",
    negMarks: "",
    testPackage: "",
    schedule_date: "",
    announcement_date: "",
    subject: "",
    course_type: "",
    syllabuspdf: "",
    schedule_time: "",
    announcement_time:""
  });
  const [row, setRow] = useState([]);
  const [subList, setSubList] = useState([]);
  const [pdf, setPdf] = useState("");
  const [subjectCount, setSubjectCount] = useState([]);
  // console.log("Form Values:", formval);
  // console.log("Subject Details:", row);
  // console.log("",)
  useEffect(() => {
    subjectListData();
    megaTestShow();
  }, []);

  // single data fetch
  const megaTestShow = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/api/megatest/show/${id}`, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
 
 
      const schedule_time = new Date( parseInt(response?.data?.data?.megaTestData?.schedule_time) );
      let schedule_time_String = schedule_time?.toLocaleTimeString('en-US', { hour12: false });

      const announcement_time = new Date( parseInt(response?.data?.data?.megaTestData?.announcement_time) );
      let announcement_time_String = announcement_time?.toLocaleTimeString('en-US', { hour12: false });
 
      setFormval({...response?.data?.data?.megaTestData,schedule_time : schedule_time_String, announcement_time :announcement_time_String});

      setRow(response?.data?.data?.subjectDetails);
      setSubjectCount(response.data.data.subjectCount)

    } catch (error) {
      console.log("error mega test getting error", error);
    }
  };
 
  const subjectListData = async () => {
    try {
      const response = await axios(`${BASE_URL}/api/subject/alldata`, {
        method: "GET",
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      //   console.log("sublist", response.data.data);
      setSubList(response.data.data);
    } catch (error) {
      console.log("subject list getting error", error);
    }
  };
  const handelOnChange = (e) => {
    if (e.target.name === "syllabuspdf") {
      let file = e?.target?.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = () => {
          setPdf(reader?.result);
        };
        reader.readAsDataURL(e?.target?.files[0]);
        return setFormval((currVal) => ({
          ...currVal,
          syllabuspdf: e.target.files[0],
        }));
      }
    }
    if (e.target.type === "number") {
      if (e.target.value < 0) {
        return;
      }
    }
    setFormval((currVal) => ({ ...currVal, [e.target.name]: e.target.value }));
  };

  const handelOnSubmit = async (e) => {
    e.preventDefault();
    
    let formData = new FormData();
    formData.append("syllabuspdf", formval.syllabuspdf);
    formData.append("formval", JSON.stringify(formval));
    formData.append("testSubject", JSON.stringify(row));
    console.log(formval);
    console.log(row);
    
    // return
    const response = await axios.post(
      // `http://localhost:1100/api/megatest/edit/${id}`,
      `${BASE_URL}/api/megatest/edit/${id}`,
      formData,
      {
        method: "POST",
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      } 
    );
    // console.log("responseedittestmega", response);
    // alert('')
    navigate("/megatestlist");
  };

  const addRow = () => {
    setRow([
      ...row,
      { subject: "", totalQuestions: "", marks: "", negMarks: "" },
    ]);
  };
  const removeRow = (index) => {
    const newRows = row.filter((row, i) => i !== index);
    setRow(newRows);
  };

  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    setRow((prevRows) =>
      prevRows.map((row, i) => (i === index ? { ...row, [name]: value } : row))
    );
  };

  
  const deletetestSubject= async (subjectId)=>{
    // console.log(subjectId)
    let response = await axios(`${BASE_URL}/api/test/test_subject/${subjectId}`, {
      method: "DELETE",
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    });
    setRow((prv)=>{
      prv=prv.filter((arr)=>arr?._id !== response?.data?.data?._id)
      return prv
    });
  }
  // console.log("formValme",formval)
  return (
    <>
      <section className="main-sec">
        <div className="row">
          <div className="col-lg-12">
            <div className="dashboard-title">
              <h4 className="dash-head">
                <i className="fa-brands fa-readme  me-2" />
                Edit Test
              </h4>
            </div>
            <div className="custom-bredcump">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/megatestlist">Mega Test List</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Edit Test
                  </li>
                </ol>
              </nav>
            </div>
          </div>
          <div className="col-lg-12">
            <div className="cards cstm-form">
              <form onSubmit={handelOnSubmit}>
                <div className="row">
                  <div className="row">
                    <div className="mb-3 col-lg-4">
                      <label className="form-label">Test Title</label>
                      <input
                        type="text"
                        className="form-control"
                        name="title"
                        onChange={handelOnChange}
                        value={formval?.title}
                      />
                    </div>

                    <div className="mb-3 col-lg-4">
                      <label className="form-label">Schedule Date</label>
                      <input
                        type="date"
                        className="form-control"
                        name="schedule_date"
                        onChange={handelOnChange}
                        value={formval?.schedule_date?.split("T")[0]}
                        // min={today}
                      />
                    </div>

                    <div className="mb-3 col-lg-3">
                    <label className="form-label">Schedule Time</label>
                    <input
                      type="time"
                      className="form-control"
                      name="schedule_time"
                      onChange={handelOnChange}
                      value={formval?.schedule_time}
                    />
                  </div>

                    
                    <div className="mb-3 col-lg-4">
                      <label className="form-label">Announcement Date</label>
                      <input
                        type="date"
                        className="form-control"
                        name="announcement_date"
                        onChange={handelOnChange}
                        value={formval?.announcement_date?.split("T")[0]}
                      />
                    </div>
             

                  <div className="mb-3 col-lg-3">
                    <label className="form-label">Announcement Time</label>
                    <input
                      type="time"
                      className="form-control"
                      name="announcement_time"
                      onChange={handelOnChange}
                      value={formval?.announcement_time}
                    />
                  </div>
                    
                    <div className="mb-3 col-lg-4">
                      <label className="form-label">
                        Total Time (In Mintus)
                      </label>
                      <input
                        type="number"
                        className="form-control"
                        name="duration"
                        onChange={handelOnChange}
                        value={formval?.duration}
                        min={0}
                      />
                    </div>

                    <div className="col-lg-4 mb-3 ">
                      <label htmlFor="" className="form-label">
                        Syllabus Pdf
                      </label>
                      <input
                        type="file"
                        accept=".pdf"
                        name="syllabuspdf"
                        onChange={handelOnChange}
                        className="form-control"
                      />
                    </div>

                    {/* <div className="mb-3 col-lg-3">
                      <label className="form-label">Course Type</label> <br />
                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="course_type"
                          onChange={handelOnChange}
                          checked={formval?.course_type === "paid"}
                          value="paid"
                          id="paid"
                        />
                        <label className="form-check-label" htmlFor="paid">
                          Paid
                        </label>
                      </div>
                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="course_type"
                          onChange={handelOnChange}
                          checked={formval?.course_type === "unpaid"}
                          value="unpaid"
                          id="unpaid"
                        />
                        <label className="form-check-label" htmlFor="unpaid">
                          Unpaid
                        </label>
                      </div>
                    </div> */}
                  </div>
                  {/* {formval?.syllabuspdf && (
                    <div className="mb-3">
                      <iframe
                        src={pdf || `${BASE_URL}/${formval?.syllabuspdf}`}
                        // onError={pdfError}
                        width="180"
                        height="100"
                        id="pdfFrame"
                      ></iframe>
                    </div>
                  )} */}
                  {formval?.syllabuspdf ? (
                    <div className="mb-3">
                      <iframe
                        src={pdf || `${BASE_URL}/${formval?.syllabuspdf}`}
                        width="180"
                        height="100"
                        // onLoad={pdfError}
                        id="pdfFrame"
                      ></iframe>
                    </div>
                  ) : (
                    <div id="errorMessage" style={{ color: "red" }}>
                      PDF file is not available.
                    </div>
                  )}{" "}
                  {/* {formval?.syllabuspdf ? null : (
                    <div id="errorMessage" style={{ color: "red" }}>
                      PDF file is not available.
                    </div>
                  )} */}
                  <div className="row align-items-center">
                    <div className="mb-3 col">
                      <label className="form-label">Select Subject</label>
                      <select
                        className="form-select"
                        name="subject"
                        onChange={handelOnChange}
                        value={formval?.subject?._id}
                      >
                        <option>Select Subject</option>
                        {subList?.map((arr) => (
                          <option key={arr?._id} value={arr?._id}>
                            {arr?.sub_name}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="mb-3 col">
                      <label className="form-label">Total Question</label>
                      <input
                        type="number"
                        className="form-control"
                        name="totalQuestions"
                        onChange={handelOnChange}
                        value={formval?.totalQuestions}
                        min={0}
                      />
                    </div>
                    <div className="mb-3 col">
                      <label className="form-label">Total Marks</label>
                      <input
                        type="number"
                        className="form-control"
                        name="marks"
                        onChange={handelOnChange}
                        value={formval?.marks}
                        min={0}
                      />
                    </div>
                    <div className="mb-3 col">
                      <label className="form-label">Negative Mark</label>
                      <input
                        type="number"
                        className="form-control"
                        name="negMarks"
                        onChange={handelOnChange}
                        value={formval?.negMarks}
                        min={0.1}
                         step={0.1}
                      />
                    </div>
                    <div className="col ">
                      <label></label>
                      <button
                        type="button"
                        className="btn btn-primary me-3"
                        onClick={addRow}
                      >
                        <i className="fas fa-plus"></i>
                      </button>
                      &nbsp;
                      <Link
                        to={`/testassignquestionadd/${formval?._id}/${formval?.subject?._id}/${formval?.totalQuestions}`}
                        className="blu-btn"
                      >
                        Assign ({formval?.testassignQuestionCount || 0})
                      </Link>
                    </div>
                  </div>
                  {row?.map((row, index) => (
                    <div className="row align-items-center" key={index}>
                      <div className="mb-3 col">
                        <label className="form-label">Select Subject</label>
                        <select
                          className="form-select"
                          name="subject"
                          onChange={(e) => handleInputChange(e, index)}
                          value={row?.subject}
                        >
                          <option>Select Subject</option>
                          {subList?.map((arr) => (
                            <option key={arr?._id} value={arr?._id}>
                              {arr?.sub_name}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="mb-3 col">
                        <label className="form-label">Total Question</label>
                        <input
                          type="number"
                          className="form-control"
                          name="totalQuestions"
                          onChange={(e) => handleInputChange(e, index)}
                          value={row?.totalQuestions}
                          min={0}
                        />
                      </div>
                      <div className="mb-3 col">
                        <label className="form-label">Total Marks</label>
                        <input
                          type="number"
                          className="form-control"
                          name="marks"
                          onChange={(e) => handleInputChange(e, index)}
                          value={row?.marks}
                          min={0}
                        />
                      </div>

                      <div className="mb-3 col">
                        <label className="form-label">Negative Mark</label>
                        <input
                          type="number"
                          className="form-control"
                          name="negMarks"
                          onChange={(e) => handleInputChange(e, index)}
                          value={row?.negMarks}
                          min={0.1}
                          step={0.1}
                        />
                      </div>
                      <div className="col">
                        <label></label>
                        <button
                          type="button"
                          className="btn btn-danger me-3"
                          // onClick={() => removeRow(index)}
                            onClick={
                              row?._id
                                ? () => deletetestSubject(row?._id)
                                : () => removeRow(index)
                            }
                        >
                          <i className="fas fa-minus"></i>
                        </button>
                        &nbsp;
                        {row?.subject && (
                          <Link
                            to={`/testassignquestionadd/${formval?._id}/${row?.subject}/${row?.totalQuestions}`}
                            className="blu-btn"
                          >
                            Assign ({subjectCount[index] || 0})
                          </Link>
                        )} 
                      </div>
                    </div>
                  ))}
                  <div className="mb-3 col-lg-12">
                    <React_Quill_Editor
                      disabled={false}
                      data={formval?.description}
                      seteditor={setFormval}
                      fieldName={"description"}
                      editorTitle={"Short Description"}
                    />
                  </div>
                  <div className="mb-3 col-lg-12 text-center">
                    <button type="submit" className="thm-btn w-25">
                      Submit
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default MegaTestEdit;
