import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import BASE_URL from "../config/baseUrl";

const RolePermissionEdit = () => {
  let navigate = useNavigate();
  const { id } = useParams();
  let [formval, setFormval] = useState({});
  let [role, setRole] = useState([]);
  let [subCategories, setSubCategories] = useState([]);
  let [checkedPermissions, setCheckedPermissions] = useState([]);
  let [checkedCategories, setCheckedCategories] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      await fetchRole();
      await apiCall();
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (subCategories.length > 0) {
      fetchExistingData();
    }
  }, [subCategories]);

  // Fetch existing data for editing
  const fetchExistingData = async () => {
    try {
      const response = await axios(
        `${BASE_URL}/api/rolepermission/role-permission-get/${id}`,
        {
          method: "GET",
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        }
      );
      // console.log("aaya",response.data.data)
      const data = response?.data?.data;

      setFormval({ role_id: data?.role_id._id });

      const existingPermissions = data?.sub_cat_id || [];
      // console.log("existingPermissions",existingPermissions)
      const existingPermissionIds = existingPermissions.map(
        (permission) => permission._id
      );
      setCheckedPermissions(existingPermissionIds);
      // Update checkedCategories based on the existing permissions
      let categoriesWithCheckedSubcats = {};
      subCategories.forEach((category) => {
        const isCategoryChecked = category.sub_categories.some((sub) =>
          existingPermissionIds.includes(sub._id)
        );
        if (isCategoryChecked) {
          categoriesWithCheckedSubcats[category._id] = true;
        }
      });
      setCheckedCategories(categoriesWithCheckedSubcats);
    } catch (error) {
      console.log("Error fetching existing data:", error);
    }
  };

  // const handleCategoryCheckboxChange = (categoryId, subCategories) => {
  //   const isCategoryChecked = !checkedCategories[categoryId];

  //   setCheckedCategories((prev) => {
  //     const updatedCategories = { ...prev, [categoryId]: isCategoryChecked };

  //     // Update checkedPermissions based on the checked state of the category
  //     const updatedPermissions = isCategoryChecked
  //       ? [
  //           ...new Set([
  //             ...checkedPermissions,
  //             ...subCategories.map((sub) => sub._id),
  //           ]),
  //         ]
  //       : checkedPermissions.filter((id) => !subCategories.map((sub) => sub._id).includes(id));

  //     setCheckedPermissions(updatedPermissions);
  //     return updatedCategories;
  //   });

  // };


  // const handleCheckboxChange = (id) => {
  //   setCheckedPermissions((prev) =>
  //     prev.includes(id)
  //       ? prev.filter((permId) => permId !== id)
  //       : [...prev, id]
  //   );
  // };

  const handleCategoryCheckboxChange = (categoryId, subCategories) => {
    const isCategoryChecked = !checkedCategories[categoryId];

    setCheckedCategories((prev) => {
      const updatedCategories = { ...prev, [categoryId]: isCategoryChecked };
      // console.log("updateCategory", updatedCategories)
      // Update checkedPermissions based on the checked state of the category
      const updatedPermissions = isCategoryChecked
        ? [
          ...new Set([
            ...checkedPermissions,
            ...subCategories.map((sub) => sub._id),
          ]),
        ]
        : checkedPermissions.filter((id) => !subCategories.map((sub) => sub._id).includes(id));

      setCheckedPermissions(updatedPermissions);
      return updatedCategories;
    });
  };

  const handleCheckboxChange = (id, categoryId) => {
    const updatedPermissions = checkedPermissions.includes(id)
      ? checkedPermissions.filter((permId) => permId !== id)
      : [...checkedPermissions, id];

    setCheckedPermissions(updatedPermissions);

    // Check if all subcategories are unchecked
    const allSubCategories = subCategories.find(cat => cat._id === categoryId)?.sub_categories;
    const allUnchecked = allSubCategories.every(sub => !updatedPermissions.includes(sub._id));

    // Update category checkbox based on subcategory checks
    setCheckedCategories(prev => ({
      ...prev,
      [categoryId]: !allUnchecked
    }));
  };

  const fetchRole = async () => {
    try {
      let response = await axios(`${BASE_URL}/api/role/role-get-all`, {
        method: "GET",
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      setRole(response?.data?.data);
    } catch (error) {
      console.log("Error getting roles:", error);
    }
  };
  // console.log("11",checkedPermissions)
  const handlePostPermission = async (e) => {
    e.preventDefault();
    try {
      const response = await axios(
        `${BASE_URL}/api/rolepermission/role-permission-update/${id}`,
        {
          method: "PUT",
          data: { role_id: formval.role_id, sub_cat_id: checkedPermissions },
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        }
      );

      // console.log("Response:", response?.data);
      navigate("/rolepermissionlist");
    } catch (error) {
      console.log("Error posting permissions:", error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormval({ ...formval, [name]: value });
  };

  //fetch sub category api
  const apiCall = async () => {
    try {
      let response = await axios(
        `${BASE_URL}/api/permissioncategory/permission-n-sub-category-get-all`,
        {
          method: "GET",
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        }
      );
      setSubCategories(response?.data?.data);
    } catch (error) {
      console.log("Error fetching subcategories:", error);
    }
  };
  // console.log("1",formval.role_id)
  return (
    <>
      <section className="main-sec">
        <div className="row">
          <div className="col-lg-12">
            <div className="dashboard-title">
              <h4 className="dash-head">
                <i className="fa-solid fa-book me-2" />
                Edit Role Permission
              </h4>
            </div>
            <div className="custom-bredcump">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="#">Test</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Edit Role Permission
                  </li>
                </ol>
              </nav>
            </div>
          </div>
          <div className="col-lg-12">
            <div className="cards cstm-form">
              <form onSubmit={handlePostPermission}>
                <div className="row">
                  <div className="col-lg-4 col-md-6 mb-4">
                    <label htmlFor="" className="form-label">
                      Role
                    </label>
                    <select
                      name="role_id"
                      onChange={handleChange}
                      className="form-select"
                      value={formval.role_id || ""}
                    >
                      <option value="">Select Role</option>
                      {role?.map((arr) => (
                        <option key={arr?._id} value={arr._id}>
                          {arr?.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="row">
                    {subCategories.map((category) => (
                      <div key={category._id} className="col-lg-3 role-management" >
                        <div>
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id={`category-${category._id}`}
                            checked={!!checkedCategories[category._id]}
                            onChange={() =>
                              handleCategoryCheckboxChange(
                                category._id,
                                category.sub_categories
                              )
                            }
                          />
                          <label for={category._id}>{category?.name}</label>
                        </div>
                        <ul className="col-lg-3 role-management w-100">
                          {category.sub_categories.map((subCategory) => (
                            <li key={subCategory._id}>
                              <input
                                type="checkbox"
                                className="form-check-input"
                                id={subCategory._id}
                                checked={checkedPermissions.includes(
                                  subCategory._id
                                )}
                                onChange={() =>
                                  handleCheckboxChange(subCategory._id, category._id)
                                }
                              />
                              <label htmlFor={subCategory._id}>
                                {subCategory?.name}
                              </label>
                            </li>
                          ))}
                        </ul>
                      </div>
                    ))}
                  </div>
                </div>
                <div className="col-lg-12 mb-4 text-center">
                  <button className="thm-btn" type="submit">
                    Update
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default RolePermissionEdit;
