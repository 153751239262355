import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import BASE_URL from "../config/baseUrl";
import axios from "axios";

const UserRoleEdit = () => {
  const { id } = useParams();
  // console.log("addid",id)
  const [values, setValues] = useState({
    role_id:"",
    user_id:""
  });
  const navigate = useNavigate();
  const [roleList, setRoleList] = useState([]);
 

  useEffect(() => {
    roleListData();
    roleUserData()
  }, []);

  const roleListData = async () => {
    let response = await axios(`${BASE_URL}/api/roleuser/role-user-get/${id}`, {
      method: "GET",
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    });
    console.log("4454", response);
    setValues(response?.data?.data);
  };
  const roleUserData = async () => {
    let response = await axios(`${BASE_URL}/api/role/role-get-all`, {
      method: "GET",
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    });
    console.log("4454", response);
    setRoleList(response?.data?.data);
  };

console.log("values",values)
console.log("role_id",roleList)

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log({user_id:values?.user_id?._id,role_id:   values.role_id });
   
 
    try {
      const response = await axios.put(
        `${BASE_URL}/api/roleuser/role-user-update/${id}`,
        {user_id:values?.user_id?._id,role_id:   values.role_id },
        {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        }
      );
      console.log("Editrole response", response);

      navigate("/userrolelist");
    } catch (error) {
      console.log("error adding role", error);
    }
  };
console.log("values",values)
const handleChange = (e) => {
  const { name, value } = e.target;
  setValues((prevValues) => ({ ...prevValues, [name]: value }));
};
 
  return (
    <>
      <section className="main-sec">
        <div className="row">
          <div className="col-lg-12">
            <div className="dashboard-title">
              <h4 className="dash-head">
                <i className="fas fa-users me-2" />
                Edit User Role
              </h4>
            </div>
            <div className="custom-bredcump">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                  Edit User Role
                  </li>
                </ol>
              </nav>
            </div>
          </div>
          <div className="col-lg-12">
            <div className="cards cstm-form">
              <form action="" onSubmit={handleSubmit}>
                <div className="row">

                <div className="col-lg-4 col-md-6 mb-4">
                    <label htmlFor="" className="form-label">
                      userName
                    </label>
                    <input
                      type="text"
                      name="route"
                      className="form-control"
                      // placeholder="Enter Route Name"
                      value={values?.user_id?.name || null}
                      disabled
                    />
                  </div> 

                  <div className="frm-bx mb-4 col-lg-4 mb-3">
                    <label htmlFor="" className="form-label">
                      Assign Role
                    </label>
                    <select
                      value={values?.role_id}
                      onChange={handleChange}
                      name="role_id"
                      className="form-select"
                    >
                      <option value="">Select Role</option>
                      {roleList?.map((arr) => (
                        <option value={arr?._id} key={arr?._id}>
                          {arr?.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  
                  <div className="frm-bx mb-4 col-lg-12 mb-3 text-center">
                    <button className="thm-btn rounded-2" type="submit">
                      Update  
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default UserRoleEdit;
