import React from 'react'
import { Link } from 'react-router-dom'

const NoPermission = () => {
  return (
    <>
    <section className="main-sec">
        <div className="row">
          {/* <div className="col-lg-12">
            <div className="dashboard-title">
              <h4 className="dash-head">
                <i className="fas fa-video me-2" />
                Add New Role
              </h4>
            </div>
            <div className="custom-bredcump">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link>Roles</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Add New Role
                  </li>
                </ol>
              </nav>
            </div>
          </div> */}
          <div className="col-lg-12">
            <div className="cards cstm-form text-center mt-5">
                <h2 className='p-5'>You Have No Permission To See This Route</h2>
             <Link to="/" className='btn btn-danger '>Back to Home</Link>
              {/* <form action="" onSubmit={onSubmitHandle}>
                <div className="row">
                  <div className="col-lg-6 mb-4">
                    <label htmlFor="" className="form-label">
                      Name
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Role Name"
                      onChange={(e) => setRole(e.target.value)}
                    />
                  </div>
                </div>
                <div className="row">
                  {perCategory?.map((row, rowIndex) => (
                    <div className="col-lg-3 mb-4" key={rowIndex}>
                      <input
                        class="form-check-input"
                        type="checkbox"
                        id={row?._id}
                        onChange={(e) => handleChange(row?._id, e)}
                        checked={formval?.includes(row?._id) && true}
                      />

                      <h4 className="dash-head">{row?.category}</h4>
                    </div>
                  ))}
                </div>
                <div className="col-lg-12 mb-4 text-center">
                  <button className="thm-btn" type="submit">
                    Submit Role
                  </button>
                </div>
              </form> */}
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default NoPermission