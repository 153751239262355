import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import BASE_URL from "../config/baseUrl";
import trash from "../../assets/images/trash.png";
import edit from "../../assets/images/edit.png";
import view from "../../assets/images/view.png";
import Pagination from "../config/Pagination";
import Swal from "sweetalert2";
import QuestionImportCard from "../Question_Bank/QuestionImportCard";

const MegaQuestionList = () => {
  const [megaQuesData, setMegaQuesData] = useState([]);
  const [page, setPage] = useState(1);
  const [quescount, setQuesCount] = useState([]);
  const [subList, setSubList] = useState([]);
  const [quesStatus, setQuesStatus] = useState([]);
  const [subjectOldData, setSubjectOldData] = useState([]);
  const [selectInput, setSelectInput] = useState('');
  const itemPerPage = 10;
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    fetchData();
    fetchSubject();
  }, [page]);
  //pagination
  const handlePageChange = (pageNumber) => {
    setPage(pageNumber);
  };
  //subject list api
  const fetchSubject = async () => {
    try {
      const response = await axios(`${BASE_URL}/api/subject/alldata`, {
        method: "GET",
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      //   console.log("sublist", response.data.data);
      setSubList(response.data.data);
    } catch (error) {
      console.log("subject list getting error", error);
    }finally{
      setLoading(false)
    }
  };

  // fetch question api
  const fetchData = async () => {
    try {
      const response = await axios.get(
        `${BASE_URL}/api/question/all_question?p=${page}`,
        {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        }
      );
      // console.log("responsemega question", response?.data?.data);
      setMegaQuesData(response?.data?.data.allQuestion);
      setQuesCount(response?.data?.data.questionCount);
      const initialStatus = response?.data?.data.allQuestion.map((ques) => ({
        id: ques._id,
        status: ques.isActive,
      }));
      setQuesStatus(initialStatus);
    } catch (error) {
      console.log("fetching question error", error);
    }
  };

  // quiz status api
  const handleActive = async (id) => {
    try {
      const response = await axios.put(
        `${BASE_URL}/api/question/active/${id}`,
        {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        }
      );
      console.log("response", response.data);
      const updatedStatus = quesStatus.map((ques) =>
        ques.id === id
          ? { ...ques, status: response.data.data.isActive }
          : ques
      );
      setQuesStatus(updatedStatus);
    } catch (error) {
      console.log("error active", error);
    }
  };

  // delete quiz api


  const handleDelete = async (id) => {
    // Show confirmation dialog
    const result = await Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    });
  
    // If confirmed, proceed with deletion
    if (result.isConfirmed) {
      try {
        // Make the DELETE request
        const delResponse = await axios.delete(
          `${BASE_URL}/api/question/delete_question/${id}`,
          {
            headers: {
              Authorization: localStorage.getItem("token"),
            },
          }
        );
        
        // Update state
        const deletedUser = delResponse.data;
        setMegaQuesData(megaQuesData.filter((ques)=>ques._id !== deletedUser._id))
        
        // Show success message
        Swal.fire({
          title: "Deleted!",
          text: "Your file has been deleted.",
          icon: "success",
        });
        
        // Refresh the data
        fetchData();
        
      } catch (error) {
        console.error("Error deleting banner:", error);
        Swal.fire({
          title: "Error!",
          text: "There was a problem deleting the banner.",
          icon: "error",
        });
      }
    }
  };
  
  const handelOnChange = async(e) => {
    let id=e?.target?.value
    setSelectInput(e?.target?.value)
    if(!id){
      return alert("Please Select Subject")
    }

   
    let apicall = async () => {
      let response = await axios(
        `${BASE_URL}/api/question/search/${id}`,
        {
          method: "GET",
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        }
      );
      if(response?.data?.data.length === 0){
        return alert("Subject Have No Qestions")
      }   
      setMegaQuesData(response?.data?.data);
    };
    apicall();
  }

  return (
    <>
      <section className="main-sec">
        <div className="row">
          <div className="col-lg-12">
            <div className="dashboard-title">
              <h4 className="dash-head">
                <i className="fa-solid fa-book  me-2" />
                Mega Question Bank
              </h4>
              <Link className="blu-btn" to="/megaquestionadd">
                Add Mega Question
              </Link>
            </div>
            <div className="custom-bredcump">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="#">Test</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Mega Question List
                  </li>
                </ol>
              </nav>
            </div>
          </div>
          <div className="col-lg-12">
            <QuestionImportCard/>
            <div className="cards bus-list">
              <div className="bus-filter">
                <div className="row">
                  <div className="col-lg-7">
                    <h4 className="table-head">Question List ({quescount})</h4>
                  </div>
                  <div className="col-lg-5">
                    <form action="">
                      <div className="row justify-content-end gx-2 align-items-center">
                        <div className="col-lg-6">
                          <select
                            name="subject_id"
                            onChange={handelOnChange} 
                            value={selectInput}
                            className="form-select"
                          >
                            <option value={""}>Select Subject</option>
                            {subList.map((arr) => (
                              <option key={arr?._id} value={arr?._id}>
                                {arr?.sub_name}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div className="col-lg-2">
                          <button
                            type="button"
                               onClick={()=>{
                                setMegaQuesData([...subjectOldData])
                                setPage(1)
                                // setShowPagination(true)
                                setSelectInput("")
                                }}
                            className="blu-btn w-100"
                          >
                            Reset
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div className="table table-responsive custom-table">
              {
                  loading ? (
                    <div className="text-center my-4">
                      <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                      </div>
                    </div>
                  ) : (
                <table className="table table-borderless">
                  <thead>
                    <tr>
                      <th>SR NO.</th>
                      <th>Question</th>
                      <th>option_a</th>
                      <th>option_b</th>
                      <th>option_c</th>
                      <th>option_d</th>
                      <th>SUbject</th>
                      <th>Answer</th>
                      <th>Status</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {megaQuesData?.map((arr, i) => (
                      <tr key={arr?._id}>
                        <td>{++i + 10 * (page - 1)}</td>
                        <td>{arr?.question}</td>
                        <td>{arr?.option_a}</td>
                        <td>{arr?.option_b}</td>
                        <td>{arr?.option_c}</td>
                        <td>{arr?.option_d}</td>

                        <td>{arr?.subject_id?.sub_name || "null"}</td>
                        <td>{arr?.answer}</td>
                        <td>
                          <div className="form-check form-switch">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              onClick={() => handleActive(arr?._id)}
                              // defaultChecked={arr?.isActive}
                              checked={
                                quesStatus.find((ques) => ques.id === arr._id)
                                  ?.status
                              }
                            />
                          </div>
                        </td>
                        <td>
                          <Link
                            className="mx-1"
                            to={`/megaquestionedit/${arr?._id}`}
                          >
                            <img src={edit} alt="" />
                          </Link>
                          <Link
                            className="mx-1"
                            to="#"
                            onClick={() => handleDelete(arr?._id)}
                          >
                            <img src={trash} alt="" />
                          </Link>
                          <Link
                            className="mx-1"
                            to={`/megaquestionview/${arr?._id}`}
                          >
                            <img src={view} alt="" />
                          </Link>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                  {/* {console.log(dat/a > 0)} */}
                </table>)}
                <Pagination
                  currentPage={page}
                  onPageChange={handlePageChange}
                  itemPerPage={itemPerPage}
                  totalItems={quescount}
                />
                {/* {  <div className="custom-pagination">
                  <ul className="pagination">
                    <li className="page-item">
                      <button
                        className="page-link"
                        to=""
                        aria-label="Previous"
                        disabled={pageNumber === 1 && true}
                        onClick={() => setPageNumber((prev) => prev - 1)}
                      >
                        <span aria-hidden="true">«</span>
                      </button>
                    </li>
                    {pageNumber >= 2 && (
                      <li className="page-item">
                        <Link className="page-link" to="">
                          {pageNumber - 1}
                        </Link>
                      </li>
                    )}
                    <li className="page-item">
                      <Link className="page-link active" to="">
                        {pageNumber}
                      </Link>
                    </li>
                    <li className="page-item">
                      <Link
                        className="page-link"
                        to=""
                        onClick={() => setPageNumber((prev) => prev + 1)}
                      >
                        {pageNumber + 1}
                      </Link>
                    </li>
                    <li className="page-item">
                      <Link
                        className="page-link"
                        to=""
                        onClick={() => setPageNumber((prev) => prev + 2)}
                      >
                        {pageNumber + 2}
                      </Link>
                    </li>
                    <li className="page-item">
                      <Link
                        className="page-link"
                        to=""
                        aria-label="Next"
                        onClick={() => setPageNumber((prev) => prev + 1)}
                      >
                        <span aria-hidden="true">»</span>
                      </Link>
                    </li>
                  </ul>
                </div>} */}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default MegaQuestionList;
