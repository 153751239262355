import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import BASE_URL from "../config/baseUrl";
import axios from "axios";

const AdminUserAdd = () => {
  const [values, setValues] = useState({
    name: "",
    email: "",
    mobile: "",
    password: "",
  });
  const [roleList, setRoleList] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    roleListData();
  }, []);

  const roleListData = async () => {
    let response = await axios(`${BASE_URL}/api/role/role-get-all`, {
      method: "GET",
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    });
    console.log("4454", response.data);
    setRoleList(response?.data?.data);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(values);
    if(!values.name || !values.email|| !values.mobile|| !values.password){
      return alert("Emplty Field Not required")
    }
 
    try {
      const response = await axios.post(
        `${BASE_URL}/api/user/register`,
        values
      );
      const response2 = await axios.post(
        `${BASE_URL}/api/roleuser/role-user-create`,
        {user_id:response?.data.data._id,role_id:values.role},
         
      );  
      console.log("addrole response", response);
    //   console.log("addrole response", response2);


      navigate("/adminuserlist");
    } catch (error) {
      console.log("error adding popup", error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
  };


//   const handleDelete = async (id) => {
 
//     const result = await Swal.fire({
//       title: "Are you sure?",
//       text: "You won't be able to revert this!",
//       icon: "warning",
//       showCancelButton: true,
//       confirmButtonColor: "#3085d6",
//       cancelButtonColor: "#d33",
//       confirmButtonText: "Yes, delete it!",
//     });
 
//     if (result.isConfirmed) {
//       try {
//         // Make the DELETE request
//         const delResponse = await axios.delete(
//           `${BASE_URL}/api/roleuser/role-user-delete/${id}`,
//           {
//             headers: {
//               Authorization: localStorage.getItem("token"),
//             },
//           }
//         );

//         // Update state
//         const deletedRole = delResponse.data.data;
//         // console.log('delete',deletedRole)
//         setUserRole(userRole.filter((role) => role._id !== deletedRole._id));

//         // Show success message
//         Swal.fire({
//           title: "Deleted!",
//           text: "Your file has been deleted.",
//           icon: "success",
//         });

//         // Refresh the data
//         fetchData();
//       } catch (error) {
//         console.error("Error deleting user role", error);
//         Swal.fire({
//           title: "Error!",
//           text: "There was a problem deleting the user role.",
//           icon: "error",
//         });
//       }
//     }
//   };

  return (
    <>
      <section className="main-sec">
        <div className="row">
          <div className="col-lg-12">
            <div className="dashboard-title">
              <h4 className="dash-head">
                <i className="fas fa-users me-2" />
                Add Admin User
              </h4>
            </div>
            <div className="custom-bredcump">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Add Admin User
                  </li>
                </ol>
              </nav>
            </div>
          </div>
          <div className="col-lg-12">
            <div className="cards cstm-form">
              <form action="" onSubmit={handleSubmit}>
                <div className="row">
                  <div className="frm-bx mb-4 col-lg-4 mb-3">
                    <label htmlFor="" className="form-label">
                      Name
                    </label>
                    <input
                      type="text"
                      name="name"
                      className="form-control"
                      placeholder="Enter First Name"
                      value={values.name}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="frm-bx mb-4 col-lg-4 mb-3">
                    <label htmlFor="" className="form-label">
                      Email Address
                    </label>
                    <input
                      type="email"
                      name="email"
                      className="form-control"
                      placeholder="Enter  Email Address"
                      onChange={handleChange}
                      value={values.email}
                    />
                  </div>
                  <div className="frm-bx mb-4 col-lg-4 mb-3">
                    <label htmlFor="" className="form-label">
                      Phone Number
                    </label>
                    <input
                      type="number"
                      name="mobile"
                      className="form-control"
                      placeholder="Enter Mobile Number"
                      onChange={handleChange}
                      value={values.mobile}
                    />
                  </div>
                  <div className="frm-bx mb-4 col-lg-4 mb-3">
                    <label htmlFor="" className="form-label">
                      Password
                    </label>
                    <input
                      type="password"
                      name="password"
                      className="form-control"
                      placeholder="Enter Password"
                      value={values.password}
                      onChange={handleChange}
                    />
                  </div>

                  <div className="frm-bx mb-4 col-lg-4 mb-3">
                    <label htmlFor="" className="form-label">
                      Assign Role
                    </label>
                    <select
                      value={values?.role}
                      onChange={handleChange}
                      name="role"
                      className="form-select"
                    >
                      <option value="">Select Role</option>

                      {roleList?.map((arr) => (
                        <option  id={arr?._id} value={arr?._id} key={arr?._id}>
                          {arr?.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="frm-bx mb-4 col-lg-12 mb-3 text-center">
                    <button className="thm-btn rounded-2" type="submit">
                      Submit
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AdminUserAdd;
