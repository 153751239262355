import React, { useState, useEffect } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import BASE_URL from "../config/baseUrl";

const RoleView = () => {
  let navigate = useNavigate();
  let { id } = useParams();
// console.log('id',id)
  let [data, setData] = useState([]);

  useEffect(() => {
    roleViewData();
  }, []);
// console.log("data",data)
  const roleViewData = async () => {
    try {
      const response = await axios(`${BASE_URL}/api/role/role-get/${id}`, {
        method: "GET",
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      setData(response.data.data); 
        // console.log("response",response)
    } catch (error) {
      console.error("Error fetching role data:", error);
    }
  };



  return (
    <>
      <section className="main-sec">
        <div className="row">
          <div className="col-lg-12">
            <div className="dashboard-title">
              <h4 className="dash-head">
                <i className="fas fa-video me-2" />
                View Role
              </h4>
            </div>
            <div className="custom-bredcump">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link>Roles</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    View Role
                  </li>
                </ol>
              </nav>
            </div>
          </div>
          <div className="col-lg-12">
            <div className="cards cstm-form">
              <form action="">
                <div className="row">
                  <div className="col-lg-6 mb-4">
                    <label htmlFor="roleName" className="form-label">
                      Name
                    </label>
                    <input
                      type="text"
                      id="roleName"
                      className="form-control"
                      placeholder=""
                      value={data?.name || ''} 
                      disabled
                    />
                  </div>
                </div>
                <div className="frm-bx mb-4 col-lg-12 mb-3 text-center">
                    <Link to="/rolelist" className="thm-btn rounded-2" type="button">
                      {"<"} Back
                    </Link>
                  </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default RoleView;
