import React, { useState, useEffect } from "react";
// import Header from '../Layout/Header'
// import Sidebar from '../Layout/Sidebar'
import { Link } from "react-router-dom";
import view from "../../assets/images/view.png";
import edit from "../../assets/images/edit.png";
import trash from "../../assets/images/trash.png";
import BASE_URL from "../config/baseUrl";
import axios from "axios";
import Swal from "sweetalert2";

function Role_list() {
  let [formval, setFormval] = useState({}); 
  let [role, setrole] = useState([]);
//   let [data, setData] = useState([]);
  useEffect(() => {
    const apiCall = async () => {
      let response = await axios(`${BASE_URL}/api/role/alldata`, {
        method: "GET",
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      setrole([...response?.data?.data]);
    };
    apiCall();
    // const apiCall = async () => {
    //     let response = await fetch(`${BASE_URL}/api/permission/alldata`, {
    //         method: "GET",
    //         headers: {
    //             "Authorization": (localStorage.getItem("token"))
    //         }
    //     });
    //     const result = await response.json();
    //     console.log(result)
    //     setData([...result.data])
    // };
    // apiCall();
  }, []);


  const handleDelete = (id) => {
    let apiCall = async () => {
      let response = await axios(`${BASE_URL}/api/role/delete/${id}`, {
        method: "POST",
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      console.log(response.data)
    //   console.log(data)
      let deletedUser = response?.data?.data;
      setrole(role.filter((arr) => arr?._id !== deletedUser?._id));
    };
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it !",
    }).then((result) => {
      if (result.isConfirmed) {
        apiCall();
        Swal.fire({
          title: "Deleted!",
          text: "Test has been deleted.",
          icon: "success",
        });
      }
    });
  };
  return (
    <>
      <section className="main-sec">
        <div className="row">
          <div className="col-lg-12">
            <div className="dashboard-title">
              <h4 className="dash-head">
                <i className="fas fa-video me-2" />
                Manage Roles
              </h4>
              <Link to="/role_add" className="blu-btn">
                <i className="far fa-plus me-2" />
                Add New Role
              </Link>
            </div>
            <div className="custom-bredcump">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/role-list">Roles</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Manage Roles
                  </li>
                </ol>
              </nav>
            </div>
          </div>
          <div className="col-lg-12">
            <div className="cards bus-list">
              <div className="bus-filter">
                <div className="row">
                  <div className="col-lg-12">
                    <h4 className="table-head">Roles List</h4>
                  </div>
                </div>
              </div>
              <div className="table table-responsive custom-table">
                <table className="table table-borderless">
                  <thead>
                    <tr>
                      <th>SR NO.</th>
                      <th>NAME</th>
                      <th>ACTION</th>
                    </tr>
                  </thead>
                  <tbody>
                    {role?.map((arr, i) => (
                      <tr key={arr._id}>
                        <td>{++i}</td>
                        <td>{arr?.role}</td>
                        <td>
                          <Link className="mx-2" to={`/role_view/${arr?._id}`}>
                            <img src={view} alt="role" />
                          </Link>
                          <Link className="mx-2" to={`/role_edit/${arr?._id}`}>
                            <img src={edit} alt="edit" />
                          </Link>
                          <Link className="mx-2" to="" onClick={()=>handleDelete(arr?._id)}>
                            <img src={trash} alt="delete" />
                          </Link>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                {/* <div className="custom-pagination">
                                    <ul className="pagination">
                                        <li className="page-item">
                                            <a className="page-link" href="#" aria-label="Previous">
                                                <span aria-hidden="true">«</span>
                                            </a>
                                        </li>
                                        <li className="page-item">
                                            <a className="page-link active" href="#">
                                                1
                                            </a>
                                        </li>
                                        <li className="page-item">
                                            <a className="page-link" href="#">
                                                2
                                            </a>
                                        </li>
                                        <li className="page-item">
                                            <a className="page-link" href="#">
                                                3
                                            </a>
                                        </li>
                                        <li className="page-item">
                                            <a className="page-link" href="#" aria-label="Next">
                                                <span aria-hidden="true">»</span>
                                            </a>
                                        </li>
                                    </ul>
                                </div> */}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Role_list;
