import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import BASE_URL, { baseUrl } from "../config/baseUrl";
import axios from "axios";
import React_Quill_Editor from "../ckeditor/React_Quill_Editor";

const ComboOfferEdit = () => {
  const { id } = useParams();
  // console.log("idcc", id)
  const [videoData, setVideoData] = useState([]);
  const navigate = useNavigate();
  const [values, setValues] = useState({
    title: "",
    description: "",
    price: "",
    offer_price: "",
    sequence: "",
  });
  const [imgFile, setImgFile] = useState(null);
  const [bgcImgFile, setBgcImgFile] = useState(null);
  const [imgFileName, setImgFileName] = useState("");
  const [bgcImgFileName, setBgcImgFileName] = useState("");
  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
  };
  useEffect(() => {
    videoCaourseData();
    getSingleData();
  }, []);
  const getSingleData = async () => {
    try {
      const response = await axios.post(
        `${baseUrl}/api/combo-course-list`,
        {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        }
      );
      // console.log("response single**", response.data.data.data)
      const singledata = response.data.data.data
      const filteredData = singledata.filter((combo) => combo.id == id);
      
      if (filteredData.length > 0) {
        // Assuming there's only one matching item, take the first one
        setValues(filteredData[0]);
      }
    } catch (error) {
      console.log("getting single data error", error);
    }
  };
  // console.log("values",values)
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const formData = new FormData();
      formData.append("id", id);
      formData.append("title", values.title);
      formData.append("description", values.description);
      if (bgcImgFile) {
        formData.append("background_image", bgcImgFile);
      }
      if (imgFile) {
        formData.append("front_image", imgFile);
      }

      formData.append("price", values.price);
      formData.append("offer_price", values.offer_price);
      formData.append("sequence", values.sequence);


      const response = await axios.post(
        `${baseUrl}/api/combo-course-update`,
        formData,
        {
          headers: {
            Authorization: localStorage.getItem("token"),
            "Content-Type": "multipart/form-data",
          },
        }
      );
      console.log("editresponse", response.data);
     
      if(response.data.response === 'error'){
        alert(response.data.message)
      }else{
        navigate("/comboofferlist");
      }
     
  
    } catch (error) {
      console.log("error edit combo offer", error);
    }
  };
  const videoCaourseData = async () => {
    try {
      const resposne = await axios.get(
        `https://test.qualityeducation.in/api/video-category-get`,
        {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        }
      );
      //   console.log("response", resposne.data);
      setVideoData(resposne.data.data);
    } catch (error) {
      console.log("error video cat", error);
    }
  };
// console.log("values",values)
  return (
    <>
      <section className="main-sec">
        <div className="row">
          <div className="col-lg-12">
            <div className="dashboard-title">
              <h4 className="dash-head">
                <i className="fas fa-users me-2" />
                Edit Combo Offer
              </h4>
            </div>
            <div className="custom-bredcump">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Edit Combo Offer
                  </li>
                </ol>
              </nav>
            </div>
          </div>
          <div className="col-lg-12">
            <div className="cards cstm-form">
              <form action="" onSubmit={handleSubmit}>
                <div className="row">
                  <div className="frm-bx mb-4 col-lg-4 mb-3">
                    <label htmlFor="" className="form-label">
                      Title
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      value={values.title}
                      onChange={handleChange}
                      name="title"
                      placeholder="Title"
                    />
                  </div>
                  <div className="frm-bx mb-4 col-lg-4 mb-3">
                    <label htmlFor="" className="form-label">
                      Price
                    </label>
                    <input
                      type="Number"
                      className="form-control"
                      name="price"
                      value={values.price}
                      onChange={handleChange}
                      placeholder="price"
                    />
                  </div>
                  <div className="frm-bx mb-4 col-lg-4 mb-3">
                    <label htmlFor="" className="form-label">
                      Offer Price
                    </label>
                    <input
                      type="Number"
                      className="form-control"
                      name="offer_price"
                      value={values.offer_price}
                      onChange={handleChange}
                      placeholder="offerPrice"
                    />
                  </div>
                  <div className="frm-bx mb-4 col-lg-4 mb-3">
                    <label htmlFor="" className="form-label">
                      Sequence
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="sequence"
                      value={values.sequence}
                      onChange={handleChange}
                      placeholder="Sequence"
                    />
                  </div>
                  <div className="frm-bx mb-4 col-lg-4 mb-3">
                    <label htmlFor="" className="form-label">
                      Image
                    </label>
                    <input
                      type="file"
                      // onChange={(e) => setImgFile(e.target.files[0])}
                      onChange={(e) => {
                        setImgFile(e.target.files[0]);
                        setImgFileName(e.target.files[0]?.name); // Set the file name
                      }}
                      className="form-control"
                    />
                    <div className="image-box">
                      <div className="image-box-innr">
                        <img
                          src={`${values.front_image}`}
                          alt="BannerImg"
                        />
                        <a href="javascript:void(0);">
                          <i className="far fa-times" />
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="frm-bx mb-4 col-lg-4 mb-3">
                    <label htmlFor="" className="form-label">
                      Image
                    </label>
                    <input
                      type="file"
                      // onChange={(e) => setBgcImgFile(e.target.files[0])}
                      onChange={(e) => {
                        setBgcImgFile(e.target.files[0]);
                        setBgcImgFileName(e.target.files[0]?.name); // Set the background image file name
                      }}
                      className="form-control"
                    />
                    <div className="image-box">
                      <div className="image-box-innr">
                        <img
                          src={`${values.background_image}`}
                          alt="BannerImg"
                        />
                        <a href="javascript:void(0);">
                          <i className="far fa-times" />
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="mb-3 col-lg-12">
                      <React_Quill_Editor
                        disabled={false}
                        data={values?.description}
                        seteditor={setValues}
                        fieldName={"description"}
                        editorTitle={"Short Description"}
                      />
                    </div>
                  </div>
                  <div className="frm-bx mb-4 col-lg-12 mb-3 text-center">
                    <button className="thm-btn rounded-2" type="submit">
                      Edit Combo Offer
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ComboOfferEdit;
