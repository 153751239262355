import React from 'react'
import { Link } from 'react-router-dom'

const TopicAdd = () => {
  return (
    <>
     <section className="main-sec">
        <div className="row">
          <div className="col-lg-12">
            <div className="dashboard-title">
              <h4 className="dash-head">
                <i className="fas fa-video me-2" />
                Add Topic
              </h4>
            </div>
            <div className="custom-bredcump">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/livevideocourselist">
                      live Video Course Management
                    </Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Add Topic
                  </li>
                </ol>
              </nav>
            </div>
          </div>
          <div className="col-lg-12">
            <div className="cards cstm-form">
              <form action="" >
                <div className="row">
                  <div className="col-lg-6 col-md-4 mb-4">
                    <label htmlFor="" className="form-label">
                      Topic Title
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="title"
                    //   onChange={handelOnChange}
                    //   value={formval.title}
                    />
                  </div>
                  <div className="col-lg-6 col-md-4 mb-4">
                    <label htmlFor="" className="form-label">
                      Status
                    </label>
                    <select
                      className="form-select"
                      name="isActive"
                    //   onChange={handelOnChange}
                    >
                      <option defaultValue={""}>Select Status</option>
                      <option value={true}>Active</option>
                      <option value={false}>Inactive</option>
                    </select>
                  </div>
                  <div className="col-lg-6 mb-4">
                    <label htmlFor="" className="form-label">
                      Image
                    </label>
                    <input
                      type="file"
                      className="form-control"
                      defaultValue=""
                      name="images"
                    //   onChange={handelOnChange}
                      multiple
                    />
                    <div className="image-box">
                      {/* {image.map((imageUrl, index) => (
                        <div className="image-box-innr" key={index}>
                          <img src={imageUrl} alt={`Image ${index}`} />
                          <Link to="#" 
                        //   onClick={() => handleImgDelete(index)}
                        >
                            <i className="fa fa-times" />
                          </Link>
                        </div>
                      ))} */}
                    </div>
                  </div>
                  <div className="col-lg-12 mb-4 text-center">
                    <button className="thm-btn" type="submit">
                      Submit
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default TopicAdd