import React from 'react'
// import Header from '../Layout/Header'
// import Sidebar from '../Layout/Sidebar'
import { Link } from 'react-router-dom'
export default function User_add() {
    return (
        <>
            {/* <Header /> */}
            {/* <Sidebar /> */}
            <section className="main-sec">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="dashboard-title">
                            <h4 className="dash-head">
                                <i className="fas fa-users me-2" />
                                Add User
                            </h4>
                        </div>
                        <div className="custom-bredcump">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item">
                                        <Link to="/">Dashboard</Link>
                                    </li>
                                    <li className="breadcrumb-item active" aria-current="page">
                                        Add user
                                    </li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                    <div className="col-lg-12">
                        <div className="cards cstm-form">
                            <form action="">
                                <div className="row">
                                    <div className="frm-bx mb-4 col-lg-4 mb-3">
                                        <label htmlFor="" className="form-label">
                                            First Name
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Enter First Name"
                                        />
                                    </div>
                                    <div className="frm-bx mb-4 col-lg-4 mb-3">
                                        <label htmlFor="" className="form-label">
                                            Last Name
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Enter Last Name"
                                        />
                                    </div>
                                    <div className="frm-bx mb-4 col-lg-4 mb-3">
                                        <label htmlFor="" className="form-label">
                                            DOB
                                        </label>
                                        <input type="date" className="form-control" placeholder="" />
                                    </div>
                                    <div className="frm-bx mb-4 col-lg-4 mb-3">
                                        <label htmlFor="" className="form-label">
                                            Email Address
                                        </label>
                                        <input
                                            type="email"
                                            className="form-control"
                                            placeholder="Enter Email Address"
                                        />
                                    </div>
                                    <div className="frm-bx mb-4 col-lg-4 mb-3">
                                        <label htmlFor="" className="form-label">
                                            Phone Number
                                        </label>
                                        <input
                                            type="number"
                                            className="form-control"
                                            placeholder="Enter Phone Number"
                                        />
                                    </div>
                                    <div className="frm-bx mb-4 col-lg-4 mb-3">
                                        <label htmlFor="" className="form-label">
                                            Gender
                                        </label>
                                        <select name="" id="" className="form-select">
                                            <option selected="">Select Gender</option>
                                            <option value="">Male</option>
                                            <option value="">Female</option>
                                            <option value="">Other</option>
                                        </select>
                                    </div>
                                    <div className="frm-bx mb-4 col-lg-4 mb-3">
                                        <label htmlFor="" className="form-label">
                                            City
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Enter City"
                                        />
                                    </div>
                                    <div className="frm-bx mb-4 col-lg-4 mb-3">
                                        <label htmlFor="" className="form-label">
                                            PIN
                                        </label>
                                        <input
                                            type="number"
                                            className="form-control"
                                            placeholder="Enter PIN"
                                        />
                                    </div>
                                    <div className="frm-bx mb-4 col-lg-4 mb-3">
                                        <label htmlFor="" className="form-label">
                                            Status
                                        </label>
                                        <select name="" id="" className="form-select">
                                            <option selected="">Select Status</option>
                                            <option value="">Active</option>
                                            <option value="">Inactive</option>
                                        </select>
                                    </div>
                                    <div className="frm-bx mb-4 col-lg-4 mb-3">
                                        <label htmlFor="" className="form-label">
                                            Image
                                        </label>
                                        <input type="file" className="form-control" />
                                        <div className="image-box">
                                            <div className="image-box-innr">
                                                <img src="assets/images/avtar.jpg" alt="" />
                                                <a href="#">
                                                    <i className="far fa-times" />
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="frm-bx mb-4 col-lg-12 mb-3 text-center">
                                        <button className="thm-btn rounded-2" type="button">
                                            Add User
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}
