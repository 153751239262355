import React from 'react'
import UnauthorizedImg from "../../assets/images/401.png"
import { Link } from 'react-router-dom'
export default function  Unauthorized() {
  return (
    <>
    <section className="error404">
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-lg-8 col-md-12">
          <div className="error404-innr text-center">
            <img src={UnauthorizedImg} alt="" />
            <Link to="/" className="blu-btn">
              Back To Home
            </Link>
          </div>
        </div>
      </div>
    </div>
  </section>
  </>
  )
}
